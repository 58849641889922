// tealium definition
export enum uTagEvents {
	video_event = 'video_event', // Start, pause, Finish
	slideshow_banner = 'slideshow_banner', // User goes to a home page and interacts with banner slide controls (Prev, Next, Slide Dots)
	navigation = 'navigation', // various link clicks
	search_submit = 'search_submit',
	product_filter = 'product_filter', // User interacts with the filter options
	change_location_submit = 'change_location_submit', // User provides a zip code in the supplied field and clicks the update button
	installation_selection = 'installation_selection', // User interacts with a dropdown option underneath the Add/Remove installation toggle buttons
	article_tools = 'article_tools',
	cart_add = 'cart_add',
	cart_remove = 'cart_remove',
	cart_update = 'cart_update',
	cart_empty = 'cart_empty',
	cart_view = 'cart_view',
	user_register = 'user_register',
	user_login = 'user_login',
	user_logout = 'user_logout',
	link_click = 'link_click',
	continue_to_payment = 'continue_to_payment',
	continue_to_order_review = 'continue_to_order_review',
	submit_order = 'submit_order',
}
export interface tealiumData {
	[index: string]: Array<string> | string;
	article_created_date?: string; // "09/09/2020"
	cart_product_id?: Array<string>; // On 'cart_add' event, an array of all product ID's currently in the cart	["PROD123", "PROD456"]
	cart_product_price?: Array<string>; // On 'cart_add' event, an array of all product prices currently in the cart	["12.99", "25.99"]
	cart_product_quantity?: Array<string>; // On 'cart_add' event, an array of all product quantities currently in the cart	["2", "2"]
	cart_product_sku?: Array<string>; // On 'cart_add' event, an array of all product skus currently in the cart	["PR-RED-1234", "PR-BLK-6789"]
	cart_product_brand?: Array<string>;
	cart_product_category?: Array<string>;
	cart_product_url?: Array<string>;
	cart_total_items?: string; // Total number of all items in the cart.	"4"
	cart_total_value?: string; // Total value for all items in the cart as a string with only digits and decimal	"77.96"
	checkout_option?: string; // Sending the option value for a given checkout step.	"Visa"
	checkout_step?: string; // Specifies which step number the user is on during the checkout process	"3"
	country_code?: string; // Country Code eg. us, uk, mx, ca, jp, etc.	"us"
	customer_city?: string; // Contains the customer's city of residence.	"San Diego"
	customer_country?: string; // Contains the customer's country of residence.	"United States"
	customer_email?: string; // Contains the customer's email address.	"johnsmith@example.com"
	customer_first_name?: string; // The first name of the customer.	"John"
	customer_id?: string; // Contains the unique customer ID.	"8237572"
	customer_last_name?: string; // The last name of the customer.	"Smith"
	customer_state?: string; // Contains the customer's state of residence.	"CA"
	customer_zip?: string; // Contains the customer's postal code.	"92101"
	event_action?: string; // action of the triggered event	"click"
	event_category?: string; // category of the triggered event	"nav tracking"
	event_label?: string; // label of the triggered event	"contact"
	event_noninteraction?: string; // True if noninteraction of triggered event is true, default is false	"true"
	event_value?: string; // value of the triggered event	"3"
	order_currency_code?: string; // Currency code for the site eg. USD, GBP, EUR, CAD	"USD"
	order_discount?: string; // Contains the order-level discount amount. eg. 10.00 as a string with only digits and decimal	"10.00"
	order_id?: string; // Unique Identifier for an order, should only be populated on Order Confirmation page.	"ORD123456"
	order_promo_code?: string; // string list of comma separated promotion codes.	"SPRFREE,PROMO10"
	order_shipping?: string; // Contains the total value for shipping as a string with only digits and decimal.	"6.99"
	order_shipping_type?: string; // Contains the type of shipping. eg. 'FedEx Priority'.	"UPS"
	order_subtotal?: string; // Contains price of all items including any product or order level discounts, but excluding tax and shipping as a string with only digits and decimal	"45.98"
	order_tax?: string; // Total tax amount for this order as a string with only digits and decimal.	"2.50"
	order_total?: string; // Total Amount of the Order including tax and shipping but less all discounts as a string with only digits and decimal	"54.47"
	page_category_id?: string; // A unique identifier for the category being viewed eg. '243', 'MENS_SHOES', etc.	"243"
	page_category_name?: string; // A user-friendly name for the category being viewed eg. 'Shoes: Boots'	"Shoes: Boots"
	page_name?: string; // Tealium variable to identify the page name	"Homepage"
	page_product_description?: string; // description of the product on the pdp	"this is a product description"
	product_brand?: Array<string>; // An array of product brands.	["Ralph Lauren", "Lucky"]
	product_category_level_0?: Array<string>; // An array of product categories	["Shirts", "Pants"]
	product_category_level_1?: Array<string>; // An array of product categories	["Shirts", "Pants"]
	product_category_level_2?: Array<string>; // An array of product categories	["Shirts", "Pants"]
	product_category_level_3?: Array<string>; // An array of product categories	["Shirts", "Pants"]
	product_category_level_4?: Array<string>; // An array of product categories	["Shirts", "Pants"]
	product_id?: Array<string>; // An array of product IDs	["PROD123", "PROD456"]
	product_image?: Array<string>; // ["http://www.abc.com/image1", "http://www.abc.com/image2"]
	product_name?: Array<string>; // An array of product names.	["Product One", "Really Expensive Product Two"]
	product_price?: Array<string>; // An array of product selling prices as strings with only digits and decimal	["12.99", "1010.98"]
	product_quantity?: Array<string>; // An array of quantities for each product.	["2","2"]
	product_sku?: Array<string>; // An array of product skus	["PR-RED-1234", "PR-BLK-6789"]
	product_url?: Array<string>; // ["http://www.abc.com/prod1", "http://www.abc.com/prod2"]
	search_keyword?: string; // Value of search text entered by user. eg. 'long sleeve'	"cargo"
	search_results?: string; // Number of results returned by search. eg. '42'	"42"
	site_section?: string; // The high-level sections of your site eg. Apparel, Accessories, Help, etc.	"Clothing"
	tealium_event?: string; // Tealium variable to identify unique events and page types eg. home, product_view, cart_add, user_login, email_signup, etc.	"cart_add"
	video_id?: string; // "id123"
	video_name?: string; // "how to"
	update_type?: string; // "add", "remove", "update quantity"
}

function trimNull(data: tealiumData) {
	Object.keys(data).forEach((key) => data[key] === undefined && delete data[key]);
}

declare const utag: {
	link(dataObject: object, callback?: Function, UIDs?: Array<number>): Function;
	view(dataObject: object, callback?: Function, UIDs?: Array<number>): Function;
};

export enum GA_EventValues {
	MainNav = 0, // 0
	SubNav = 0,
	SectionLink = 0,
	SubCategory = 0,
	HeaderUSP = 0,
	ChangeLocationOpen = 0,
	ChangeLocationSubmit = 0,
	LogInOpen = 0,
	LogInSubmit = 0,
	HeaderLogo = 0,
	HeaderCall = 0,
	HeaderHelp = 0,
	HeaderMyAccount = 0,
	HeaderTopBar = 0,
	MobileNavOpen = 0,
	MobileNavClose = 0,
	MobileNavSlide = 0,
	MobileNavBack = 0,
	MobileContactCall = 0,
	MobileSearchOpen = 0,
	MobileSearchClose = 0,
	MobileViewAll = 0,
	MobileMyAccount = 0,
	SearchSubmit = 0,
	ShoppingCartLinkClick = 0,
	MainNavExpand = 0,
	MainNavContract = 0,
	Breadcrumb = 0,
	QuickSearch = 0,
}

export enum linkOpen {
	self,
	newWindow,
	newTab
}

export enum GA_eventActions {
	'click' = 'click',
	'mouseover' = 'mouseover',
	'mouseout' = 'mouseout',
	'keydown' = 'keydown',
}

export enum GA_installationEventActions {
	'PDPChangeLocation' = 'PDP Change Location',
	'PDPWhatsThis' = "PDP What's This",
	'PDPRemoveInstall' = 'PDP Remove Install',
	'PDPAddInstall' = 'PDP Add Install',
	'DismissLocation' = 'Dismiss Location',
	'UpdateLocation' = 'Update Location',
	'ATCRemoveInstall' = 'ATC Remove Install',
	'ATCAddInstall' = 'ATC Add Install',
	'CartAddInstall' = 'Cart Add Install',
	'CartRemoveInstall' = 'Cart Remove Install',
	'HybridChangeLocation' = 'Hybrid Change Location',
	'OpenFAQ' = 'Open FAQ',
}

export enum GA_eventCategories {
	Header = 'Header V.2.0',
	Navigation = 'Navigation V.2.0',
	Body = 'Body V.2.0',
	Installation = 'Installation',
	Footer = 'Footer V.2.0',
	ACWHeader = 'ACW Header',
	ACWNavigation = 'ACW Navigation',
	ACWBody = 'ACW Body',
	ACWFooter = 'ACW Footer',
}

export enum pageReferences {
	homePage = 'Home Page',
}

export interface GA_EventFieldObjects {
	hitType: string;
	eventCategory: string;
	eventAction: GA_eventActions;
	eventLabel: string;
	eventValue: GA_EventValues;
}

export interface GA_options {
	transport: string;
	nonInteraction: boolean;
	hitCallback: Function;
}

export type miniCartItem = {
	brand: string;
	category: string;
	full_id: string;
	id: string;
	image: string;
	model: string;
	name: string;
	price: string;
	products_model_alt: string;
	quantity: number;
	url: string;
};

export class AnalyticInteractions {
	currentCart: Array<miniCartItem>;
	_defaultEventCategory: string;
	debug: boolean;

	set defaultEventCategory(newValue) {
		if (this.debug) console.log(`set page category: ${newValue}}`);
		this._defaultEventCategory = newValue;
	}

	analyticEvent = new CustomEvent('AnalyticClickEvent', {
		bubbles: true,
	});
	analyticHyperlinkEvent = new CustomEvent('AnalyticClickEvent', {
		bubbles: true,
		detail: { elementType: HTMLAnchorElement, openMethod: linkOpen.self },
	});
	analyticSubmitEvent = new CustomEvent('AnalyticClickEvent', {
		bubbles: true,
		detail: { elementType: HTMLFormElement },
	});
	analyticButtonEvent = new CustomEvent('AnalyticClickEvent', {
		bubbles: true,
		detail: { elementType: HTMLButtonElement },
	});

	static analyticsAlreadyEnabled() {
		if (document.body.dataset.analyticsEnabled == 'true') return true;
		return false;
	}
	constructor(defaultEventCategory, isACW = false, debug = false) {
		this.debug = debug;
		this._defaultEventCategory = defaultEventCategory;

		if (this.debug) console.info(`initializing analytics: ${this._defaultEventCategory}`);
		document.body.dataset.analyticsEnabled = 'true';

		window.analyticEvents = {
			generic: this.analyticEvent,
			button: this.analyticButtonEvent,
			anchor: this.analyticHyperlinkEvent,
			submit: this.analyticSubmitEvent,
		};

		window.onload = AnalyticInteractions.initYTanalytics;

		const anchorDelegate = isACW ? this.acwAnalyticDispatch : this.anchorAnalyticDelegate;
		document.body.addEventListener('click', anchorDelegate);
		document.body.addEventListener('AnalyticClickEvent', this.analyticClickEvent);
		document.body.addEventListener('AnalyticCartEvent', (event: CustomEvent) => {
			let callbackFN: Function = event.detail.callback || null;

			this.syncCartAnalytics();

			if (!this.currentCart) {
				// if we fail to sync the cart end with the callback
				if (callbackFN) callbackFN();
				return;
			}

			// TODO: is it possible to get the original event to populate the eventActions automatically with click, mouseout, etc?
			const clickedEle = <HTMLElement>event.target,
				eventCategory = clickedEle.dataset.eventCategory?.trim() || this._defaultEventCategory || document.body.dataset.eventCategory?.trim() || 'Page Event',
				eventLabel: string = clickedEle.dataset.eventLabel?.trim() || 'Cart Update', // TODO: figure out a suitable default
				eventValue: string = clickedEle.dataset.eventValue?.trim() || '0',
				eventAction: string = clickedEle.dataset.eventAction?.trim() || GA_eventActions.click;
			let productUpdate = false;
			let modifiedProduct: miniCartItem = {
				brand: '',
				category: '',
				full_id: '',
				id: '',
				image: '',
				model: '',
				name: '',
				price: '',
				products_model_alt: '',
				quantity: 0,
				url: '',
			};

			const removeFromLocalCart = (id: string) => {
				const sId = this.stripOption(id);
				const existingItemIndex = this.currentCart?.findIndex((item) => item.id === sId) ?? -1;

				if (existingItemIndex >= 0) {
					// remember the object we're going to remove before removing it
					modifiedProduct = this.currentCart.splice(existingItemIndex, 1)[0];
					productUpdate = true;
				}
			};

			let tealiumEvent: string = (() => {
				if (clickedEle.dataset.tealiumEvent?.trim()) {
					return clickedEle.dataset.tealiumEvent?.trim();
				} else if (event.detail.action) {
					switch (event.detail.action) {
						case 'add':
							return uTagEvents.cart_add;
						case 'update':
							return uTagEvents.cart_update;
						case 'remove':
							return uTagEvents.cart_remove;
						default:
							return uTagEvents.cart_add;
					}
				} else {
					return uTagEvents.cart_add;
				}
			})();

			let cartData: tealiumData = {
				cart_product_id: new Array<string>(),
				cart_product_price: new Array<string>(),
				cart_product_quantity: new Array<string>(),
				cart_product_sku: new Array<string>(),
				product_brand: new Array<string>(),
				product_category_level_0: new Array<string>(),
				product_id: new Array<string>(),
				product_image: new Array<string>(),
				product_name: new Array<string>(),
				product_price: new Array<string>(),
				product_quantity: new Array<string>(),
				product_sku: new Array<string>(),
				product_subcategory: new Array<string>(),
				product_url: new Array<string>(),
			};
			let totalQuantity: number = 0;
			let totalValue: number = 0;

			if (this.debug) {
				console.info('Analytic Event: ', {
					eventCategory,
					eventAction,
					eventLabel,
					eventValue,
				});
			} else {
				if (tealiumEvent == uTagEvents.cart_remove) {
					removeFromLocalCart(event.detail.id);
				}
				if (tealiumEvent == uTagEvents.cart_update) {
					const id = this.stripOption(event.detail.id),
						quantity = event.detail.quantity;
					const cleanedQuantity: number = typeof quantity === 'string' ? parseInt(quantity) : quantity;

					if (cleanedQuantity <= 0) {
						// we passed 0 quantity, so remove it from the array
						removeFromLocalCart(id);
					} else {
						// attempt to find the index of the product in the cart
						let productIndex: number = this.currentCart.findIndex((item) => item.id === id);
						if (productIndex >= 0) {
							// we found an index, update the quantity
							this.currentCart[productIndex].quantity = cleanedQuantity;
							modifiedProduct = this.currentCart[productIndex];
							productUpdate = true;
						}
					}
				}
				if (tealiumEvent == uTagEvents.cart_add) {
					const id = this.stripOption(event.detail.id);

					// capture the item added to the cart
					let productIndex: number = this.currentCart.findIndex((item) => item.id === id);
					if (productIndex >= 0) {
						modifiedProduct = this.currentCart[productIndex];
						productUpdate = true;
					}
				}

				for (let index = 0; index < this.currentCart.length; index++) {
					const cartItem: miniCartItem = this.currentCart[index],
						imageURL = cartItem.image ? `${window.location.origin}/products-image/500/${cartItem.image}` : '';

					cartData.product_brand.push(cartItem.brand);
					cartData.product_category_level_0.push(cartItem.category);
					cartData.product_id.push(this.stripOption(cartItem.id));
					cartData.product_image.push(imageURL);
					cartData.product_name.push(cartItem.name || '');
					cartData.product_price.push(cartItem.price);
					cartData.product_quantity.push(cartItem.quantity.toString());
					cartData.product_sku.push(cartItem.model.toString());
					cartData.product_url.push(cartItem.url);

					totalQuantity += parseInt(cartItem.quantity.toString());
					totalValue += parseInt(cartItem.price) * parseInt(cartItem.quantity.toString());
				}
				cartData.cart_total_items = totalQuantity.toString();
				cartData.cart_total_value = totalValue.toString();

				if (event.detail.currentProduct) {
					const currentItem: miniCartItem = event.detail.currentProduct,
						imageURL = currentItem.image ? `${window.location.origin}/products-image/500/${currentItem.image}` : '';

					cartData.product_brand.push(currentItem.brand);
					cartData.product_category_level_0.push(currentItem.category);
					cartData.product_id.push(this.stripOption(currentItem.id));
					cartData.product_image.push(imageURL);
					cartData.product_name.push(currentItem.name || '');
					cartData.product_price.push(currentItem.price);
					cartData.product_quantity.push(currentItem.quantity.toString());
					cartData.product_sku.push(currentItem.model.toString());
					cartData.product_url.push(currentItem.url);
				}

				if ('utag' in window && tealiumEvent) {
					if (tealiumEvent == uTagEvents.cart_add || tealiumEvent == uTagEvents.cart_remove || tealiumEvent == uTagEvents.cart_update) {
						// in remove or update, we need to make sure to update the cart before we send it off
						AnalyticInteractions.tealium_cartEvent(
							cartData.cart_product_id,
							cartData.cart_product_price,
							cartData.cart_product_quantity,
							cartData.cart_product_sku,
							cartData.cart_total_items,
							cartData.cart_total_value,
							eventAction,
							eventCategory,
							eventLabel,
							eventValue,
							productUpdate ? [modifiedProduct.brand || ''] : cartData.product_brand,
							productUpdate ? [modifiedProduct.category || ''] : cartData.product_category_level_0,
							productUpdate ? [modifiedProduct.id] : cartData.product_id,
							productUpdate ? [modifiedProduct.image || ''] : cartData.product_image,
							productUpdate ? [modifiedProduct.name] : cartData.product_name,
							productUpdate ? [modifiedProduct.price] : cartData.product_price,
							productUpdate ? [modifiedProduct.quantity.toString()] : cartData.product_quantity,
							productUpdate ? [modifiedProduct.model] : cartData.product_sku,
							productUpdate ? [modifiedProduct.url || ''] : cartData.product_url,
							event.detail.action || 'add',
							tealiumEvent,
							event.detail.callback
						);
					} else {
						AnalyticInteractions.tealiumEvent(
							{
								event_action: eventAction,
								event_category: eventCategory,
								event_label: eventLabel,
								event_value: eventValue.toString(),
								tealium_event: tealiumEvent,
								event_noninteraction: eventAction == GA_eventActions.click ? 'false' : 'true',
							} as tealiumData,
							callbackFN
						);
					}
				} else {
					if (callbackFN) {
						callbackFN();
					}
				}
			}
		});
		const contToPmtBtn = document.querySelector('#btn_continue_topm');
		const contToReviewOrderBtn = document.querySelector('#btn_continue_toro');

		contToPmtBtn?.addEventListener('click', (ev: MouseEvent) => {
			AnalyticInteractions.tealium_continueToPayment(GA_eventActions.click, GA_eventCategories.Body, 'Continue to Payment');
		});

		contToReviewOrderBtn?.addEventListener('click', (ev: MouseEvent) => {
			AnalyticInteractions.tealium_continueToOrderReview(GA_eventActions.click, GA_eventCategories.Body, 'Continue to Order Review');
		});

		document.body.addEventListener('click', (ev: MouseEvent) => {
			if ((ev.target as HTMLElement).matches('input.button_so')) {
				AnalyticInteractions.tealium_submitOrder(GA_eventActions.click, GA_eventCategories.Body, 'Submit Order');
			}
		});
	}
	acwAnalyticDispatch(event) {		
		if (event.target instanceof HTMLAnchorElement || (event.target as HTMLElement).closest('a')) {
			// we identified the clicked element as a hyperlink, cast as such
			const anchorElement: HTMLAnchorElement = (event.target instanceof HTMLAnchorElement ? event.target : (event.target as HTMLElement).closest('a')) as HTMLAnchorElement;
			let callbackFN: Function = null;
			let category: GA_eventCategories = null;
			let openMethod: linkOpen = linkOpen.self;
			if (anchorElement.target == '_blank' || event.shiftKey) openMethod = linkOpen.newWindow;
			if (anchorElement.target == '_blank' || event.ctrlKey) openMethod = linkOpen.newTab;
			// if the hyperlink has a href and not marked as nofollow (i.e. not acting as a button)
			// then stop the link from redirecting so we can avoid race condition
			// UPDATE: we are temporarily no longer halting redirection manually to see if this improves INP
			if (anchorElement.href?.trim() !== '' && anchorElement.rel !== 'nofollow') {
				// event.preventDefault();
				callbackFN = () => {
				// 	switch (openMethod) {
				// 		case linkOpen.newWindow:
				// 			window.open((<HTMLAnchorElement>anchorElement).href, '_blank');
				// 			break;
				// 		case linkOpen.newTab:
				// 			window.open((<HTMLAnchorElement>anchorElement).href);
				// 			break;
				// 		default:
				// 			window.location.href = (<HTMLAnchorElement>anchorElement).href
				// 			break;
				// 	}
				};

				// great, now find out where the event came from so we can set the right labels
				// header
				// navigation
				// footer
				// body
				// installation ??
				if (anchorElement.matches('#acw_menu a')) {
					category = GA_eventCategories.ACWNavigation;
				} else if (anchorElement.matches('.headWrap a')) {
					category = GA_eventCategories.ACWHeader;
				} else if (anchorElement.matches('#footer_seo a, #footer a')) {
					category = GA_eventCategories.ACWFooter;
				} else {
					category = GA_eventCategories.ACWBody;
					if (anchorElement.matches('.buyGroup a')) {
						return;
					}
				}

				let eventLabel: string = anchorElement.href || '[No hyperlink description available]';
				const potentialImage = anchorElement.querySelector('img');
				if (anchorElement.dataset.eventLabel && anchorElement.dataset.eventLabel !== '') {
					eventLabel = anchorElement.dataset.eventLabel;
				} else if (anchorElement.innerText && anchorElement.innerText.trim() !== '') {
					eventLabel = anchorElement.innerText;
				} else if (anchorElement.getAttribute('aria-label')) {
					eventLabel = anchorElement.getAttribute('aria-label');
				} else if (potentialImage && (potentialImage.alt || potentialImage.getAttribute('aria-label'))) {
					eventLabel = potentialImage.alt || potentialImage.getAttribute('aria-label');
				}
				// fire off the navigation event!
				AnalyticInteractions.tealium_navigation(GA_eventActions.click, category, eventLabel, '0', callbackFN);
			}
		}
	}
	anchorAnalyticDelegate = (e: MouseEvent) => {
		const target = <HTMLElement>e.target;

		if (target.tagName === 'A' || target.closest('a')) {
			// e.preventDefault();
			const clickedEle = target.tagName === 'A' ? (target as HTMLAnchorElement) : (target.closest('a') as HTMLAnchorElement);

			if (!clickedEle.dataset.eventLabel || clickedEle.dataset.eventLabel === '') {
				// no label so far.. do we have other elements we can grab?
				const innerImg = clickedEle.querySelector('img') as HTMLImageElement;

				if (clickedEle.innerText !== '') {
					// we have inner text
					clickedEle.dataset.eventLabel = clickedEle.innerText;
				} else if (innerImg) {
					// no inner text or title?
					// if we have an image, try to get the alt
					clickedEle.dataset.eventLabel = '(image) ' + innerImg.alt;
				}
			}
			const event = this.analyticHyperlinkEvent;
			if (clickedEle.target == '_blank' || e.shiftKey) event.detail.openMethod = linkOpen.newWindow;
			if (e.ctrlKey) event.detail.openMethod = linkOpen.newTab;

			clickedEle.dispatchEvent(event);
		}
	};

	analyticClickEvent = (event: CustomEvent) => {
		// TODO: is it possible to get the original event to populate the eventActions automatically with click, mouseout, etc?
		const clickedEle = event.target as HTMLElement,
			eventCategory = clickedEle.dataset.eventCategory?.trim() || this._defaultEventCategory || document.body.dataset.eventCategory?.trim() || 'Page Event',
			eventLabel: string = clickedEle.dataset.eventLabel?.trim() || '<No Valid Label>', // TODO: figure out a suitable default
			eventValue: number = parseInt(clickedEle.dataset.eventValue?.trim()) || 0,
			eventAction: string = clickedEle.dataset.eventAction?.trim() || GA_eventActions.click;

		let tealiumEvent: string = clickedEle.dataset.tealiumEvent?.trim() || null;
		let callbackFN: Function = event.detail?.callback;

		if (event.detail && event.detail.elementType === HTMLFormElement) {
			callbackFN = () => {
				(<HTMLFormElement>clickedEle).submit();
			};
		} else if (event.detail && event.detail.elementType === HTMLAnchorElement) {
			callbackFN = () => {
			// 	if ((<HTMLAnchorElement>clickedEle).href) {
			// 		switch (event.detail.openMethod) {
			// 			case linkOpen.newWindow:
			// 				window.open((<HTMLAnchorElement>clickedEle).href, '_blank');
			// 				break;
			// 			case linkOpen.newTab:
			// 				window.open((<HTMLAnchorElement>clickedEle).href);
			// 				break;
			// 			default:
			// 				window.location.href = (<HTMLAnchorElement>clickedEle).href
			// 				break;
			// 		}
			// 	}
			};
			if (!tealiumEvent) {
				tealiumEvent = uTagEvents.navigation;
			}
		}
		// TODO: temporary check, remove to enable the full product page analytic
		// this is so we can enable installation tracking before entire product page is ready

		// WHEN DO WE PREVENT DEFAULT TO KEEP THE LINKS FROM GOING ANYWHERE??
		if (eventCategory === 'Shopping Cart Event') {
			if (callbackFN) callbackFN();
			return;
		}
		if (this.debug) {
			console.info('Analytic Event: ', { eventCategory, eventAction, eventLabel, eventValue });
			if (callbackFN) {
				callbackFN();
			}
		} else {
			if ('utag' in window && tealiumEvent) {
				if (tealiumEvent == uTagEvents.video_event) {
					const vID = clickedEle.dataset.videoId?.trim() || null;
					const vName = clickedEle.dataset.videoName?.trim() || null;
					AnalyticInteractions.tealium_videoEvent(eventLabel, eventValue.toString(), vID, vName, callbackFN);
				} else if (tealiumEvent == uTagEvents.navigation) {
					AnalyticInteractions.tealium_navigation(eventAction, eventCategory, eventLabel, eventValue.toString(), callbackFN);
				} else {
					AnalyticInteractions.tealiumEvent(
						{
							event_action: eventAction,
							event_category: eventCategory,
							event_label: eventLabel,
							event_value: eventValue.toString(),
							tealium_event: tealiumEvent,
							event_noninteraction: eventAction == GA_eventActions.click ? 'false' : 'true',
						} as tealiumData,
						callbackFN
					);
				}
			} else {
				if (callbackFN) {
					callbackFN();
				}
			}
		}
	};
	private stripOption = (id: string) => {
		if (id) {
			// when coming from a js script, "id" might still come in as int
			id = id.toString();

			const optionIndex = id.indexOf('{');
			const pidIndex = id.indexOf('pid');

			//console.info(`index on ${id}: ${optionIndex}`);
			if (optionIndex >= 0) {
				id = id.substring(0, optionIndex);
			}
			if (pidIndex >= 0) {
				id = id.substring(pidIndex + 3, id.length);
			}
			return id;
		} else {
			return id;
		}
	};
	private syncCartAnalytics() {
		// we need to keep a client-side copy of the cart for iterable,
		// use this to sync our copy with the server's copy

		// clear the cart
		this.currentCart = [];
		const cartData = JSON.parse(document.getElementById('cartData').innerHTML);
		// re-populate the cart with updated data
		if (cartData) {
			for (let index = 0; index < cartData.length; index++) {
				const cartItem: miniCartItem = cartData[index];
				cartItem.id = this.stripOption(cartItem.id);

				this.currentCart.push(cartItem);
			}
		}
	}
	static initYTanalytics() {
		const ytPlayers = document.querySelectorAll('iframe[src*="youtube.com"]') as NodeListOf<HTMLIFrameElement>;

		if (ytPlayers?.length > 0) {
			if (!('YT' in window)) {
				const tag = document.createElement('script');
				tag.src = 'https://www.youtube.com/iframe_api';
				const firstScriptTag = document.getElementsByTagName('script')[0];
				firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
			}

			ytPlayers.forEach((player) => {
				// generate unique id for element if we don't have one
				if (!player.id) {
					player.id = Date.now().toString();
				}
				// we need this value set in src for events to work
				if (player.src.indexOf('enablejsapi') < 0) {
					const qInx = player.src.indexOf('?');
					if (qInx > 0) {
						player.src += '&enablejsapi=1';
					} else {
						player.src += '?enablejsapi=1';
					}
				}

				player.setAttribute('enablejsapi', 'true');

				(<any>window).onYouTubeIframeAPIReady = (e: Event) => {
					const yt_player = new YT.Player(player.id, {
						events: {
							onStateChange: (event) => {
								const vData: { author: string; title: string; video_id: string } = (<any>yt_player).getVideoData();
								switch (event.data) {
									case YT.PlayerState.ENDED:
										AnalyticInteractions.tealium_videoEvent('Reached end of video', '0', vData.video_id, vData.title);
										break;
									case YT.PlayerState.PLAYING:
										AnalyticInteractions.tealium_videoEvent('Played video', '0', vData.video_id, vData.title);
										break;
									case YT.PlayerState.PAUSED:
										AnalyticInteractions.tealium_videoEvent('Paused video', '0', vData.video_id, vData.title);
										break;
									default:
										break;
								}
							},
						},
					});
				};
			});
		}
	}

	static genericSendEvent(category: string, action: string, label: string, eventType: GA_EventValues, callbackFN?: Function) {
		let options: GA_options = {
			transport: 'beacon',
			nonInteraction: false,
			hitCallback: null,
		};

		if (action === GA_eventActions.mouseover || action === GA_eventActions.mouseout) {
			options.nonInteraction = true;
		}

		if (callbackFN) {
			// callback passed, we want to wait until we hear from google before continuing
			let callbackComplete = false;
			const doCallback = () => {
				if (!callbackComplete) {
					callbackComplete = true;
					callbackFN();
				}
			};
			options.hitCallback = doCallback;
			// Creates a timeout to call our callback after one second.
			setTimeout(doCallback, 1000);
		}
	}

	static headerEvent(action: GA_eventActions = GA_eventActions.click, label: string, eventType: GA_EventValues, eventTarget: HTMLElement, openMethod: linkOpen = linkOpen.self, callbackFN?: Function) {
		if (action === GA_eventActions.click && eventTarget.nodeName === 'A') {
			callbackFN = () => {
			// 	if ((<HTMLAnchorElement>eventTarget).href) {
			// 		switch (openMethod) {
			// 			case linkOpen.newWindow:
			// 				window.open((<HTMLAnchorElement>eventTarget).href, '_blank');
			// 				break;
			// 			case linkOpen.newTab:
			// 				window.open((<HTMLAnchorElement>eventTarget).href);
			// 				break;
			// 			default:
			// 				window.location.href = (<HTMLAnchorElement>eventTarget).href
			// 				break;
			// 		}
			// 	}
			};
		}
		if ('utag' in window) {
			this.tealium_navigation(action, GA_eventCategories.Header, label, eventType.toString(), callbackFN);
		} else {
			this.genericSendEvent(GA_eventCategories.Header, action, label, eventType, callbackFN);
		}
	}
	static navigationEvent(action: GA_eventActions = GA_eventActions.click, label: string, eventType: GA_EventValues, eventTarget: HTMLElement, openMethod: linkOpen = linkOpen.self, callbackFN?: Function) {
		if (action === GA_eventActions.click && eventTarget.nodeName === 'A') {
			callbackFN = () => {
				// if ((<HTMLAnchorElement>eventTarget).href) {
				// 	switch (openMethod) {
				// 		case linkOpen.newWindow:
				// 			window.open((<HTMLAnchorElement>eventTarget).href, '_blank');
				// 			break;
				// 		case linkOpen.newTab:
				// 			window.open((<HTMLAnchorElement>eventTarget).href);
				// 			break;
				// 		default:
				// 			window.location.href = (<HTMLAnchorElement>eventTarget).href
				// 			break;
				// 	}
				// }
			};
		}
		if ('utag' in window) {
			this.tealium_navigation(action, GA_eventCategories.Navigation, label, eventType.toString(), callbackFN);
		} else {
			this.genericSendEvent(GA_eventCategories.Navigation, action, label, eventType, callbackFN);
		}
	}

	static pageEvent(page: pageReferences, action: GA_eventActions = GA_eventActions.click, label: string, eventType: GA_EventValues, callbackFN?: Function) {
		this.genericSendEvent(page, action, label, eventType);
	}

	static installationEvent(page: pageReferences, action: GA_installationEventActions, label: string, eventType: GA_EventValues, callbackFN?: Function) {
		if ('utag' in window) {
			this.tealium_installationSelection(action, GA_eventCategories.Installation, label, eventType.toString(), callbackFN);
		} else {
			this.genericSendEvent(page, action, label, eventType);
		}
	}

	static footerEvent(action: GA_eventActions = GA_eventActions.click, label: string, eventType: GA_EventValues, eventTarget: HTMLElement, openMethod: linkOpen = linkOpen.self, callbackFN?: Function) {
		if (action === GA_eventActions.click && eventTarget.nodeName === 'A') {
			callbackFN = () => {
				// if ((<HTMLAnchorElement>eventTarget).href) {
				// 	switch (openMethod) {
				// 		case linkOpen.newWindow:
				// 			window.open((<HTMLAnchorElement>eventTarget).href, '_blank');
				// 			break;
				// 		case linkOpen.newTab:
				// 			window.open((<HTMLAnchorElement>eventTarget).href);
				// 			break;
				// 		default:
				// 			window.location.href = (<HTMLAnchorElement>eventTarget).href
				// 			break;
				// 	}
				// }
			};
		}
		if ('utag' in window) {
			this.tealium_navigation(action, GA_eventCategories.Footer, label, eventType.toString(), callbackFN);
		} else {
			this.genericSendEvent(GA_eventCategories.Footer, action, label, eventType, callbackFN);
		}
	}

	static tealiumEvent(data: tealiumData, callback?: Function, UIDs: Array<number> = []) {
		// rip any keys that have null values out of our object

		trimNull(data);

		if ('utag' in window) {
			if (UIDs.length <= 0) {
				UIDs = null;
			}

			utag.link(data, callback, UIDs);
		} else {
			if (callback) {
				callback();
			}
		}
	}

	static tealium_videoEvent(label: string, value: string = '0', videoId: string = '', videoName: string = '', callback?: Function, UIDs?: Array<number>) {
		const eventData: tealiumData = {
			event_action: GA_eventActions.click, //Required
			event_category: GA_eventCategories.Body, //Required
			event_label: label, //Required
			event_noninteraction: 'false', //Suggested
			event_value: value, //Suggested
			tealium_event: uTagEvents.video_event, //Required
			video_id: videoId, //Suggested
			video_name: videoName, //Suggested
		};

		this.tealiumEvent(eventData, callback, UIDs);
	}
	static tealium_slideshowEvent(label: string, value: string = '0', callback?: Function, UIDs?: Array<number>) {
		const eventData: tealiumData = {
			event_action: GA_eventActions.click, //Required
			event_category: GA_eventCategories.Body, //Required
			event_label: label, //Required
			event_noninteraction: 'false', //Suggested
			event_value: value, //Suggested
			tealium_event: uTagEvents.slideshow_banner, //Required
		};

		this.tealiumEvent(eventData, callback, UIDs);
	}
	static tealium_navigation(action: string = GA_eventActions.click, category: string, label: string, value: string = '0', callback?: Function, UIDs?: Array<number>) {
		const eventData: tealiumData = {
			event_action: action, //Required
			event_category: category, //Required
			event_label: label, //Required
			event_noninteraction: action == GA_eventActions.click ? 'false' : 'true', //Suggested
			event_value: value, //Suggested
			tealium_event: uTagEvents.navigation, //Required
		};

		this.tealiumEvent(eventData, callback, UIDs);
	}
	static tealium_searchSubmit(keyword: string, action: string = GA_eventActions.click, category: string, label: string, value: string = '0', callback?: Function, UIDs?: Array<number>) {
		const eventData: tealiumData = {
			event_action: action, //Required
			event_category: category, //Required
			event_label: label, //Required
			search_keyword: keyword,
			event_noninteraction: 'false', //Suggested
			event_value: value, //Suggested
			tealium_event: uTagEvents.search_submit, //Required
		};

		this.tealiumEvent(eventData, callback, UIDs);
	}
	static tealium_productFilter(action: string = GA_eventActions.click, category: string, label: string, value: string = '0', callback?: Function, UIDs?: Array<number>) {
		const eventData: tealiumData = {
			event_action: action, //Required
			event_category: category, //Required
			event_label: label, //Required
			event_noninteraction: 'false', //Suggested
			event_value: value, //Suggested
			tealium_event: uTagEvents.product_filter, //Required
		};

		this.tealiumEvent(eventData, callback, UIDs);
	}
	static tealium_changeLocationSubmit(customerZip: string, action: string = GA_eventActions.click, category: string, label: string, value: string = '0', callback?: Function, UIDs?: Array<number>) {
		const eventData: tealiumData = {
			event_action: action, //Required
			event_category: category, //Required
			event_label: label, //Required
			event_noninteraction: 'false', //Suggested
			event_value: value, //Suggested
			customer_zip: customerZip,
			tealium_event: uTagEvents.change_location_submit, //Required
		};

		this.tealiumEvent(eventData, callback, UIDs);
	}
	static tealium_installationSelection(action: string = GA_eventActions.click, category: string, label: string, value: string = '0', callback?: Function, UIDs?: Array<number>) {
		const eventData: tealiumData = {
			event_action: action, //Required
			event_category: category, //Required
			event_label: label, //Required
			event_noninteraction: 'false', //Suggested
			event_value: value, //Suggested
			tealium_event: uTagEvents.installation_selection, //Required
		};

		this.tealiumEvent(eventData, callback, UIDs);
	}
	static tealium_articleTools(action: string = GA_eventActions.click, category: string, label: string, value: string = '0', callback?: Function, UIDs?: Array<number>) {
		const eventData: tealiumData = {
			event_action: action, //Required
			event_category: category, //Required
			event_label: label, //Required
			event_noninteraction: 'false', //Suggested
			event_value: value, //Suggested
			tealium_event: uTagEvents.article_tools, //Required
		};

		this.tealiumEvent(eventData, callback, UIDs);
	}
	static tealium_cartEvent(
		cartProductIDs: Array<string>,
		cartProductPrices: Array<string>,
		cartProductQuantities: Array<string>,
		cartProductSKUs: Array<string>,
		cartTotalItems: string,
		cartTotalValue: string,
		action: string = GA_eventActions.click,
		category: string,
		label: string,
		value: string = '0',
		productBrands: Array<string>,
		productCategories: Array<string>,
		productIDs: Array<string>,
		productImages: Array<string>,
		productNames: Array<string>,
		productPrices: Array<string>,
		productQuantity: Array<string>,
		productSKUs: Array<string>,
		productURLs: Array<string>,
		updateType: string,
		tealiumEvent: uTagEvents,
		callback?: Function,
		UIDs?: Array<number>
	) {
		const eventData: tealiumData = {
			cart_product_id: cartProductIDs, //Required
			cart_product_price: cartProductPrices, //Required
			cart_product_quantity_level_0: cartProductQuantities, //Required
			cart_product_sku: cartProductSKUs, //Required
			cart_total_items: cartTotalItems, //Required
			cart_total_value: cartTotalValue, //Required
			event_action: action, //Required
			event_category: category, //Required
			event_label: label, //Required
			event_noninteraction: 'false', //Suggested
			event_value: value, //Suggested
			product_brand: productBrands, //Required
			product_category: productCategories, //Required
			product_id: productIDs, //Required
			product_image: productImages, //Required
			product_name: productNames, //Required
			product_price: productPrices, //Required
			product_quantity: productQuantity, //Required
			product_sku: productSKUs, //Required
			product_url: productURLs, //Required
			update_type: updateType,
			tealium_event: tealiumEvent, //Required
		};

		this.tealiumEvent(eventData, callback, UIDs);
	}

	static tealium_continueToPayment(action: string = GA_eventActions.click, category: string, label: string, callback?: Function, UIDs?: Array<number>) {
		const eventData: tealiumData = {
			event_action: action, //Required
			event_category: category, //Required
			event_label: label, //Required
			event_noninteraction: 'false', //Suggested
			tealium_event: uTagEvents.continue_to_payment, //Required
		};

		this.tealiumEvent(eventData, callback, UIDs);
	}
	static tealium_continueToOrderReview(action: string = GA_eventActions.click, category: string, label: string, callback?: Function, UIDs?: Array<number>) {
		const eventData: tealiumData = {
			event_action: action, //Required
			event_category: category, //Required
			event_label: label, //Required
			event_noninteraction: 'false', //Suggested
			tealium_event: uTagEvents.continue_to_order_review, //Required
		};

		this.tealiumEvent(eventData, callback, UIDs);
	}
	static tealium_submitOrder(action: string = GA_eventActions.click, category: string, label: string, callback?: Function, UIDs?: Array<number>) {
		const eventData: tealiumData = {
			event_action: action, //Required
			event_category: category, //Required
			event_label: label, //Required
			event_noninteraction: 'false', //Suggested
			tealium_event: uTagEvents.submit_order, //Required
		};

		this.tealiumEvent(eventData, callback, UIDs);
	}
}
