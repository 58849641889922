import { eventHelpers, domHelpers, ajaxHelpers } from '../helpers/helpers';
import { gsap } from "gsap";
import { AnalyticInteractions, GA_eventActions, linkOpen } from '../helpers/AnalyticHelper';

/* global ActiveXObject, gsap */
/* exported footerMethods */
// above lines are ESLint exceptions 

// use these for consistently naming specific dynamically generated DOM elements
enum domIdPrefixes {
    'button' = 'button_',
    'accordion' = 'accordion_'
}

interface link {
    href:string,
    title:string,
}
interface expertData extends link {
    imageURL: string,
    expertName: string,
    expertTitle: string
}
interface bbbJSON extends link {
    imageURL: string,
    imageAlt: string,
}

type miscLinkProps = {
    href?: string;
    onclick?: string;
    title: string;
}

type miscLink = { [index: string] : miscLinkProps };

export class footerModule {
    private readonly _plusIconClass = 'icon-plus plusIfy'; // class names that generate the + icon (from icon font)

    private _timer_id: number;               // this is used to throttle event firing during active window resizing
    private _footerContainer: HTMLDivElement;
    private _emailForm: HTMLFormElement;              // reference to the email form element
    private _emailInput: HTMLInputElement;             // reference to the email input box in the email form
    private _emailSignUpButton: HTMLButtonElement;      // reference to the email form's signup button
    private _emailPostAjaxDest: string;      // where we are going to send our ajax POST to
    private _siteLinksSection: NodeListOf<HTMLElement>;       // reference to the element in which we will append the site's links
    private _storeLinksSection: NodeListOf<HTMLElement>;      // reference to the element in which we will append the "our stores" section
    private _socialLinksSections: NodeListOf<HTMLElement>;    // reference to the elements in which we will append the the social icon links (currently expecting two containers)
    private _miscLinksSection: NodeListOf<HTMLElement>;       // reference to the element in which we will append the the misc links (privacy policy, copyright, etc.)
    private _expertSections: ()=>NodeListOf<HTMLElement>;
    private _BBBSections: NodeListOf<HTMLElement>;            // sections to append the BBB image into
    private _priorWidth: number;             // for comparing when resizing, making sure event is only firing on width change

    constructor(
        siteLinkJSON:any,
        storeLinkJSON:any,
        socialLinkJSON:any,
        miscLinkJSON:any,
        expertJSON: expertData,
        bbbJSON: bbbJSON
    ) {
        const expertSections = () => {
            return document.querySelectorAll('#siteLinks .constrainer section:last-child, #miscLinks');
        }; // wrapping in a function so we can reevaluate on-demand        

        // record our current, initial width
        this._priorWidth = domHelpers.getScreenWidth();
        // setters 
        this._footerContainer = document.querySelector('footer#footer');
        this._emailForm = this._footerContainer.querySelector('#footerEmailForm');
        this._emailInput = this._footerContainer.querySelector('#txtEmailSignUp');
        this._emailSignUpButton = this._footerContainer.querySelector('#btnEmailSignUp');
        this._emailPostAjaxDest = '/includes/emailSignup_new.php';
        this._siteLinksSection = this._footerContainer.querySelectorAll('#siteLinks .constrainer');
        this._storeLinksSection = this._footerContainer.querySelectorAll('#ourStores .constrainer');
        this._socialLinksSections = this._footerContainer.querySelectorAll('#footer .social');
        this._miscLinksSection = this._footerContainer.querySelectorAll('#miscLinks .constrainer');
        this._expertSections = ()=>this._footerContainer.querySelectorAll('#siteLinks .constrainer section:last-child, #miscLinks');
        this._BBBSections = this._footerContainer.querySelectorAll('#ourStores .constrainer, #mobileSocial .constrainer, #mobileBBB .constrainer');
        // generate footer links
        this.populateFooterLinks(siteLinkJSON);
        // generate our stores links
        this.populateStoreLinks(storeLinkJSON);
        // make our footer and stores links accordions
        this.toggleAccordionButtonMode();
        this.toggleAccordionMode();
        // generate social links
        this.populateSocialConnectLinks(socialLinkJSON);
        // generate the misc links
        this.populateMiscLinks(miscLinkJSON);
        // generate the expert info
        this.createExpertElements(expertJSON);
        // generate the BBB images and place them where they are supposed to go
        this.createBBBBadges(bbbJSON);
        // bind events
        this._emailForm.onsubmit = this.submitForm;    // form submit (more validation and ajax call)
        window.onresize = this.listenResize;    // enable or disable accordion based on media query during resizing or rotating device
        window.onorientationchange = this.listenResize;    // enable or disable accordion based on media query during resizing or rotating device    
        eventHelpers.bindDelegateEvent('#footer','.titleButton, .titleButton .icon-plus', ['click'], this.toggleAccordion); // attach events to the buttons
        this._footerContainer.addEventListener('click', this.analyticClickEvents, true);
    }

    private analyticClickEvents = (event:MouseEvent)=>{
        //event.preventDefault(); //remove me after debugging

        const possibleChildren = ['SPAN', 'IMG', 'FIGURE', 'FIGCAPTION'],
            allowedElements = ['BUTTON','A'];
        let clickedEle = <Element>event.target,
            eventLabel:string = null;

        // if the event targeted a descendant of an interactive element, get the parent
        if (possibleChildren.includes(clickedEle.tagName)) {
            // bring our focus back to the interactive element (parent)
            // nulls if unable to find a parent link/button
            clickedEle = clickedEle.closest('a,button');
        }

        if(clickedEle){   
            if (allowedElements.includes(clickedEle.tagName)) {
                // default
                eventLabel = (<HTMLAnchorElement>clickedEle).innerText;
                // find out if this came from Connect With Us
                if (clickedEle.matches('.social > a')) {
                    eventLabel = `Connect With Us > ${(<HTMLAnchorElement>clickedEle).title}`;
                }
                // find out if it came from a section link
                if (clickedEle.matches('.accordion > a')) {
                    // get related accordion button
                    const titleButton = clickedEle.closest('section').querySelector('.titleButton') as HTMLButtonElement;

                    eventLabel = `${titleButton.innerText} > ${(<HTMLAnchorElement>clickedEle).innerText}`;
                }
                // did they expand the accordion button?
                if (clickedEle.classList.contains('titleButton')) {
                    eventLabel = `(Mobile) ${clickedEle.getAttribute('aria-expanded') === 'true'?'Expanded':'Collapsed'} ${(<HTMLAnchorElement>clickedEle).innerText} Section`;
                }
                // expert Link
                if (clickedEle.classList.contains('expertFigure')) {
                    eventLabel = `Expert Figure Link > ${(<HTMLAnchorElement>clickedEle).title}`;
                }
                // BBB logo link
                if(clickedEle.hasAttribute('imagealt')){
                    eventLabel = clickedEle.getAttribute('imagealt')
                }
                // our email sign up button
                if (clickedEle.id === 'btnEmailSignUp') {
                    eventLabel = 'Email Sign Up Submit Button';
                }
                if (eventLabel) {
                    let openMethod: linkOpen = linkOpen.self;
                    if (clickedEle.tagName === 'A') {
                        // event.preventDefault(); // we'll manually redirect in analytics
                        if ((<HTMLAnchorElement>clickedEle).target == '_blank' || event.shiftKey) openMethod = linkOpen.newWindow;
                        if (event.ctrlKey) openMethod = linkOpen.newTab;
                    }
                    
                    AnalyticInteractions.footerEvent(GA_eventActions.click, eventLabel, 0, clickedEle as HTMLAnchorElement | HTMLButtonElement, openMethod);
                    //console.info(eventLabel);
                }
            }
        }
    }

    private toggleAccordionButtonMode = (button?: HTMLButtonElement) => {
        // enable or disable the button accordion toggle-ability, based on viewport
        // also set appropriate aria tags for screen readers
        const buttons: Array<HTMLButtonElement> = typeof button !== 'undefined' ? [button] : Array.from(document.querySelectorAll('#footer button.titleButton')) as Array<HTMLButtonElement>; // if we pass a specific button, operate on that (initial generation), otherwise grab all relevant

        for (let index = 0; index < buttons.length; index++) {
            const thisButton:HTMLButtonElement = buttons[index]; // get button by index
            if (domHelpers.isSmallScreen()) {
                thisButton.disabled = false;
                thisButton.setAttribute('aria-pressed', 'false');
                thisButton.setAttribute('aria-expanded', 'false');
            } else {
                thisButton.disabled = true;
                thisButton.setAttribute('aria-pressed', 'true');
                thisButton.setAttribute('aria-expanded', 'true');
            }
        }
    };

    private toggleAccordionMode = () => {
        const accordions: NodeListOf<HTMLElement> = document.querySelectorAll('footer .accordion'),
            tabletWidthCheck:boolean = window.matchMedia('(min-width: 577px)').matches, // in the footer, we allow desktop behavior at tablet resolution
            isMobile: boolean = domHelpers.isSmallScreen() && !tabletWidthCheck;

        for (let index = 0; index < accordions.length; index++) {
            const accordion:HTMLElement = accordions[index];
            let expandedHeight:number;
            // because we have different margins among links between mobile and desktop, we need to reset the container's height to the initial state when
            // we change to/from the mobile and desktop media breakpoints, to make sure it'll expand to show the full list
            accordion.style.height = null; // reset to the fully open state
            expandedHeight = accordion.offsetHeight;
            accordion.setAttribute('data-initial-height', expandedHeight.toString()); // record the opened height

            // accordion always expanded on desktop
            if(isMobile){
                accordion.setAttribute('aria-hidden', isMobile.toString());
                accordion.style.height = (isMobile ? '0' : expandedHeight) + 'px';
            }
        }
    };
    private plusIcon = ():HTMLSpanElement => {
        return domHelpers.createIcon(this._plusIconClass, true); // utilize createIcon helper with our known class
    };

    private createPhoneText = (phoneNumber: string) => {
        const phoneElement = document.createElement('div'), // create the container
            // generate the phone icon and append it
            phoneIcon = domHelpers.createIcon('icon-header-phone', true);
        let phoneText: HTMLAnchorElement;

        phoneIcon.setAttribute('role', 'presentation');
        // append our text node after appending our icon
        phoneText = phoneElement.appendChild(domHelpers.createHyperlink(phoneIcon.outerHTML + 'Call ' + phoneNumber, { href: 'tel: ' + phoneNumber, title: 'Call our office' })); // create a text node we can insert into the container
        // set the class
        phoneElement.className = 'phoneDisplay';
        phoneText.className = 'phone';
        return phoneElement;
    };
    private populateFooterLinks = (footerData:any) => {
        if (footerData) {
            for (let key in footerData) {
                const dataRow = footerData[key],
                    // Containers
                    linkGroupSection:HTMLElement = document.createElement('section'),
                    accordionDiv:HTMLDivElement = this.createAccordionDiv(key),
                    // Title
                    accordionButton:HTMLButtonElement = this.createButton(key);
                // Links
                for (let link in dataRow) {
                    const linkData = dataRow[link];
                    // iterate the data and generate the appropriate DOM element for each
                    accordionDiv.appendChild(this.generateElement(link, linkData));
                }

                // with our powers combined...
                linkGroupSection.appendChild(accordionButton);
                linkGroupSection.appendChild(accordionDiv);
                this.append(this._siteLinksSection, linkGroupSection);

                // now we have the accordion on the DOM, record its height so we we know what to expand it to when in mobile resolution
                accordionDiv.setAttribute('data-initial-height', accordionDiv.offsetHeight.toString());

                if (domHelpers.isSmallScreen()) {
                    // we're in mobile, so collapse our accordion
                    accordionDiv.style.height = "0";
                }
            }
        } else {
            // something bad happened, we have no data!
        }
    };
    private append = (containerGroup:NodeListOf<HTMLElement>, element:HTMLElement|HTMLSpanElement|DocumentFragment) => {
        // because we grab our section(s) using querySelectorAll, we need to iterate through possible containers
        // even just one result will return a node list object
        for (let index = 0; index < containerGroup.length; index++) {
            const container:HTMLElement = containerGroup[index];
            
            container.appendChild(element.cloneNode(true));
        }
    };
    private populateSocialConnectLinks = (socialLinkData:any) => {
        // social links are social media logo icons, generated via an icon font
        for (let socialLink in socialLinkData) {
            if (socialLinkData.hasOwnProperty(socialLink)) {
                const linkData = socialLinkData[socialLink],
                    // create the icon, which will be an empty <span> with the passed class
                    icon = domHelpers.createIcon(linkData['icon'], true),
                    // create the hyperlink object and toss the icon inside it
                    link = domHelpers.createHyperlink(icon, linkData);
                // set accessibility tags
                link.setAttribute('aria-label', 'Visit our ' + socialLink + ' page.');
                // insert the icon into our social containers
                this.append(this._socialLinksSections, link);
            }
        }
    };
    private populateStoreLinks = (siteLinkData:any) => {
        // Containers
        const linkGroupSection = document.createElement('section'); // this section encapsulates our button and ALL of the links
        linkGroupSection.appendChild(this.createButton('Shop Our Stores')); // create the title button, add to section
        const accordionDiv = linkGroupSection.appendChild(this.createAccordionDiv('Shop Our Stores')); // create accordion div, add to section and reference into variable

        for (let siteLink in siteLinkData) {
            if (siteLinkData.hasOwnProperty(siteLink)) {
                const linkData = siteLinkData[siteLink],
                    // generate the link element
                    accordionLink = domHelpers.createHyperlink(siteLink, linkData);
                // append it to the accordion
                accordionDiv.appendChild(accordionLink);
            }
        }
        // link group section contains button and accordion div
        this.append(this._storeLinksSection, linkGroupSection);

        // now we have the accordion on the DOM, get it's height
        accordionDiv.setAttribute('data-initial-height', accordionDiv.offsetHeight.toString());
    };
    private createButton = (key:string) => {
        const returnButton = document.createElement('button'); // create the button element
        returnButton.setAttribute('id', this.buttonID(key)); // set a unique ID
        returnButton.className = 'titleButton'; // set the class name
        returnButton.innerText = key; // set the display text
        returnButton.addEventListener('click', this.toggleAccordion);
        returnButton.setAttribute('aria-controls', this.accordionID(key)); // set aria-controls value to the accordion ID (accessibility)
        returnButton.setAttribute('aria-pressed', (!domHelpers.isSmallScreen()).toString()); // set aria-pressed value to indicate default state of toggled
        returnButton.setAttribute('aria-expanded', (!domHelpers.isSmallScreen()).toString()); // set aria-expanded value to indicate default state of toggled
        returnButton.disabled = true; // set initial default state of disabled (desktop, non-accordion)
        returnButton.appendChild(this.plusIcon()); // add the + icon
        return returnButton; // return the element directly
    };
    private createAccordionDiv = function (key:string) {
        const returnDiv = document.createElement('div'); // create the container div
        returnDiv.setAttribute('id', this.accordionID(key)); // set a unique id
        returnDiv.className = 'accordion'; // set the accordion class

        return returnDiv; // return the element directly
    };

    private populateMiscLinks = (miscLinkData:miscLink) => {
        Object.entries(miscLinkData).forEach(([key, value]) => {
            if (!value.onclick) this.append(this._miscLinksSection, this.generateElement(key, value));
            else {
                const linkButton = document.createElement('button');
                linkButton.setAttribute('onclick', value.onclick);
                linkButton.innerText = value.title;
                this.append(this._miscLinksSection, linkButton);
            }
        });
    };
    private generateElement = (element:string|HTMLElement, elementData:any) => {
        // determine from the passed data what kind of DOM element 
        // we need to generate for the accordion item
        // element can be either text or a dom element

        if (elementData['href']) {
            // hyperlink        
            return domHelpers.createHyperlink(element, elementData);
        } else {
            // special cases that may not necessarily be links        
            if (typeof elementData === 'string') {
                // these cases are text only
                if (element === 'phone') {
                    // special case with phone generates with an icon and special markup
                    return this.createPhoneText(elementData);
                } else {
                    // normal case, just a span text
                    return domHelpers.createSpan(elementData);
                }
            } else if (typeof elementData === 'object') {
                // we might have an array of things to generate, 
                // organized within a category, but needing to generate
                // top level (not with nested DOM markup)
                const multipleElementReturn = document.createDocumentFragment();
                for (let item in elementData) {
                    if (elementData.hasOwnProperty(item)) {
                        const thisItem = elementData[item];
                        // use recursion to make sure we're generating the correct element
                        // append it to a documentFragment so we can return all at once
                        multipleElementReturn.appendChild(this.generateElement(item, thisItem));
                    }
                }
                return multipleElementReturn;
            }
        }
    };
    private generateExpert = (expertData:expertData) => {
        const expertLink = document.createElement('a'), // create the anchor tag that will wrap around the figure and be positioned
            expertContainer = expertLink.appendChild(document.createElement('figure')); // figure markup

        expertContainer.appendChild(domHelpers.createImage({ src: expertData.imageURL, loading:'lazy',class:'lazy', alt: 'Photo of ' + expertData.expertName + ', our ' + expertData.expertTitle })); // image markup

        const expertDivCaption = expertContainer.appendChild(document.createElement('figcaption')), // caption markup
            expertName = expertDivCaption.appendChild(document.createElement('strong')), // name portion of the caption text
            expertTitle = expertDivCaption.appendChild(document.createElement('span')); // title portion of the caption text

        expertLink.className = 'expertFigure';                  // set our class for styling and positioning
        expertLink.href = expertData.href;                      // set our hyperlink URL
        expertLink.title = expertData.title;                    // set our link title
        expertName.innerText = expertData.expertName + ', ';    // set the expert name in the caption
        expertTitle.innerText = expertData.expertTitle;         // set the expert title in the caption

        return expertLink;  // return the entire link that encases the figure
    };
    private createExpertElements = (expertData:any) => {
        const expertElement = this.generateExpert(expertData); // generate entire markup for an expert link

        this.append(this._expertSections(), expertElement);
    };
    private createBBBBadges = (bbbData:bbbJSON) => {
        const badgeImage = domHelpers.createImage({ src: bbbData.imageURL, alt: bbbData.imageAlt, width: 165, height:63 }), // create the button
            badgeLink = domHelpers.createHyperlink(badgeImage, bbbData), // create the link
            extraSection = document.createElement('section'),
            gaRating = document.createElement('g:ratingbadge');

        badgeLink.className = 'BBBLogo';

        //this.append(this._BBBSections, badgeLink);

        gaRating.setAttribute('merchant_id', document.body.dataset.meId);
        document.body.dataset.meId = "";

        extraSection.appendChild(badgeLink.cloneNode(true));
 
        if (!domHelpers.isSmallScreen()) {
            const script = document.body.appendChild(document.createElement('script')) as HTMLScriptElement;
            script.src="https://apis.google.com/js/platform.js";
            script.async = true;
            script.defer = true;
            extraSection.appendChild(gaRating);
        }

        this._BBBSections[0].appendChild(extraSection);
        this._BBBSections[1].appendChild(badgeLink);
        this._BBBSections[2].appendChild(badgeLink);
    };
    private emailSubmitCallback = (result:any) => {
        const response = JSON.parse(result),
            spinner = this._emailForm.querySelector('.PED_Spinner') as HTMLElement
        if (response.succeed) {
            // success
            // remove current form content
            while (this._emailForm.firstChild) {
                this._emailForm.removeChild(this._emailForm.firstChild);
            }
            // (updated for screen reader) display message in empty successMessage span
            const submitMessage = this._footerContainer.querySelector('.successMessage') as HTMLElement
            submitMessage.innerHTML = response.message;
        } else {
            // fail
            this._emailInput.disabled = false;    // re-enable the email input box
            this._emailSignUpButton.removeAttribute('hidden');    // show the submit button again
            this._emailInput.setCustomValidity(response.message); // set the email box's validation message

            this._emailInput.setAttribute('aria-invalid', true.toString());  // set aria invalid for accessibility
            this._emailInput.classList.add('invalid');    // add our invalid class
            spinner.style.display = 'none'; // hide our spinner
            eventHelpers.bindEvent(this._emailInput, ['change', 'input'], this.validateEmail);

            this.reportValidity(this._emailForm); // use this to actually display the validation message
        }
    };
    private reportValidity = (form: HTMLFormElement) => {
        // first initialize the validity check
        form.checkValidity();

        // workaround for browsers that do not support form.reportValidity
        if ('reportValidity' in form) {
            // this will display the validation message when following checkValidity
            form.reportValidity();
        } else {
            if (this._emailInput.validationMessage) {
                // fallback to some other way of notifying the user
                alert(this._emailInput.validationMessage);
            }
        }
    };
    private validateEmail = (event:Event) => {
        const thisInput = event.target as HTMLInputElement;
        thisInput.setCustomValidity('');
        // meant to provide visual feedback of validation while interacting with the control
        if (thisInput.validity.valid) {
            // remove any error display & stop watching
            eventHelpers.unbindEvent(thisInput, ['change', 'input'], this.validateEmail);
            thisInput.setAttribute('aria-invalid', false.toString());
            thisInput.classList.remove('invalid');
        }
    };
    private submitForm = (event:Event) => {
        event.preventDefault(); // prevent default form submit behavior
        const thisForm = event.target as HTMLFormElement, spinner = thisForm.querySelector('.PED_Spinner') as HTMLElement,
            parameters = { email: escape(this._emailInput.value) };

        if (this._emailInput.validity.valid) {
            this._emailInput.setAttribute('aria-invalid', false.toString());
            this._emailInput.classList.remove('invalid');
            this._emailInput.disabled = true; // disable email input to prevent altering value
            this._emailSignUpButton.setAttribute('hidden', true.toString()); // hide submit button to prevent accidental re-submit
            spinner.style.display = 'block'; // show the loading spinner to indicate something is happening
            ajaxHelpers.post(this._emailPostAjaxDest, parameters, this.emailSubmitCallback); // handle submit - posts to emailPostAjaxDest value
        } else {
            this._emailInput.setAttribute('aria-invalid', true.toString());
            this._emailInput.classList.add('invalid');
            // watch the input
            eventHelpers.bindEvent(this._emailInput, ['change', 'input'], this.validateEmail);

            // notify the user
            this.reportValidity(thisForm);

        }
        return false;
    };
    private toggleAccordion = (event:KeyboardEvent|MouseEvent) => {
        const isPlusIcon = (<Element>event.target).classList.contains('icon-plus'),
            thisButton = isPlusIcon ? (<Element>event.target).parentElement as HTMLButtonElement : event.target as HTMLButtonElement,   // reference the button that was clicked to toggle the accordion
            siblingAccordion = document.getElementById(thisButton.getAttribute('aria-controls')),   // the accordion associated with this button
            initialHeight = siblingAccordion?.getAttribute('data-initial-height'), // the height we determined when we called privateMethods.dom.toggleAccordionMode
            isExpanded = function (accordionHeight = 0) { return accordionHeight > 0; }(siblingAccordion?.clientHeight); // get current expanded state. Local Function, so we re-evaluate on each call

        // prevent default behavior so we don't get any odd form submissions or anything
        event.preventDefault();
        // animate using TweenLite if it's available
        gsap.to(siblingAccordion, { duration: .5, height: isExpanded ? 0 : initialHeight ?? 0 });

        // set accessibility attributes
        thisButton.setAttribute('aria-pressed', (!isExpanded).toString());
        thisButton.setAttribute('aria-expanded', (!isExpanded).toString());
        siblingAccordion.setAttribute('aria-hidden', (isExpanded).toString());
    };
    private listenResize = () => {
        clearTimeout(this._timer_id);

        // throttle using our timer to keep this event from firing too frequently
        this._timer_id = window.setTimeout(() => {
            if (this._priorWidth != domHelpers.getScreenWidth()) {
                // "refresh" our accordions
                this.toggleAccordionMode();
                // "refresh" our buttons
                this.toggleAccordionButtonMode();
                // record our new width for the next comparison
                this._priorWidth = domHelpers.getScreenWidth();
            }
        }, 300);

    };

    private buttonID = (key:string) => {
        // helps create consistently styled IDs to help with accessibility linking
        return domIdPrefixes.button + domHelpers.stripSpaces(key);
    };
    private accordionID = function (key:string) {
        // helps create consistently styled IDs to help with accessibility linking
        return domIdPrefixes.accordion + domHelpers.stripSpaces(key);
    };
};