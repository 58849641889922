import {
    domHelpers as DH,
    PEDButtonType,
    eventHelpers,
    domHelpers
} from '../helpers/helpers';
import { gsap } from 'gsap';
import { ModuleState, SummaryState, location } from '../../app/components/cart/ItemCount/types'
import { mobileNavHeader } from '../navigation/mobileNavModules';
import {
    AnalyticInteractions,
    GA_eventActions,
    GA_EventValues,
    GA_eventCategories
} from '../helpers/AnalyticHelper';

enum formIDs {
    desktop = 'desktopChangeLocation',
    mobile = 'mobileChangeLocationForm'
};

/**
 * Class to handle all things related to change location form
 *
 * @export
 * @class changeLocationForm
 */
export class changeLocationModule {
    protected _formElement: HTMLFormElement;
    protected _formTitle: string;
    protected _formDescription: string;
    protected _currentZipCode: string;
    protected _updateButton: HTMLButtonElement;
    protected _zipInputTextBox: HTMLInputElement;
    protected _validatorLabel: HTMLElement;
    protected _validatorLabelIcon: HTMLSpanElement;
    protected _closeButton: HTMLButtonElement;
    protected _canToggle: boolean = true;
    protected _toggleButton: HTMLButtonElement;
    protected _timerID: number;
    protected _onSubmitCallback: Function;
    protected _locObject: location;
    protected _csrfToken: string;

    /**
     *Creates an instance of changeLocationModule.
     * @param {HTMLButtonElement} toggleButton
     * @param {string} currentZip
     * @param {Function} [onSubmit]
     * @param {string} [formTitle="Change Location"]
     * @param {string} [formDescription="Update your ZIP Code below to get the best shopping experience for your area."]
     * @memberof changeLocationModule
     */
    constructor(
        toggleButton: HTMLButtonElement,
        currentZip?: string,
        onSubmit?: Function,
        formTitle: string = "Change Location",
        formDescription: string = "Update your ZIP Code below to get the best shopping experience for your area.",
    ) {
        // listen for incoming cart data updates
        document.body.addEventListener('cart:data', this.receiveCartData);
        document.body.addEventListener('location:update', this.receiveCartData);
        // set our properties
        this._formTitle = formTitle;
        this._formDescription = formDescription;

        // if we passed an additional callback for the onsubmit action, set it here
        if (onSubmit) {
            this._onSubmitCallback = onSubmit;
        }

        if (this.isLegacy()) {
            window.siteData.legacyToken.then((data: string) => {
                this._csrfToken = data;
            }).catch((err) => {
                // fail gracefully
            });
        }
        window.siteData.moduleData.then((data: ModuleState) => {
            this._locObject = data.cart.location;
            this._currentZipCode = currentZip || this._locObject.postalCode;
            if (!this.isLegacy()) {
                this._csrfToken = data.cart.csrf;
            }
            if (this._onSubmitCallback) {
                this._onSubmitCallback(this._locObject.postalCode);
            }

            // set the toggle button and events
            this._toggleButton = toggleButton || document.querySelector(
                '.location .changeLocationDialog'
            ) as HTMLButtonElement;
            this._toggleButton.onclick = this.toggleLocationDialog;
            this._toggleButton.addEventListener('keyup', this.keyAttemptToggleDialog);
            this._toggleButton.disabled = false;
            // generate the form so it's ready when we need it
            this.scaffoldForm();
        }).catch((err) => {
            console.log(err);
            // fail gracefully
        });
    }

    //#region getters/setters
    /**
     * Return an instance of our form element
     *
     * @readonly
     * @type {HTMLFormElement}
     * @memberof changeLocationForm
     */
    get formElement(): HTMLFormElement {
        return this._formElement;
    }

    /**
      * Get the currently set zip code
      *
      * @readonly
      * @type {string}
      * @memberof changeLocationForm
      */
    get currentZipCode(): string {
        return this._currentZipCode;
    }
    /**
     * set the current zip code.  Meant for synchronization between mobile and 
     * desktop modules
     *
     * @memberof changeLocationModule
     */
    set currentZipCode(value: string) {
        this._currentZipCode = value;
    }
    //#endregion getters/setters

    /**
     * Create & assemble our form elements
     *
     * @protected
     * @memberof changeLocationForm
     */
    protected scaffoldForm() {
        // create & assemble our form elements
        const fieldSet = DH.createElement('fieldset') as HTMLFieldSetElement,
            formLabel = fieldSet.appendChild(DH.createElement('legend', this._formTitle)),
            description = fieldSet.appendChild(DH.createElement('p', this._formDescription)),
            formLabelID = 'changeLocationLabel', descriptionID = 'changeLocationDesc',
            zipInputGroup = fieldSet.appendChild(domHelpers.createElement('div') as HTMLElement);


        this._zipInputTextBox = zipInputGroup.appendChild(DH.createTextInput());
        this._validatorLabel = zipInputGroup.appendChild(DH.createElement('div'));
        this._updateButton = fieldSet.appendChild(DH.createButton(PEDButtonType.special));
        this._formElement = DH.createElement('form', fieldSet) as HTMLFormElement;
        this._closeButton = this._formElement.appendChild(DH.createButton(PEDButtonType.none));

        // set some properties
        this._formElement.setAttribute('role', 'dialog');
        this._formElement.setAttribute('aria-labelledby', formLabelID);
        this._formElement.setAttribute('aria-describedby', descriptionID);
        this._formElement.setAttribute('aria-hidden', true.toString());
        this._formElement.id = formIDs.desktop;
        this._formElement.noValidate = true;

        eventHelpers.bindEvent(this._formElement, ['submit'], this.submitChangeLocation);
        DH.trapFocus(this._formElement);

        formLabel.id = formLabelID;

        description.id = descriptionID;

        zipInputGroup.classList.add('inputGroup');

        this._zipInputTextBox.id = "txtChangeZip";
        this._zipInputTextBox.placeholder = "Enter Zip Code";
        this._zipInputTextBox.required = true;
        this._zipInputTextBox.setAttribute('aria-label', 'Enter Zip Code');
        this._zipInputTextBox.setAttribute('aria-describedBy', descriptionID);
        this._zipInputTextBox.setAttribute('aria-required', true.toString());
        this._zipInputTextBox.value = this._currentZipCode;
        this._zipInputTextBox.pattern = `^[0-9]{5}(?:-[0-9]{4})?$`;
        this._zipInputTextBox.title = "Valid five digit US ZIP ##### or extended ZIP+4 #####-####"
        this._validatorLabelIcon = DH.createIcon('icon-exclamation-circle', true);
        this._validatorLabel.className = "inputWarning";
        this._validatorLabel.appendChild(this._validatorLabelIcon);

        this._updateButton.classList.add("btnChangeLocation");
        this._updateButton.innerHTML = "Update";

        this._closeButton.className = "locationClose";
        this._closeButton.setAttribute('aria-label', "Close Location Change Dialog");
        this._closeButton.innerHTML = "&times;";
        this._closeButton.onclick = this.toggleLocationDialog;
    }

    protected reportValidity(): void {
        // first initialize the validity check
        this._formElement.checkValidity();

        // workaround for browsers that do not support form.reportValidity
        if ('reportValidity' in this._formElement) {
            // this will display the validation message when following 
            // checkValidity
            this._formElement.reportValidity();
        } else {
            if (this._zipInputTextBox.validationMessage) {
                // fallback to some other way of notifying the user
                alert(this._zipInputTextBox.validationMessage);
            }
        }
    }

    protected toggleLocationDialog = (event: Event) => {
        // keep this button from doing anything other than what we want
        event.preventDefault();
        if (event.type === 'submit' && !this._canToggle) {
            return false;
        }

        // toggle the display of the form, using ARIA tags (CSS will target 
        // these for toggling visibility)
        if (this._formElement.getAttribute('aria-hidden') === 'true') {
            this.appendToDesktop();
            this._formElement.setAttribute('aria-hidden', 'false');
            // When the dialog appears on the screen, keyboard focus should be 
            // moved to the default focusable control inside the dialog. 
            this._zipInputTextBox.focus();
            // Analytic it!
            AnalyticInteractions.headerEvent(
                GA_eventActions.click,
                "Open change location dialog",
                GA_EventValues.ChangeLocationOpen,
                event.target as HTMLButtonElement
            );
        } else {
            this._canToggle = false;
            // toggle aria-hidden to both hide from readers and trigger the css 
            // fadeout animation
            this._formElement.setAttribute('aria-hidden', 'true');
            // clear the opacity value we animated to let the css take over
            setTimeout(() => {
                // "refresh" our accordions
                this.removeFromDesktop();
            }, 300); // 300 should match the transition duration in the css for 
            // this form's opacity

            // unbind these listeners so we don't trigger the dialog open again 
            // if enter was pushed to close
            this._toggleButton.focus();
            this.resetTimer();
        }

        return false;
    };

    protected submitChangeLocation = (event: MouseEvent) => {
        this._canToggle = false;
        // keep this button from doing anything other than what we want
        event.preventDefault();
        this._updateButton.disabled = true;
        this._updateButton.classList.add('disabled');
        this._updateButton.setAttribute('aria-disabled', true.toString());
        if (![5, 9, 10].includes(this._zipInputTextBox.value.length)) {
            this._zipInputTextBox.setCustomValidity(
                'Length of postal code must be 5 digits.'
            );
        } else {
            if (isNaN(parseInt(this._zipInputTextBox.value))) {
                this._zipInputTextBox.setCustomValidity(this._zipInputTextBox.title);
            } else {
                this._zipInputTextBox.setCustomValidity('');
            }
        }

        // validate
        if (this._zipInputTextBox.validity.valid == true) {
            let backend: string;
            if (document.body.dataset.legacy === '1') {
                backend = '/update-location.php';
            } else {
                backend = '/shopping/cart';
            }
            const payload: { postalCode: string; productId?: number } = {
                postalCode: this._zipInputTextBox.value,
            };

            let productId = null;
            const pdpDataEl = document.getElementById('pdpData');
            if (pdpDataEl) {
                const pdpData = JSON.parse(decodeURIComponent(pdpDataEl.dataset.pdp));
                productId = pdpData.productJSON.id;
            }
            if (productId) {
                payload.productId = productId;
            }

            document.body.dispatchEvent(new CustomEvent('cart:evt', {
                detail: {
                    action: 'location:update',
                    url: backend,
                    csrf: this._csrfToken,
                    payload: payload
                }
            }));

            AnalyticInteractions.tealium_changeLocationSubmit(
                this._currentZipCode,
                GA_eventActions.click,
                GA_eventCategories.Header,
                `Submit new location change: ${this._currentZipCode}`
            );

            if (!DH.isSmallScreen()) {
                this.toggleLocationDialog(event);
            }
        } else {
            this._zipInputTextBox.setAttribute('aria-invalid', true.toString());
            this._zipInputTextBox.classList.add('invalid');

            // notify the user
            this.reportValidity();
            this._zipInputTextBox.setCustomValidity('');
        }
    };

    protected receiveCartData = (evt: CustomEvent) => {
        let payload: SummaryState;
        if (evt.type === 'location:update') {
            payload = evt.detail.slices.cart;
            this._canToggle = true;
            this._updateButton.disabled = false;
            this._updateButton.classList.remove('disabled');
            this._updateButton.setAttribute('aria-disabled', false.toString());
            if (evt.detail.success) {
                this.poupulateWithPayload(payload);
                if (this._formElement.getAttribute('aria-hidden') === 'false') {
                    this.toggleLocationDialog(evt);
                }
            } else {
                this._zipInputTextBox.setAttribute('aria-invalid', true.toString());
                this._zipInputTextBox.classList.add('invalid');
            }
        } else {
            payload = evt.detail;
            if (payload.location.postalCode != this._currentZipCode) {
                this.poupulateWithPayload(payload);
            }
        }
    };

    private positionChangeLocationPopup = () => {
        // Maths based on the size of the button, the form, and the container 
        // (for dialog positioning)
        // find how tall the container is
        var divHeight = this._toggleButton.parentElement.offsetHeight;
        // maths to position the dialog below the change location button
        this._formElement.style.top = (divHeight + 10) + 'px';
    }

    private appendToDesktop() {
        this._toggleButton.parentElement.appendChild(this._formElement);

        this.positionChangeLocationPopup();
    }
    private removeFromDesktop() {
        if (this._formElement.parentElement) {
            this._formElement.parentElement.removeChild(this._formElement);
        }
    }
    //#region Events
    private keyAttemptToggleDialog = (event: KeyboardEvent) => {
        // toggle dialog if enter is pressed
        if (event.key == 'Enter' && this._canToggle) {
            // enter was pressed, open/close the dialog
            this.toggleLocationDialog(event);
        }
    }

    private poupulateWithPayload = (payload: SummaryState) => {
        this._currentZipCode = payload.location.postalCode;
        this._zipInputTextBox.value = this._currentZipCode;
        this._zipInputTextBox.setAttribute('aria-invalid', false.toString());
        this._zipInputTextBox.classList.remove('invalid');
        this._validatorLabelIcon.setAttribute('aria-hidden', true.toString());
        if (this.isLegacy()) {
            window.siteData.legacyToken.then((data: string) => {
                this._csrfToken = data;
            });
        } else {
            this._csrfToken = payload.csrf;
        }
        if (this._onSubmitCallback) {
            this._onSubmitCallback(payload.location.postalCode);
        }

        // @todo: move these events to the components where they belong

        // I don't think we need to reload on hybrid anymore

        // const pageType = document.body.dataset.category;

        // if (pageType == 'hybrid') {
        //     // reload page instead of dispatchEvent for hybrid page
        //     window.location.reload();
        // }
        this._updateButton.disabled = false;
        this._updateButton.classList.remove('disabled');
        this._updateButton.setAttribute('aria-disabled', false.toString());
        
        const pdpZip = <HTMLInputElement>document.getElementById('c_zip');
        const pdpShipToZip = document.getElementsByClassName('shipToZip')[0] as HTMLElement;
        if (pdpZip) {
            pdpZip.value = `${this._currentZipCode}`;
        }
        if (pdpShipToZip) {
            pdpShipToZip.textContent = `${this._currentZipCode}`;
        }

        if (document.body.dataset.legacy === '1') {
            // shopping cart zip update
            const shoppingZipArrow = <HTMLInputElement>document.getElementById('blue_arrow_os');
            const shoppingZip = <HTMLInputElement>document.getElementById('os_zipcode_txtbx');
            const shoppingZipBtn = <HTMLInputElement>document.getElementById('os_zipcode_btn');

            if (shoppingZipArrow) {
                shoppingZipArrow.click();
            }
            if (shoppingZip && shoppingZipBtn) {
                var currentZip = `${this._currentZipCode}`;
                setTimeout(function () {
                    setTimeout(function () {
                        shoppingZipBtn.click();
                    }, 300);
                    shoppingZip.value = currentZip;
                }, 500);
            }
        }
        window.dispatchEvent(new CustomEvent('changedLocation', {
            detail: {
                zipCode: this._currentZipCode,
                state: payload.location.regionCode,
                city: payload.location.locality
            }
        }));
        // if we specified a callback in our constructor, call it here
        if (this._onSubmitCallback) {
            this._onSubmitCallback(this._currentZipCode);
        }
    }

    private resetTimer = () => {
        clearTimeout(this._timerID);

        // throttle using our timer to keep this event from firing too frequently
        this._timerID = window.setTimeout(() => {
            // "refresh" our accordions
            this._canToggle = true;
        }, 300);

    };
    ////#endregion events

    private isLegacy(): boolean {
        return document.body.dataset.legacy === '1';
    }
}


export class mobileChangeLocationModule extends changeLocationModule {
    private _header: mobileNavHeader;
    private _previousHeaderText: string;
    private readonly _tweenSpeed: number = .3;

    /**
     *Creates an instance of changeLocationForm.
     * @param {string} [formTitle="Change Location"]
     * @param {string} [formDescription="Update your ZIP Code below to get the best shopping experience for your area."]
     * @param {string} currentZip
     * @memberof changeLocationForm
     */
    constructor(
        toggleButton: HTMLButtonElement,
        currentZip: string,
        header: mobileNavHeader,
        onSubmit?: Function,
        formTitle: string = "Change Location",
        formDescription: string = "Update your ZIP Code below to get the best shopping experience for your area.",
    ) {
        super(toggleButton, currentZip, onSubmit, formTitle, formDescription);
        this._header = header;
        // make sure we're binding to our instance
        this._toggleButton.onclick = this.toggleLocationDialog;
        this.scaffoldForm();
    }

    /**
     * Create & assemble our form elements
     *
     * @private
     * @memberof changeLocationForm
     */
    protected scaffoldForm() {
        super.scaffoldForm();
        // create & assemble our form elements
        const fieldSet = DH.createElement('fieldset') as HTMLFieldSetElement,
            description = fieldSet.appendChild(DH.createElement('p', this._formDescription)),
            zipParagraph = description.appendChild(document.createElement('p')),
            zipStrong = zipParagraph.appendChild(document.createElement('strong')),
            zipLabel = zipParagraph.appendChild(document.createElement('span')),
            formLabelID = 'changeLocationLabel', descriptionID = 'changeLocationDesc',
            zipInputGroup = fieldSet.appendChild(domHelpers.createElement('div') as HTMLElement);

        this._zipInputTextBox = zipInputGroup.appendChild(DH.createTextInput());
        this._validatorLabel = zipInputGroup.appendChild(DH.createElement('div'));
        this._updateButton = fieldSet.appendChild(DH.createButton(PEDButtonType.special));
        this._formElement = DH.createElement('form', fieldSet) as HTMLFormElement;
        this._closeButton = this._formElement.appendChild(DH.createButton(PEDButtonType.none));

        // set some properties
        this._formElement.setAttribute('role', 'dialog');
        // this._formElement.setAttribute('aria-labelledby', formLabelID);
        // this._formElement.setAttribute('aria-describedby', descriptionID);
        this._formElement.setAttribute('aria-hidden', true.toString());
        this._formElement.id = formIDs.mobile;
        this._formElement.noValidate = true;

        this._formElement.addEventListener('submit', this.submitChangeLocation);
        DH.trapFocus(this._formElement);

        //formLabel.id = formLabelID;
        zipStrong.innerText = 'Current Zip Code:';
        zipLabel.innerText = this._currentZipCode;
        zipLabel.className = 'headerZipCode';
        description.id = descriptionID;

        zipInputGroup.classList.add('inputGroup');

        this._zipInputTextBox.id = "txtChangeZip";
        this._zipInputTextBox.placeholder = "Enter Zip Code";
        this._zipInputTextBox.required = true;
        this._zipInputTextBox.setAttribute('aria-label', 'Enter Zip Code');
        this._zipInputTextBox.setAttribute('aria-describedBy', descriptionID);
        this._zipInputTextBox.setAttribute('aria-required', true.toString());
        this._zipInputTextBox.pattern = `^[0-9]{5}(?:-[0-9]{4})?$`;
        //this._zipInputTextBox.pattern = `^[0-9]{5}?$`;
        this._zipInputTextBox.type = "number";
        // this._zipInputTextBox.maxLength = 5;
        // this._zipInputTextBox.minLength = 5;
        this._zipInputTextBox.title = "Valid five digit US ZIP ##### or extended ZIP+4 #####-####"
        //this._zipInputTextBox.title = "Valid five digit US ZIP #####"
        this._zipInputTextBox.value = escape(this._currentZipCode);

        this._validatorLabel.className = "inputWarning";
        this._validatorLabel.appendChild(domHelpers.createIcon('icon-exclamation-circle', true));

        this._updateButton.classList.add("btnChangeLocation");
        this._updateButton.innerHTML = "Update";

        this._closeButton.className = "locationClose";
        this._closeButton.setAttribute('aria-label', "Close Location Change Dialog");
        this._closeButton.innerHTML = "<span class='icon-cheveron-left'></span>Back";
        this._closeButton.onclick = this.toggleLocationDialog;
    }

    protected toggleLocationDialog = (event: MouseEvent) => {
        event.preventDefault;
        let container = document.querySelector('#mobileChangeLocation') as HTMLElement;
        // toggle the display of the form, using ARIA tags (CSS will target these 
        // for toggling visibility)
        if (this._formElement.getAttribute('aria-hidden') === 'true') {
            //this.appendToDesktop();
            this._formElement.setAttribute('aria-hidden', 'false');
            container.style.display = 'block';
            // hide the toggle link?
            this._toggleButton.style.display = "none";
            // update the header

            this._previousHeaderText = this._header.text;
            this._header.setText(this._formTitle);
            this._header.hideLink();
            // When the dialog appears on the screen, keyboard focus should be 
            // moved to the default focusable control inside the dialog. 
            gsap.to(['#mainMobileSlide'], {
                duration: this._tweenSpeed,
                x: '-=' + 315, onComplete: () => {
                    document.querySelector('#headerNavigation').scrollTop = 0;
                    this._zipInputTextBox.focus();
                }
            });
            // analytics
            AnalyticInteractions.headerEvent(
                GA_eventActions.click,
                "Mobile: Open change location dialog",
                GA_EventValues.ChangeLocationOpen,
                event.target as HTMLButtonElement
            );
        } else {
            this._canToggle = false;
            // show the toggle link?
            this._toggleButton.style.display = "";
            // update the header
            this._header.setText(this._previousHeaderText);
            if (this._header.text != "Main Menu") {
                this._header.showLink();
            }
            gsap.to(['#mainMobileSlide'], {
                duration: this._tweenSpeed,
                x: '+=' + 315, onComplete: () => {
                    this._formElement.setAttribute('aria-hidden', 'true');
                    container.style.display = '';

                    this._toggleButton.focus();
                }
            });
        }

        return false;
    }
}
