import {
	browserTracingIntegration,
	init,
	replayIntegration,
} from '@sentry/browser';
import {
	contextLinesIntegration,
	extraErrorDataIntegration,
	httpClientIntegration,
} from '@sentry/integrations';
import { PED_VERSION } from '../version'; // version file generated at compile time

const isDev = window.location.host.startsWith('dev.');
const conditionalOptions = isDev
    ? {
          // dev options
          environment: 'dev',
          debug: false,
          beforeSend: (event) => {
              debugger;
              const { type, value } = event.exception.values[0];
              console.error(`${type}: ${value}`, event);
              return null; // returning null should prevent this from getting sent to sentry
          },
      }
    : {
          // prod options
          environment: 'production',
          debug: false,
      };

init({
    dsn: 'https://df279085d40e440b8506671031e2c706@o129268.ingest.sentry.io/285342',
    normalizeDepth: 10,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.25,

    integrations: [
        browserTracingIntegration(),
        contextLinesIntegration(),
        extraErrorDataIntegration(),
        // Captures errors on failed requests from Fetch and XHR and attaches request and response information
        httpClientIntegration(),
        replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],

    // improved release tracking using our package version
    release: `${PED_VERSION}`,
	

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.7,

    // Error sample rate
    sampleRate: 1,

    initialScope: {
        tags: { site: window.location.host },
    },

    // special options
    ...conditionalOptions,
});
