export type storeInfoType = {
    id: string; // "powerequipmentdirect.com"
    name: string; // "13"
    domain: string; // "PED"
    initials: string; // "Power Equipment Direct"
    url: string; // "/var/www/vhosts/ped.com/"
    path: string; // "https://dev.powerequipmentdirect.com"
};
export type navDataType = {
    bbbJSON: {
        href: string;
        imageAlt: string;
        imageURL: string;
        title: string;
    };
    checkoutMiscLinkJSON: string;
    expertJSON: {
        expertName: string;
        expertTitle: string;
        href: string;
        imageURL: string;
        title: string;
    };
    headerNavJSON: string;
    iterable: string;
    loginToken: string;
    miscLinkJSON: string;
    phoneNumber: string;
    siteLinkJSON: string;
    socialLinkJSON: string;
    storeInfo: storeInfoType;
    storeLinkJSON: string;
    zipCode: string;
    newNav: string;
};

// get data
const dataDiv = document.getElementById('dataDiv') as HTMLElement,
    masterData: string = dataDiv?.querySelector('#generalData').innerHTML;

export const topBarContent: string =
        dataDiv?.querySelector('#topBarElements').innerHTML,
    navData = JSON.parse(masterData) as navDataType;

// use this for testing data
// console.info(navData)

export function cleanup() {
    dataDiv?.parentElement.removeChild(dataDiv);
}
