import { domHelpers, ajaxHelpers } from '../helpers/helpers';
import { gsap } from "gsap";
import { dimmer } from '../modules/dimmer';
import { AnalyticInteractions, GA_eventActions, GA_EventValues, GA_eventCategories } from '../helpers/AnalyticHelper';

enum openState {
    opened,
    closed
}

enum specialKeys {
    'ArrowUp' = 'ArrowUp',
    'ArrowDown' = 'ArrowDown',
    'Tab' = 'Tab',
    'Enter' = 'Enter',
    'Escape' = 'Escape',
}

interface searchResult {
    value: string,
    data: number,
    exactMatch: boolean,
    text:string,
}

interface article{
    type:string,
    title:string,
    id:number
}

interface product{
    name:string,
    id:number,
    price:number,
    productsUrl:string,
    onSale:boolean,
    rating:number,
    image:string
}

interface recommendations{
    articles:Array<article>,
    products:Array<product>
}

interface searchResults {
    suggestions: Array<searchResult>,
    recommendations: recommendations
}

export class searchModule {
    private _searchForm: HTMLFormElement;
    protected _searchBox: HTMLInputElement;
    private _searchButton: HTMLButtonElement;
    private _isRequesting: boolean = false;

    protected _searchResultContainer: HTMLUListElement;
    private _searchLoader: HTMLLIElement;
    private _searchLeft: HTMLLIElement;
    private _searchRight: HTMLLIElement;
    private _searchBottom: HTMLLIElement;
    private _searchBottomText: HTMLSpanElement;
    private _searchResultParent:HTMLElement;
    private _recommendations:HTMLElement;
    private _recommendationsTitle:HTMLDivElement;

    private _runningVal: string;

    private _searchStr: string;

    constructor(searchForm: HTMLFormElement, searchBox: HTMLInputElement, searchButton: HTMLButtonElement, searchResultsContainer: HTMLUListElement, searchResultParent:HTMLElement) {
        this._searchForm = searchForm;
        this._searchBox = searchBox;
        this._searchButton = searchButton;
        this._searchResultParent = searchResultParent;
        this._searchResultContainer = searchResultsContainer;
        this._searchLoader = this._searchResultParent.querySelector('.searchResultsTop');
        this._searchLeft = this._searchResultParent.querySelector('.searchResultsLeft');
        this._searchRight = this._searchResultParent.querySelector('.searchResultsRight');
        this._searchBottom = this._searchResultParent.querySelector('.searchResultsBottom');
        this._searchBottomText = this._searchBottom.querySelector('.searchNoResultL1').querySelector('.searchText');
        if(this._searchRight){
            this._recommendations = this._searchRight.querySelector('.searchRecommendationsData');
            this._recommendationsTitle = this._searchRight.querySelector('.searchRecommendationsTitle');
        }
        this._searchButton.addEventListener('click', this.submitSearch);
        this._searchBox.addEventListener('keyup', this.delay(this.suggestSearch,300));
        this._searchBox.addEventListener('click', this.checkIfHidden);
        this._searchBox.addEventListener('keydown', this.suggestInteractions);
        this._searchBox.addEventListener('input', (e:KeyboardEvent)=>{if (!this._searchBox.value) {
            this.hideSuggestions();
        }});
        this._searchBox.addEventListener('blur', (e:FocusEvent)=>{
            // when we click off the search box, we want to hide the results if we did not click on them
            const newFocus = e.relatedTarget as HTMLElement;
            const parent = newFocus?.closest('.searchResultsMiddle');
            if(!newFocus?.classList.contains('searchResultsMiddle') && !parent){
                this.hideSuggestions();
            }
        });
    }

    get selectedSuggestion(): HTMLLIElement {
        return this._searchResultContainer.querySelector('li[aria-selected="true"]') as HTMLLIElement;
    }

    private delay = (callback:(event: KeyboardEvent) =>void, ms:number) => {
        let timer = 0;
        return function() {
          var context = this, args = arguments;
          clearTimeout(timer);
          timer = window.setTimeout(function () {
            callback.apply(context, args);
          }, ms || 0);
        };
      }

    private showSuggestions = () => {
        this._searchLoader.style.display = '';
        this._searchResultContainer.hidden = false;
        this._searchLoader.hidden = false;
        this._searchResultParent.style.border = '1px solid #e2e2e2';
        this._searchResultParent.hidden = false;
        if (window.screen.width >= 1520) {
            this._searchRight.style.maxWidth = '800px';
            this._searchResultParent.style.width = '1130px';
        }else if(window.screen.width >= 993){
            this._searchRight.style.maxWidth = '400px';
            this._searchResultParent.style.width = '730px';
        }else{
            if(this._searchRight){
                this._searchRight.style.maxWidth = '';
            }
            this._searchResultParent.style.width = '';
        }
        
        this._searchBox.setAttribute('aria-expanded', true.toString());

    }
    protected hideSuggestions = () => {
        // hide results
        this._searchResultContainer.hidden = true;
        this._searchLoader.hidden = true;
        this._searchLoader.style.display = '';
        this._searchResultParent.style.border = '';
        this._searchResultParent.hidden = true;
        this._searchBox.setAttribute('aria-expanded', false.toString());
        //this.clearSuggestions();
    }
    private clearSuggestions = () => {
        let curLength: number = this._searchResultContainer.children.length;
        // clean the result listing
        while (curLength) {
            let thisChild: HTMLElement = this._searchResultContainer.children[curLength - 1] as HTMLElement;

            if (!thisChild.classList.contains('loader')) {
                this._searchResultContainer.removeChild(thisChild);
            }

            curLength = this._searchResultContainer.children.length;
        }
        if(this._searchRight){
            curLength = this._recommendations.children.length;
            // clean the result listing
            while (curLength) {
                let thisChild: HTMLElement = this._recommendations.children[curLength - 1] as HTMLElement;
                this._recommendations.removeChild(thisChild);
                curLength = this._recommendations.children.length;
            }
        }
    }

    private displayReviewStars = (rating:string) => {
        const temp_star          = rating;
        const stars_rating       = parseInt(temp_star.substring(0, 1));
        let stars_rating_blank = stars_rating;
        const stars_half         = parseInt(temp_star.substring(2, 3));

        const starDiv = document.createElement('div') as HTMLDivElement;
        const starSpan = starDiv.appendChild(document.createElement('span')) as HTMLSpanElement;
        starSpan.classList.add('recommendationItemStar');

        if (stars_rating > 0) {
            for (let i = 0; i < stars_rating; i++) {
                const star = starSpan.appendChild(document.createElement('span')) as HTMLSpanElement;
                star.classList.add('icon-star');
                star.setAttribute('aria-hidden','true');
            }
            if (stars_half >= 1 && stars_half <= 9) {
                const star = starSpan.appendChild(document.createElement('span')) as HTMLSpanElement;
                star.classList.add('icon-star-half-empty');
                star.setAttribute('aria-hidden','true');
                stars_rating_blank = stars_rating_blank + 1;
            }
            for (let i = 0; i < (5 - stars_rating_blank); i++) {
                const star = starSpan.appendChild(document.createElement('span')) as HTMLSpanElement;
                star.classList.add('icon-star-o');
                star.setAttribute('aria-hidden','true');
            }
        } else {
            for (let i = 0; i < 5; i++) {
                const star = starSpan.appendChild(document.createElement('span')) as HTMLSpanElement;
                star.classList.add('icon-star-o');
                star.setAttribute('aria-hidden','true');
            }
        }

        return starDiv;
    }

    private recommendationsProcuts = (result: product) => { 
        let a = this._recommendations.appendChild(document.createElement('a')) as HTMLAnchorElement;
        let item = a.appendChild(document.createElement('figure')) as HTMLElement;
        let left = item.appendChild(document.createElement('img')) as HTMLImageElement;
        let right = item.appendChild(document.createElement('div')) as HTMLElement;
        let name = right.appendChild(document.createElement('div')) as HTMLDivElement;
        let rightBottom = right.appendChild(document.createElement('div')) as HTMLDivElement;

        if(result.productsUrl != ''){
            a.href = result.productsUrl;
        }
        else{
            //In edge cases where products_url in products table is empty, use old redirect method as fallback
            a.href = "someproduct/p"+result.id+'.html';
        }

        left.src = 'products-image/80/'+result.image;
        left.width = 75;
        left.height = 75;
        left.alt = result.name;

        // for content on right side
        right.classList.add('recommendationItemRight');

        // for product name
        name.classList.add('recommendationProductName');
        const searchArr = this._searchStr.split(" ");
        searchArr.forEach(element => {
            //replace matching string with b tag
            const replace = element;
            const str = this.escapeRegExp(replace);
            const re = new RegExp(str+'(?!>)',"gi");
            result.name = result.name.replace(re,function(m){ return `<b>${m}</b>`;} );
        });
        name.innerHTML = result.name;
        rightBottom.classList.add('rightBottom')
        if(result.onSale){
            let onSale = rightBottom.appendChild(document.createElement('div')) as HTMLDivElement;
            onSale.innerText = 'On Sale';
            onSale.classList.add('recommendationOnSale');
        }
        if(result.price > 0){
            let price = rightBottom.appendChild(document.createElement('div')) as HTMLDivElement;
            const n = parseFloat(result.price.toString()).toFixed(2);

            if (result.price == 0.01) {
                price.innerText = '(Call For Price)';
            } else if (result.price == 0.02) {
                price.innerText = '(Add to Cart for Price)';
            } else {
                const withCommas = Number(n).toLocaleString('en',{
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
                price.innerText = '$'+`${withCommas.toString()}`;
                price.classList.add('recommendationPrice');
            }

        }
        if(result.rating > 0){
            let rating = rightBottom.appendChild(document.createElement('div')) as HTMLDivElement;
            rating.appendChild(this.displayReviewStars(result.rating.toString()));
            rating.classList.add('recommendationStar');
        }
    }

    private recommendationsArticles = (result: article) =>{
        let a = this._recommendations.appendChild(document.createElement('a')) as HTMLAnchorElement;
        let item = a.appendChild(document.createElement('figure')) as HTMLElement;
        let left = item.appendChild(document.createElement('span')) as HTMLSpanElement;
        let right = item.appendChild(document.createElement('div')) as HTMLElement;
        let name = right.appendChild(document.createElement('div')) as HTMLDivElement;
        let type = right.appendChild(document.createElement('i')) as HTMLElement;
        

        a.href = "stories/"+result.id+'-someStory.html';
        type.classList.add('recommendationArticleType');
        left.classList.add('svgClass');
        right.classList.add('recommendationItemRight');
        if(result.type.toLowerCase() == 'how-to-tips'){
            left.classList.add('icon-article-how-to');
            type.innerText = 'How-To Tips';
        }else if(result.type.toLowerCase() == 'customer-support'){
            left.classList.add('icon-article-customer-support');
            type.innerText = 'Customer Support';
        }else if(result.type.toLowerCase() == 'buyers-guide'){
            left.classList.add('icon-article-buyers-guides');
            type.innerText = 'Buyers Guide';
        }else if(result.type.toLowerCase() == 'recommended'){
            left.classList.add('icon-article-recommended');
            type.innerText = 'Recommended';
        }
        name.classList.add('recommendationArticlesName');
        const searchArr = this._searchStr.split(" ");
        searchArr.forEach(element => {
            //replace matching string with b tag
            const replace = element;
            const str = this.escapeRegExp(replace);
            const re = new RegExp(str+'(?!>)',"gi");
            result.title = result.title.replace(re,function(m){ return `<b>${m}</b>`;} );
        });
        name.innerHTML = result.title;
    }

    private suggestionItem = (result: searchResult) => {
        let li = this._searchResultContainer.appendChild(document.createElement('li')) as HTMLLIElement;
        let item = li.appendChild(document.createElement('div')) as HTMLElement;
        // temp remove search right
        let itemRight = li.appendChild(document.createElement('button')) as HTMLButtonElement;
        item.classList.add("liDiv");
        const searchArr = this._searchStr.split(" ");
        searchArr.forEach(element => {
            //replace matching string with b tag
            const replace = element;
            const str = this.escapeRegExp(replace);
            const re = new RegExp(str+'(?!>)',"gi");
            result.value = result.value.replace(re,function(m){ return `<b>${m}</b>`;} );
        });

        // replace all white space
        // const re3 = new RegExp(/\s/g,"g");
        // result.value = result.value.replace(re3,`&nbsp`);
        item.id = domHelpers.stripSpaces(result.text);
        item.setAttribute('role', 'option');
        item.setAttribute('data-text', result.text);
        item.setAttribute('aria-selected', false.toString());
        item.tabIndex = -1;
        item.addEventListener('click', (e:Event) => {
            this.selectSuggestion(li);
            this.submitSearch(e);
        });
        // temp remove search right
        itemRight.classList.add('icon-arrow-up-left');
        itemRight.setAttribute('aria-label', 'Refresh Search Result For Keyword '+result.text);
        itemRight.addEventListener('click', () => {
            this.selectSuggestion(li);
            this.refreshSuggest();
        });

        item.innerHTML = result.value;
        //item.innerHTML = result.text;
    }

    private escapeRegExp = (string: string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    private reportValidity = () => {
        // first initialize the validity check
        this._searchForm.checkValidity();

        // workaround for browsers that do not support form.reportValidity
        if ('reportValidity' in this._searchForm) {
            // this will display the validation message when following checkValidity
            this._searchForm.reportValidity();
        } else {
            if (this._searchBox.validationMessage) {
                // fallback to some other way of notifying the user
                alert(this._searchBox.validationMessage);
            }
        }
    };
    
    private suggestionsReceived = (data: string) => {
        this._isRequesting = false;
        this._searchLoader.style.display = 'none';
        this._searchLoader.hidden = true;
        this._searchLeft.hidden = false;
        if(this._searchRight){
            if(window.screen.width >= 993){
                this._searchRight.hidden = false;
                this._searchRight.style.display = 'block';
            }else{
                this._searchRight.hidden = true;
                this._searchRight.style.display = 'none';
            }
            // temp remove search right
            // this._searchRight.hidden = true;
            // this._searchRight.style.display = 'none';
            // this._searchLeft.style.borderRight = 'unset';
            // this._searchLeft.style.maxWidth = 'unset';
        }
        this._searchBottom.style.display = 'none';
        this._searchBottom.hidden = true;
        this._searchResultParent.hidden = false;
        if (data) {
            let results = JSON.parse(data) as searchResults;
            // get rid of our old results
            this.clearSuggestions();
            if(results.suggestions.length >0){
                this._searchResultParent.style.display = '';
                for (const key in results.suggestions) {
                    if (results.suggestions.hasOwnProperty(key)) {
                        const result = results.suggestions[key] as searchResult;
                        result.text = result.value;
                        this.suggestionItem(result);
                    }
                }
            }else{
                //this._searchResultParent.style.display = 'none';
                //this._searchBottom.style.display = '';
                //this._searchBottom.hidden = false;
                this._searchLeft.hidden = true;
                // if(this._searchRight){
                //     this._searchRight.hidden = true;
                //     this._searchRight.style.display = 'none';
                // }
                //this._searchResultParent.hidden = false;
            }

            if(results.recommendations && ((typeof results.recommendations.products !== 'undefined' && Object.keys(results.recommendations.products).length > 0) || (typeof results.recommendations.articles !== 'undefined' &&  Object.keys(results.recommendations.articles).length > 0))){
                let pCount = 0;
                let aCount = 0;
                if (window.screen.width >= 1520) {
                    var totalItem = 8;
                    var totalProduct = 6;
                }else{
                    var totalItem = 4;
                    var totalProduct = 4;
                }
                if(typeof results.recommendations.articles === 'undefined'){
                    totalProduct +=2;
                }else{
                    totalProduct += (2- Math.min(2, Object.keys(results.recommendations.articles).length));
                }
                
                if(this._recommendations){
                    this._recommendationsTitle.innerText = 'Recommendations';
                    if(((typeof results.recommendations.products !== 'undefined' ? Object.keys(results.recommendations.products).length : 0) + (typeof results.recommendations.articles !== 'undefined' ? Object.keys(results.recommendations.articles).length : 0)) <=4){
                        //this._searchRight.style.maxWidth = '400px';
                        //this._searchResultParent.style.width = '730px';
                    }
                    for (const key in results.recommendations.products) {
                        if (results.recommendations.products.hasOwnProperty(key)) {
                            const result = results.recommendations.products[key] as product;
                            this.recommendationsProcuts(result);
                            pCount++;
                            if(pCount >= totalProduct){break;}
                        }
                    }
                    if(pCount < totalItem){
                        for (const key in results.recommendations.articles) {
                            if (results.recommendations.articles.hasOwnProperty(key)) {
                                const result = results.recommendations.articles[key] as article;
                                this.recommendationsArticles(result);
                                aCount++;
                                if((aCount + pCount) >= totalItem){break;}
                            }
                        }
                    }
                }
            }else if(!results.recommendations || (!results.recommendations.products && !results.recommendations.articles) ){
                if(this._searchRight){
                    this._recommendationsTitle.innerText = 'No Recommendations';
                    //this._searchRight.style.maxWidth = '400px';
                    //this._searchResultParent.style.width = '730px';
                }

                if(results.suggestions.length <1){
                    //this._searchResultParent.style.display = 'none';
                    this._searchBottom.style.display = '';
                    this._searchBottom.hidden = false;
                    this._searchLeft.hidden = true;
                    if(this._searchRight){
                        this._searchRight.hidden = true;
                        this._searchRight.style.display = 'none';
                    }
                    this._searchResultParent.hidden = false;
                }
            }
        }
    }
    private suggestionsLoading = (suggestions: string) => {
        //console.info('loading in progress', suggestions);
    }

    private selectSuggestion = (selection: HTMLLIElement) => {
        // mark the new item aria-selected attribute
        selection.setAttribute('aria-selected', true.toString());
        // reflect the change on the search box
        this._searchBox.setAttribute('aria-activedescendant', selection.id);
        this._searchBox.value = (selection.getAttribute('data-text') ? selection.getAttribute('data-text') : selection.innerText);
    }
    private unSelectSuggestion = (selection: HTMLLIElement) => {
        selection.setAttribute('aria-selected', false.toString());
        if (this._searchBox.getAttribute('aria-activedescendant') === selection.id) {
            this._searchBox.setAttribute('aria-activedescendant', '');
        }
    }

    // check if result is hidden
    private checkIfHidden = (event:Event) => {
        if(this._searchResultParent.hidden == true){
            if(this._searchBox.value.length > 0 && !this._isRequesting){
                this.suggestSearchClick(event);
            }
        }
    }

    //#region events
    private submitSearch = (event: Event) => {
        event.preventDefault();
        if (this._searchBox.validity.valid) {
            // this._searchBox.setAttribute('aria-invalid', false.toString());
            // this._searchBox.classList.remove('invalid');

            const newSubmit = () => {
                window.location.href = `/search-results.php?q=${encodeURIComponent(this._searchBox.value.toLowerCase())}`
            }

            // submit header analytic and callback the form submit
            AnalyticInteractions.tealium_searchSubmit(this._searchBox.value.toLowerCase(), GA_eventActions.click, GA_eventCategories.Header, "Search: " + this._searchBox.value.toLowerCase(), GA_EventValues.SearchSubmit.toString(), ()=>{newSubmit();});

        } else {
            // this._searchBox.setAttribute('aria-invalid', true.toString());
            // this._searchBox.classList.add('invalid');

            // notify the user
            this.reportValidity();
        }
    }

    private refreshSearch = (event: Event) => {
        event.preventDefault();
        if (this._searchBox.validity.valid) {
            this._searchBox.setAttribute('aria-invalid', false.toString());
            this._searchBox.classList.remove('invalid');

            // submit header analytic and callback the form submit
            AnalyticInteractions.tealium_searchSubmit(this._searchBox.value, GA_eventActions.click, GA_eventCategories.Header, "Search: " + this._searchBox.value, GA_EventValues.SearchSubmit.toString(), ()=>{});

        } else {
            this._searchBox.setAttribute('aria-invalid', true.toString());
            this._searchBox.classList.add('invalid');

            // notify the user
            this.reportValidity();
        }
    }
    private suggestInteractions = (event: KeyboardEvent) => {
        let keyCode: string = event.code,
            curSelection: HTMLLIElement = this.selectedSuggestion;
        if (keyCode === specialKeys.ArrowDown) {
            event.preventDefault();
            if (curSelection) {
                this.unSelectSuggestion(curSelection);
            }
            this.selectSuggestion(curSelection && curSelection.nextElementSibling ? curSelection.nextElementSibling as HTMLLIElement : this._searchResultContainer.children[0] as HTMLLIElement);
        }
        if (keyCode === specialKeys.ArrowUp) {
            event.preventDefault();
            if (curSelection) {
                this.unSelectSuggestion(curSelection);
            }
            this.selectSuggestion(curSelection && curSelection.previousElementSibling ? curSelection.previousElementSibling as HTMLLIElement : this._searchResultContainer.lastChild as HTMLLIElement);
        }
        if (keyCode === specialKeys.Tab) {
            this.hideSuggestions();
            return;
        }
        if (keyCode === specialKeys.Enter) {
            event.preventDefault();
            this.hideSuggestions();
            //this._searchForm.submit();
            this.submitSearch(event);
            return;
        }
        if (keyCode === specialKeys.Escape) {
            // reset the value back to the original input
            this.hideSuggestions();
            this._searchBox.value = this._runningVal;
        }
    }

    private refreshSuggest = () => {
        if (this._searchBox.value.length > 0 && !this._isRequesting) {

            this._runningVal = this._searchBox.value;
            this._isRequesting = true;

            // show our form
            this.showSuggestions();
            this._searchLeft.hidden = true;
            if(this._searchRight){
                this._searchRight.hidden = true;
                this._searchRight.style.display = 'none';
            }
            this._searchBottom.hidden = true;
            const RegExp1 = new RegExp('\\*', 'g');
            const RegExp2 = new RegExp('\\:', 'g');
            const RegExp3 = new RegExp('\\^', 'g');
            this._searchStr = this._searchBox.value.trim().replace(RegExp1,'').replace(RegExp2,'').replace(RegExp3,'');
            this._searchBottomText.innerText = `"${this._searchStr}"`;
            ajaxHelpers.get(
                '/sayt.php?q=' + this._searchBox.value.toLowerCase(),
                this.suggestionsReceived,
                this.suggestionsLoading
            );
        } else if (!this._searchBox.value) {
            this.clearSuggestions();
            this.hideSuggestions();
        }
    }
    private suggestSearch = (event: KeyboardEvent) => {
        let keyCode: string = event.code;
        // handle non-alphanumeric key presses
        
        if (keyCode in specialKeys) {
            return;
            // otherwise perform a suggestion as normal
        } else if (this._searchBox.value.length > 0 && !this._isRequesting) {

            this._runningVal = this._searchBox.value;
            this._isRequesting = true;

            // show our form
            this.showSuggestions();
            this._searchLeft.hidden = true;
            if(this._searchRight){
                this._searchRight.hidden = true;
                this._searchRight.style.display = 'none';
            }
            this._searchBottom.hidden = true;
            const RegExp1 = new RegExp('\\*', 'g');
            const RegExp2 = new RegExp('\\:', 'g');
            const RegExp3 = new RegExp('\\^', 'g');
            this._searchStr = this._searchBox.value.trim().replace(RegExp1,'').replace(RegExp2,'').replace(RegExp3,'');
            this._searchBottomText.innerText = `"${this._searchStr}"`;
            ajaxHelpers.get(
                '/sayt.php?q=' + this._searchBox.value.toLowerCase(),
                this.suggestionsReceived,
                this.suggestionsLoading
            );
        } else if (!this._searchBox.value) {
            this.clearSuggestions();
            this.hideSuggestions();
        }
    }

    private suggestSearchClick = (event: Event) => {
        if (this._searchBox.value.length > 0 && !this._isRequesting) {

            this._runningVal = this._searchBox.value;
            this._isRequesting = true;

            // show our form
            this.showSuggestions();
            this._searchLeft.hidden = true;
            if(this._searchRight){
                this._searchRight.hidden = true;
                this._searchRight.style.display = 'none';
            }
            this._searchBottom.hidden = true;
            const RegExp1 = new RegExp('\\*', 'g');
            const RegExp2 = new RegExp('\\:', 'g');
            const RegExp3 = new RegExp('\\^', 'g');
            this._searchStr = this._searchBox.value.trim().replace(RegExp1,'').replace(RegExp2,'').replace(RegExp3,'');
            this._searchBottomText.innerText = `"${this._searchStr}"`;
            ajaxHelpers.get(
                '/sayt.php?q=' + this._searchBox.value.toLowerCase(),
                this.suggestionsReceived,
                this.suggestionsLoading
            );
        } else if (!this._searchBox.value) {
            this.clearSuggestions();
            this.hideSuggestions();
        }
    }
    //#endregion
}

export class mobileSearchModule extends searchModule {
    private _toggleButton: HTMLButtonElement;
    private _cancelButton: HTMLButtonElement;
    private _containerElement: HTMLElement;
    private _mobileSearchState: openState = openState.closed;
    private _dimmer: dimmer = new dimmer();
    private _menuCloseFunction: Function;

    constructor(toggleButton: HTMLButtonElement, container: HTMLElement, menuCloseFunction?: Function) {
        super(container.querySelector('form'), container.querySelector('form input'), container.querySelector('form button'), container.querySelector('.searchResults'), container.querySelector('.searchResultsContainer'));

        this._toggleButton = toggleButton;
        this._containerElement = container;

        this._toggleButton.addEventListener('click', this.slideHide);

        if (menuCloseFunction) {
            this._menuCloseFunction = menuCloseFunction;
        }

        this._cancelButton = document.querySelector('#'+container.id+' > button'); // the container only has two direct siblings, the form and cancel button

        if (this._cancelButton) {
            this._cancelButton.addEventListener('click', (ev: MouseEvent) => {
                ev.preventDefault();
                this._searchBox.value = "";
                this.slideHide(ev);
            });
        }
    }

    public closeIfOpen() {
        if (this._mobileSearchState == openState.opened) {
            this.slideHide(new CustomEvent("NavOpen"));
            return true;
        }
        return false;
    }

    get isOpen():boolean{
        return this._mobileSearchState === openState.opened;
    }

    get dimmerInstance():dimmer{
        return this._dimmer;
    }

    private slideHide = (event?: MouseEvent|CustomEvent) => {
        const speed: number = .2;
       
        switch (this._mobileSearchState) {
            case openState.opened:
                if (!(this._searchResultContainer.hidden)) {
                    this.hideSuggestions();
                }
                // animate up the search menu
                gsap.to(this._containerElement, { duration:speed, y: 0 });
                // remove active class from search button
                this._toggleButton.classList.remove('active');

                this._dimmer.remove(false);
                
                // focus back to the search button
                // save state
                this._mobileSearchState = openState.closed;
                break;

            case openState.closed:
                // close the menu if open
                if (this._menuCloseFunction) {
                    this._menuCloseFunction(new CustomEvent("SearchOpen"));
                }

                // add active class to search button
                this._toggleButton.classList.add('active');
                // animate down the search menu
                this._dimmer.add();
                //document.querySelector('.dimmer').addEventListener('click', this.slideHide);
                gsap.to(this._containerElement, { duration:speed, y: this._containerElement.offsetHeight - 1 });

                // bring focus to the input box
                // save state
                this._mobileSearchState = openState.opened;                

                AnalyticInteractions.headerEvent(GA_eventActions.click, "Show Search", GA_EventValues.MobileSearchOpen, this._toggleButton);

                break;
        }
    }
}