import { gsap } from "gsap";
import { headerModule } from '../header/headerModule';

export enum specialEvents {
    "SearchOpen" = "SearchOpen",
    "NavOpen" = "NavOpen",
}

export class dimmer {
    private readonly _cssClass: string = 'dimmer';
    private readonly _animSpeed: number;
    private _dimmerElement: HTMLElement;
    private _dimClone: Node;
    private _insertBeforeElement: HTMLElement;
    private _isClosing: boolean;
    private _holdY:number;

    constructor(animationSpeed: number = .2) {
        this._dimmerElement = document.createElement('div');
        this._dimmerElement.className = this._cssClass;
        this._animSpeed = animationSpeed;
        this._insertBeforeElement = document.querySelector('main');
        this._isClosing = false;
    }

    get dimmerInstance(): HTMLElement {
        return this._dimmerElement;
    }

    dimmerExists(): boolean {
        if (this._dimClone) {
            // clone exists, see if it's attached to the DOM
            return this._dimClone.parentElement ? true : false;
        } else {
            // clone does not exist
            return false;
        }
    }

    holdPosition = (e: TouchEvent | Event) => {
        const curTar = e.target as HTMLElement,
            mobileNav = document.getElementById('headerNavigation');            

        
        if (!mobileNav.contains(curTar)) { e.preventDefault(); }
        window.scrollTo(0,this._holdY);
    }

    disableScroll() {
        this._holdY = window.pageYOffset;
        document.querySelector('body').style.touchAction = 'none';
        window.addEventListener('scroll', this.holdPosition, { passive: false });
        window.addEventListener('touchmove', this.holdPosition, { passive: false });
    }

    enableScroll() {
        document.querySelector('body').style.touchAction = '';
        window.removeEventListener('scroll', this.holdPosition, { passive: false } as EventListenerOptions);
        window.removeEventListener('touchmove', this.holdPosition, { passive: false } as EventListenerOptions);

        // do this to recalculate the positions
        headerModule.initSticky();
    }

    /**
     * Add the dimmer before a specific HTML element on the page
     *
     * @param {HTMLElement} element
     * @memberof dimmer
     */
    insertBefore(element: HTMLElement): void {
        // set this to prevent the dimmer from being removed from the DOM
        // if it is in the process of fading out when this is called
        this._isClosing = false;

        if (element != this._insertBeforeElement) {
            this._insertBeforeElement = element;
        }
        // dimmer may already exist if we interrupted it as it was fading out
        if (!this.dimmerExists()) {
            // insert it between main and header & save the object reference
            this._dimClone = document.body.insertBefore(this._dimmerElement.cloneNode(), element);
        }
        // whether we just created or found it already, make sure we can see it
        // fade it in and we are done here   
        gsap.to(this._dimClone, { 
            duration:this._animSpeed, 
            opacity: 1, 
            immediateRender: true, 
            ease: "none",
            //useFrames: true 
        });
    }
    /**
     * Add the dimmer to the DOM or re-animate it if fading out,
     * using default setting
     *
     * @memberof dimmer
     */
    add(): void {
        this._isClosing = false;
        //this.disableScroll();
        this.insertBefore(this._insertBeforeElement);
    }
    /**
     * Fade the dimmer and remove it from the DOM
     *
     * @param {boolean} [isClosing=true] - send us a boolean if currently closing
     * @memberof dimmer
     */
    remove(keepScrollDisabled: boolean = false): void {
        if (this.dimmerExists()) {
            // it exists, so make it no longer exist
            // fade it out and we are done here
            gsap.to(this._dimClone, {
                duration:  this._animSpeed,
                opacity: 0,
                immediateRender: true,  
                ease: "none",
                //useFrames: true,
                onStart: () => {
                    this._isClosing = true;
                },
                onComplete: () => {
                    if (this._isClosing) {
                        // remove this div only if we didn't interrupt the closing
                        this._dimClone.parentNode.removeChild(this._dimClone);
                        this._isClosing = false;
                        if (!keepScrollDisabled) {
                            //this.enableScroll();
                        }
                    } else {
                        // we interrupted, restore the dimmer
                        this.add();
                    }
                }
            });
        }
    }
}