import { domHelpers as DH, PEDButtonType, eventHelpers, domHelpers } from '../helpers/helpers';
import { gsap } from 'gsap';
import { mobileNavHeader } from '../navigation/mobileNavModules';
import { AnalyticInteractions, GA_eventActions, GA_EventValues } from '../helpers/AnalyticHelper';

enum formIDs {
    desktop = 'desktopLoginForm',
    mobile = 'mobileLoginForm',
    desktop2 = 'desktopLoginForm2',
    mobile2 = 'mobileLoginForm2'
};

/**
 * Class to handle all things related to login form
 *
 * @export
 * @class log in form
 */
export class logInModule {
    protected _formElement: HTMLFormElement;
    protected _formLoggedIn: HTMLFormElement;
    protected _formTitle: string;
    protected _formDescription: string;
    protected _signInButton: HTMLButtonElement;
    protected _createAccountButton: HTMLElement;
    protected _forgotPasswordButton: HTMLElement;
    protected _emailTextBox: HTMLInputElement;
    protected _passwordTextBox: HTMLInputElement;
    protected _emailTextLabel: HTMLElement;
    protected _passwordTextLabel: HTMLElement;
    protected _validatorFirstLabel: HTMLElement;
    protected _validatorLastLabel: HTMLElement;
    protected _closeButton: HTMLButtonElement;
    protected _closeButton2:HTMLButtonElement;
    protected _bottomContainer: HTMLElement;
    protected _bottomContainer2: HTMLElement;
    protected _trackOrder: HTMLElement;
    protected _accountDashboard:HTMLElement;
    protected _orderHistory:HTMLElement;
    protected _emailPreferences: HTMLElement;
    protected _emailPreferences2:HTMLElement;
    protected _helpCenter: HTMLElement;
    protected _helpCenter2:HTMLElement;
    protected _canToggle: boolean = true;
    protected _toggleButton: HTMLButtonElement;
    protected _userFirstName: HTMLElement;
    protected _userFirstName2: HTMLElement;
    protected _signOut:HTMLButtonElement;
    protected _message:HTMLElement;
    protected _timerID: number;
    protected _onSubmitCallback: Function;
    protected _callBackmessage: string;
    protected _emailValiIcon:HTMLElement;
    protected _passwordValiIcon:HTMLElement;
    protected _showPassword:HTMLButtonElement;
    protected _invalidImg:HTMLImageElement;
    protected _validImg:HTMLImageElement;
    protected _invalidImg2:HTMLImageElement;
    protected _validImg2:HTMLImageElement;
    protected _loginToken:HTMLInputElement;
    protected _body:HTMLElement;


    /**
     *Creates an instance of logInModule.
     * @param {HTMLButtonElement} toggleButton
     * @param {Function} [onSubmit]
     * @param {string} [formTitle="Welcome"]
     * @param {string} [formDescription=""]
     * @memberof logInModule
     */
    constructor(
        toggleButton: HTMLButtonElement,
        loginTokenVal?: string,
        onSubmit?: Function,
        formTitle: string = "Welcome",
        formDescription: string = "",
    ) {
        // set our properties
        this._formTitle = formTitle;
        this._formDescription = formDescription;
        this._callBackmessage = "";

        // if we passed an additional callback for the onsubmit action, set it here
        if (onSubmit) {
            this._onSubmitCallback = onSubmit;
        }

        // set the toggle button and events
        if(toggleButton){
        this._toggleButton = toggleButton;
        this._toggleButton.onclick = this.toggleLogInDialog;
        this._toggleButton.addEventListener('keyup', this.keyAttemptToggleDialog);
        }
        // generate the form so it's ready when we need it
        this.scaffoldForm(loginTokenVal);
        document.body.setAttribute('data-enable-analytics', 'true');
    }

    //#region getters/setters
    /**
     * Return an instance of our form element
     *
     * @readonly
     * @type {HTMLFormElement}
     * @memberof logInForm
     */
    get formElement(): HTMLFormElement {
        return this._formElement;
    }

    get formLoggedIn(): HTMLFormElement {
        return this._formLoggedIn;
    }

    /**
     * Create & assemble our form elements
     *
     * @protected
     * @memberof logInForm
     */
    protected scaffoldForm(loginTokenVal:string) {
        // create & assemble our form elements
        const fieldSet = DH.createElement('fieldset') as HTMLFieldSetElement;
            //description = fieldSet.appendChild(DH.createElement('p', this._formDescription)),
        this._message = fieldSet.appendChild(domHelpers.createElement('div') as HTMLElement);
        const formLabelID = 'logInLabel', descriptionID = 'logInDesc';
        const emailInputContainer = fieldSet.appendChild(domHelpers.createElement('div') as HTMLElement);
        const emailInputGroup = emailInputContainer.appendChild(domHelpers.createElement('div') as HTMLElement);
        this._validatorFirstLabel = emailInputContainer.appendChild(DH.createElement('div'));
        const passwordInputContainer = fieldSet.appendChild(domHelpers.createElement('div') as HTMLElement);
        const passwordInputGroup = passwordInputContainer.appendChild(domHelpers.createElement('div') as HTMLElement);
        this._validatorLastLabel = passwordInputContainer.appendChild(DH.createElement('div'));
        
        emailInputContainer.classList.add('inputContainer');
        passwordInputContainer.classList.add('inputContainer');
        this._userFirstName = fieldSet.appendChild(DH.createElement('legend', this._formTitle));
        this._emailTextBox = emailInputGroup.appendChild(DH.createTextInput());
        this._passwordTextBox = passwordInputGroup.appendChild(DH.createTextInput());
        this._emailTextLabel = emailInputGroup.appendChild(DH.createElement('label'));
        this._emailValiIcon = emailInputGroup.appendChild(DH.createElement('span'));
        this._passwordTextLabel = passwordInputGroup.appendChild(DH.createElement('label'));
        this._showPassword = passwordInputGroup.appendChild(DH.createButton(PEDButtonType.standard));
        this._passwordValiIcon = passwordInputGroup.appendChild(DH.createElement('span'));
        this._loginToken = fieldSet.appendChild(DH.createTextInput());
        
        this._forgotPasswordButton = fieldSet.appendChild(DH.createElement('a'));
        this._signInButton = fieldSet.appendChild(DH.createButton(PEDButtonType.standard));
        this._createAccountButton = fieldSet.appendChild(DH.createElement('a'));
        this._formElement = DH.createElement('form', fieldSet) as HTMLFormElement;
        this._bottomContainer = this._formElement.appendChild(DH.createElement('div'));
        this._trackOrder = this._bottomContainer.appendChild(DH.createElement('a'));
        this._emailPreferences = this._bottomContainer.appendChild(DH.createElement('a'));
        this._helpCenter = this._bottomContainer.appendChild(DH.createElement('a'));
        this._closeButton = this._formElement.appendChild(DH.createButton(PEDButtonType.none));

        // create another form for logged in
        const fieldSet2 = DH.createElement('fieldset') as HTMLFieldSetElement;
        this._userFirstName2 = fieldSet2.appendChild(DH.createElement('legend', this._formTitle));
        this._formLoggedIn = DH.createElement('form', fieldSet2) as HTMLFormElement;
        this._bottomContainer2 = this._formLoggedIn.appendChild(DH.createElement('div'));
        this._accountDashboard = this._bottomContainer2.appendChild(DH.createElement('a'));
        this._orderHistory = this._bottomContainer2.appendChild(DH.createElement('a'));
        this._emailPreferences2 = this._bottomContainer2.appendChild(DH.createElement('a'));
        this._helpCenter2 = this._bottomContainer2.appendChild(DH.createElement('a'));
        this._signOut = this._bottomContainer2.appendChild(DH.createButton(PEDButtonType.standard));
        this._closeButton2 = this._formLoggedIn.appendChild(DH.createButton(PEDButtonType.none));


        // form without login in
        // set some properties
        this._formElement.setAttribute('role', 'dialog');
        this._formElement.setAttribute('aria-labelledby', formLabelID);
        this._formElement.setAttribute('aria-hidden', true.toString());
        this._formElement.setAttribute('action', '/login.php?action=process');
        this._formElement.setAttribute('method', 'post');
        this._formElement.setAttribute('autocomplete', 'off');
        this._formElement.id = formIDs.desktop;
        this._formElement.noValidate = true;

        // form loggedin in
        // set some properties
        this._formLoggedIn.setAttribute('role', 'dialog');
        this._formLoggedIn.setAttribute('aria-labelledby', formLabelID);
        this._formLoggedIn.setAttribute('aria-hidden', true.toString());
        this._formLoggedIn.id = formIDs.desktop2;
        this._formLoggedIn.noValidate = true;

        eventHelpers.bindEvent(this._formElement, ['submit'], this.submitLogIn);
        DH.trapFocus(this._formElement);
        DH.trapFocus(this._formLoggedIn);

        this._userFirstName.id = formLabelID;
        this._userFirstName2.id = formLabelID;

        //description.id = descriptionID;

        emailInputGroup.classList.add('inputGroupNew');
        emailInputGroup.classList.add('logInInputGroup');
        emailInputGroup.id = 'emailInputContainer';
        passwordInputGroup.classList.add('inputGroupNew');
        passwordInputGroup.classList.add('logInInputGroup');
        passwordInputGroup.id = 'passwordInputContainer';

        this._emailTextBox.id = "txtemail";
        this._emailTextBox.placeholder = "Email Address";
        this._emailTextBox.required = true;
        this._emailTextBox.classList.add("loginInput");
        this._emailTextBox.setAttribute('aria-label', 'Email Address');
        this._emailTextBox.setAttribute('aria-describedBy', descriptionID);
        this._emailTextBox.setAttribute('aria-required', true.toString());
        this._emailTextBox.setAttribute('name', 'email_address');
        this._emailTextBox.setAttribute('size', '22');
        this._emailTextBox.setAttribute('autocomplete', 'false');
        //this._emailTextBox.value = escape(this._currentZipCode);
        this._emailTextBox.pattern = `[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$`;
        this._emailTextBox.type = "email";
        this._emailTextBox.title = "Please Enter a Valid Email Address";

        this._loginToken.type = "hidden";
        this._loginToken.setAttribute('name', 'login_token');
        this._loginToken.setAttribute('value', loginTokenVal);
        this._loginToken.id = "login_token";

        //this._validatorFirstLabel.className = "inputWarning";
        this._validatorFirstLabel.appendChild(domHelpers.createIcon('errorMessage', true));
        this._validatorFirstLabel.id = "emailError";
        //this._validatorLastLabel.className = "inputWarning";
        this._validatorLastLabel.appendChild(domHelpers.createIcon('errorMessage', true));
        this._validatorLastLabel.id = "passwordError";

        this._passwordTextBox.id = "txtpassword";
        this._passwordTextBox.placeholder = "Password";
        this._passwordTextBox.required = true;
        this._passwordTextBox.setAttribute('aria-label', 'Password');
        this._passwordTextBox.setAttribute('aria-describedBy', descriptionID);
        this._passwordTextBox.setAttribute('aria-required', true.toString());
        this._passwordTextBox.setAttribute('name', 'password');
        this._passwordTextBox.setAttribute('size', '22');
        this._passwordTextBox.setAttribute('autocomplete', 'new-password');
        this._passwordTextBox.classList.add("loginInput");
        //this._emailTextBox.value = escape(this._currentZipCode);
        //this._passwordTextBox.pattern = `[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$`;
        this._passwordTextBox.type = "password";
        this._passwordTextBox.title = "Please Enter Password";
        this._emailTextBox.addEventListener('blur',this.checkEmailValiEvent);
        this._passwordTextBox.addEventListener('blur',this.checkPasswordValiEvent);

        this._emailTextLabel.setAttribute('for','txtemail');
        this._passwordTextLabel.setAttribute('for','txtpassword');
        this._emailTextLabel.innerHTML = 'Email Address';
        this._passwordTextLabel.innerHTML = 'Password';

        this._showPassword.innerHTML = 'Show';
        this._showPassword.classList.add('showPasswordBtn');
        this._showPassword.setAttribute('type','button');
        this._showPassword.addEventListener('mousedown',this.showPasswordEvent);

        this._emailValiIcon.classList.add('emailValiIcon');
        this._passwordValiIcon.classList.add('passwordValiIcon');
        this._invalidImg = DH.createElement('img') as HTMLImageElement;
        this._validImg = DH.createElement('img') as HTMLImageElement;
        this._invalidImg.setAttribute('src','https://www.powerequipmentdirect.com/images/icons/icon-field-validation-error.svg');
        this._invalidImg.setAttribute('width','20px');
        this._validImg.setAttribute('src','https://www.powerequipmentdirect.com/images/icons/icon-field-validation-success.svg');
        this._validImg.setAttribute('width','20px');
        this._invalidImg2 = DH.createElement('img') as HTMLImageElement;
        this._validImg2 = DH.createElement('img') as HTMLImageElement;
        this._invalidImg2.setAttribute('src','https://www.powerequipmentdirect.com/images/icons/icon-field-validation-error.svg');
        this._invalidImg2.setAttribute('width','20px');
        this._validImg2.setAttribute('src','https://www.powerequipmentdirect.com/images/icons/icon-field-validation-success.svg');
        this._validImg2.setAttribute('width','20px');

        this._forgotPasswordButton.className = "logInForgotPassword";

        this._signInButton.classList.add("btnLogInDialog");
        this._signInButton.type = "submit";
        //this._signInButton.classList.add("bordered");
        this._signInButton.innerHTML = "Sign In";

        this._createAccountButton.classList.add("btnLogInDialog");
        this._createAccountButton.classList.add("PED_button");
        this._createAccountButton.classList.add("special");
        this._createAccountButton.classList.add("special_a");
        this._createAccountButton.innerHTML = "Create Account";
        this._createAccountButton.onclick = this.createAccountEvent;
        this._createAccountButton.style.cursor = 'pointer';
        this._createAccountButton.setAttribute('href','/login.php?action=account_creation');
        this._forgotPasswordButton.innerHTML = "Forgot password?";
        this._forgotPasswordButton.onclick = this.forgotPasswordEvent;
        this._forgotPasswordButton.style.cursor = 'pointer';
        this._forgotPasswordButton.setAttribute('href','/password_forgotten.php');
        this._closeButton.className = "locationClose";
        this._closeButton.setAttribute('aria-label', "Close Sign In Dialog");
        this._closeButton.innerHTML = "<img src=\"https://www.powerequipmentdirect.com/images/icons/icon-popup-close.svg\" width=\"15px\" height=\"15px\">";
        this._closeButton.onclick = this.toggleLogInDialog;
        this._trackOrder.className = "logInATags";
        this._trackOrder.setAttribute('aria-label', "Track Order In Dialog");
        this._trackOrder.setAttribute('href', "/ordertracking.php");
        this._trackOrder.innerHTML = "Track Order";
        this._trackOrder.onclick = this.eventTracker;
        this._emailPreferences.className = "logInATags";
        this._emailPreferences.setAttribute('aria-label', "Email Preferences In Dialog");
        this._emailPreferences.setAttribute('href', "/newsletter_removal.php");
        this._emailPreferences.innerHTML = "Email Preferences";
        this._emailPreferences.onclick = this.eventTracker;
        this._helpCenter.className = "logInATags";
        this._helpCenter.setAttribute('aria-label', "Help Center In Dialog");
        this._helpCenter.innerHTML = "Help Center";
        this._helpCenter.setAttribute('href', "/contact_us.php");
        this._helpCenter.onclick = this.eventTracker;
        this._bottomContainer.className = "logInDialogBottom";
        this._message.className = "messageLabel";

        // elements for logged in
        this._closeButton2.className = "locationClose";
        this._closeButton2.setAttribute('aria-label', "Close Sign In Dialog");
        this._closeButton2.innerHTML ="<img src=\"https://www.powerequipmentdirect.com/images/icons/icon-popup-close.svg\" width=\"15px\" height=\"15px\">";
        this._closeButton2.onclick = this.toggleLogInDialog;
        this._accountDashboard.className = "logInATags";
        this._accountDashboard.setAttribute('aria-label', "Account Dashboard In Dialog");
        this._accountDashboard.setAttribute('href', "/account.php");
        this._accountDashboard.innerHTML = "Account Dashboard";
        this._accountDashboard.onclick = this.eventTracker;
        this._orderHistory.className = "logInATags";
        this._orderHistory.setAttribute('aria-label', "Order History In Dialog");
        this._orderHistory.setAttribute('href', "/account_history.php");
        this._orderHistory.innerHTML = "Order History";
        this._orderHistory.onclick = this.eventTracker;
        this._emailPreferences2.className = "logInATags";
        this._emailPreferences2.setAttribute('aria-label', "Email Preferences In Dialog");
        this._emailPreferences2.setAttribute('href', "/email-preferences.php");
        this._emailPreferences2.innerHTML = "Email Preferences";
        this._emailPreferences2.onclick = this.eventTracker;
        this._helpCenter2.className = "logInATags";
        this._helpCenter2.setAttribute('aria-label', "Help Center In Dialog");
        this._helpCenter2.setAttribute('href', "/contact_us.php");
        this._helpCenter2.innerHTML = "Help Center";
        this._helpCenter2.onclick = this.eventTracker;
        this._signOut.className = "logInATags";
        this._signOut.setAttribute('aria-label', "Sign Out In Dialog");
        this._signOut.setAttribute('href', "/logoff.php");
        this._signOut.innerHTML = "Sign Out";
        this._signOut.className = "sigOutBtn";
        this._signOut.setAttribute('type','button');
        this._bottomContainer2.className = "logInDialogBottom2";

        this._signOut.onclick = this.signOutUser;
    }

    protected reportValidity(): void {
        // first initialize the validity check
        this._formElement.checkValidity();

        // workaround for browsers that do not support form.reportValidity
        if ('reportValidity' in this._formElement) {
            // this will display the validation message when following checkValidity
            this._formElement.reportValidity();
        } else {
            if (this._emailTextBox.validationMessage) {
                // fallback to some other way of notifying the user
                alert(this._emailTextBox.validationMessage);
            }
        }
    }

    private positionChangeLogInPopup = () => {
        // Maths based on the size of the button, the form, and the container (for dialog positioning)
        var dialogBtnCenter = this._toggleButton.offsetWidth / 2, // get the middle point of the button
            dialogBtnLeft = this._toggleButton.getBoundingClientRect().left,
            screenWidth = window.innerWidth,
            divHeight = this._toggleButton.parentElement.offsetHeight; // find how tall the container is

        var formWidth = (screenWidth - dialogBtnLeft -dialogBtnCenter*1.45)*2;
        this._formElement.style.width = formWidth +'px';

        var formCenter = this._formElement.offsetWidth / 2; // get the middle point of the form

        this._formElement.style.left = -Math.abs(formCenter - dialogBtnCenter +45) + 'px'; // maths to center form dialog with change location button
        this._formElement.style.top = (divHeight + 15) + 'px'; // maths to position the dialog below the change location button
    }

    private appendToDesktop() {
        this._toggleButton.parentElement.appendChild(this._formElement);

        // below line may need removed if we ever rename acw's login-new to login
        const site = window.location.hostname.includes('acwholesalers.com') ? 'login-new' : 'login';

        const ajaxURL = `/${site}.php?action=new_token`;
        fetch(ajaxURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            })
            .then((resp) => {
                if (!resp.ok) {
                    // this should get caught by our fetch/fail
                    throw Error(resp.statusText);
                }
                return resp;
            })
            .then((resp) => resp.json())
            .then((resJSON) => {
                const status = resJSON['new_token'];
                const newtoken = resJSON['token'];
                if(status == 'success'){
                    this._loginToken.setAttribute('value', newtoken);
                }
            });
        this.positionChangeLogInPopup();
    }

    private removeFromDesktop() {
        if (this._formElement.parentElement) {
            this._formElement.parentElement.removeChild(this._formElement);
        }
    }

    protected eventTracker = (e: Event) => {
        var obj = e.target as HTMLElement;
        AnalyticInteractions.headerEvent(GA_eventActions.click, "Login Module "+obj.innerHTML, 0, event.target as HTMLButtonElement);
    }

    protected forgotPasswordEvent = (e: Event) => {
        event.preventDefault();
        localStorage.setItem('lastPage', window.location.href);
        AnalyticInteractions.headerEvent(GA_eventActions.click, "Login Module Forgot Password", 0, event.target as HTMLButtonElement);
        window.location.href = '/password_forgotten.php';
    }

    protected createAccountEvent = (e: Event) => {
        event.preventDefault();
        localStorage.setItem('lastPage', window.location.href);
        AnalyticInteractions.headerEvent(GA_eventActions.click, "Login Module Create Account", 0, event.target as HTMLButtonElement);
        window.location.href = (<HTMLAnchorElement>e.target).href;
    }

    private positionChangeLogInPopup2 = () => {
        // Maths based on the size of the button, the form, and the container (for dialog positioning)
        var dialogBtnCenter = this._toggleButton.offsetWidth / 2, // get the middle point of the button
            dialogBtnLeft = this._toggleButton.getBoundingClientRect().left,
            screenWidth = window.innerWidth,
            divHeight = this._toggleButton.parentElement.offsetHeight; // find how tall the container is

        var formWidth = (screenWidth - dialogBtnLeft -dialogBtnCenter*1.45)*2;
        this._formLoggedIn.style.width = formWidth +'px';

        var formCenter = this._formElement.offsetWidth / 2; // get the middle point of the form
        this._formLoggedIn.style.left = -Math.abs(formCenter - dialogBtnCenter -100) + 'px'; // maths to center form dialog with change location button
        this._formLoggedIn.style.top = (divHeight + 10) + 'px'; // maths to position the dialog below the change location button
        
    }

    private appendToDesktop2() {
        this._toggleButton.parentElement.appendChild(this._formLoggedIn);

        this.positionChangeLogInPopup2();
    }

    private removeFromDesktop2() {
        if (this._formLoggedIn.parentElement) {
            this._formLoggedIn.parentElement.removeChild(this.formLoggedIn);
        }
    }

    protected showPasswordEvent = (e: Event) => {
        e.preventDefault();
        const field = <HTMLInputElement>document.getElementById("txtpassword");
        if (field.type === "password") {
            field.type = "text";
            this._showPassword.innerHTML = 'Hide';
        } else {
            field.type = "password";
            this._showPassword.innerHTML = 'Show';
        }
        AnalyticInteractions.headerEvent(GA_eventActions.click, "Login Module Show Password", 0, event.target as HTMLButtonElement);
    }

    protected emailIsValid (email:string) {
        return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/.test(email)
    }

    protected checkPasswordValiEvent = (e:Event) => {
        var value = (<HTMLInputElement>document.getElementById("txtpassword")).value;
        if(value.length <1){
            this.passwordRemoveInvalidFunction();
            this.passwordInvalidFunction('Your password is empty.');
            return false;
        }else{
            this.passwordRemoveInvalidFunction();
            //this.passwordValidFunction();
            return true
        }
    }

    protected checkEmailLength = (e:Event) =>{
        var value = (<HTMLInputElement>document.getElementById("txtemail")).value;
        if(value.length < 6){
            this.emailRemoveInvalidFunction();
            this.emailInvalidFunction('Your email address must be at least 6 letters.');
            return false;
        }else{
            return true;
        }
    }
    protected checkEmailValiEvent = (e:Event) => {
        var value = (<HTMLInputElement>document.getElementById("txtemail")).value;
        if(value.length < 1){
            //this.emailRemoveInvalidFunction();
            //this.emailInvalidFunction('Your email address must be at least 6 letters.');
            //return false;
        }else if(!this.emailIsValid(value)){
            if(value.includes('@')){
                if(value.charAt(0) == '@'){
                    this.emailRemoveInvalidFunction();
                    this.emailInvalidFunction('Enter a valid email address. No character before \'@\'.');
                }else{
                    this.emailRemoveInvalidFunction();
                    this.emailInvalidFunction('Enter a valid email address. Incorrect email provider.');
                }
                
            }else{
                this.emailRemoveInvalidFunction();
                this.emailInvalidFunction('Enter a valid email address. Input missing \'@\'.');
            }
            return false;
        }else{
            this.emailRemoveInvalidFunction();
            //this.emailValidFunction();
            return true;
        }
    }

    protected emailValidFunction(){
        this._emailValiIcon.appendChild(this._validImg);
        this._emailValiIcon.style.display= "block";
    }

    protected passwordValidFunction(){
        this._passwordValiIcon.appendChild(this._validImg2);
        this._passwordValiIcon.style.display= "block";
    }

    protected emailInvalidFunction(message:string){
        const container = document.querySelector('#emailInputContainer');
        const messageSpan = document.querySelector('#emailError span');
        let messageContainer = document.querySelector('#emailError') as HTMLElement;
        if(container){
            container.classList.add('invalidLabel');
        }
        this._emailTextBox.classList.add('invalidInput');
        if(messageSpan){
            messageSpan.innerHTML = message;
            messageSpan.setAttribute('aria-hidden', 'false');
        }
        if(messageContainer){
            messageContainer.style.display ="block";
        }
        this._emailTextBox.setAttribute('aria-invalid', true.toString());
        this._emailValiIcon.appendChild(this._invalidImg);
        this._emailValiIcon.style.display= "block";
    }

    protected passwordInvalidFunction(message:string){
        const container = document.querySelector('#passwordInputContainer');
        const messageSpan = document.querySelector('#passwordError span');
        let messageContainer = document.querySelector('#passwordError') as HTMLElement;
        if(container){
            container.classList.add('invalidLabel');
        }
        this._passwordTextBox.classList.add('invalidInput');
        if(messageSpan){
            messageSpan.innerHTML = message;
            messageSpan.setAttribute('aria-hidden', 'false');
        }
        if(messageContainer){
            messageContainer.style.display ="block";
        }
        this._passwordTextBox.setAttribute('aria-invalid', true.toString());
        this._passwordValiIcon.appendChild(this._invalidImg2);
        this._passwordValiIcon.style.display= "block";
        
    }

    protected emailRemoveInvalidFunction(){
        const container = document.querySelector('#emailInputContainer');
        const messageSpan = document.querySelector('#emailError span');
        let messageContainer = document.querySelector('#emailError') as HTMLElement;
        if(container){
            container.classList.remove('invalidLabel');
        }
        this._emailTextBox.classList.remove('invalidInput');
        if(messageSpan){
            messageSpan.innerHTML = '';
            messageSpan.setAttribute('aria-hidden', 'true');
        }
        if(messageContainer){
            messageContainer.style.display ="none";
        }
        this._emailTextBox.setAttribute('aria-invalid', false.toString());
        this._emailValiIcon.innerHTML = '';
        this._emailValiIcon.style.display= "none";
    }

    protected passwordRemoveInvalidFunction(){
        const container = document.querySelector('#passwordInputContainer');
        const messageSpan = document.querySelector('#passwordError span');
        let messageContainer = document.querySelector('#passwordError') as HTMLElement;
        if(container){
            container.classList.remove('invalidLabel');
        }
        this._passwordTextBox.classList.remove('invalidInput');
        if(messageSpan){
            messageSpan.innerHTML = '';
            messageSpan.setAttribute('aria-hidden', 'true');
        }
        if(messageContainer){
            messageContainer.style.display ="none";
        }
        this._passwordTextBox.setAttribute('aria-invalid', false.toString());
        this._passwordValiIcon.innerHTML = '';
        this._passwordValiIcon.style.display= "none";
    }

    //#region Events
    private keyAttemptToggleDialog = (event: KeyboardEvent) => {
        // toggle dialog if enter is pressed
        if (event.keyCode == 13 && this._canToggle) {
            // enter was pressed, open/close the dialog
            this.toggleLogInDialog(event);
        }
    }

    protected signOutUser = (event: Event) => {
        AnalyticInteractions.headerEvent(GA_eventActions.click, "Login Module Sign Out", 0, event.target as HTMLButtonElement);
        const ajaxURL = `/logoff.php?action=ajax`;
        fetch(ajaxURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            })
            .then((resp) => {
                if (!resp.ok) {
                    // this should get caught by our fetch/fail
                    throw Error(resp.statusText);
                }
                return resp;
            })
            .then((resp) => resp.json())
            .then((resJSON) => {
                const status = resJSON['logoff'];
                const newtoken = resJSON['token'];
                this._loginToken.setAttribute('value', newtoken);
                if(status == 'success'){
                    const firstNameDiv = document.querySelector('.myAccount .accountName');
                    const firstNameDiv2 = document.querySelector('.changeLoginDialog .accountName');
                    firstNameDiv.innerHTML = '';
                    if(firstNameDiv2){firstNameDiv2.innerHTML = '';}
                    document.body.dataset.customer_first_name = '';
                    this.toggleLogInDialog(event);
                    this._callBackmessage = "You have been successfully signed out of your account.";
                    (<HTMLInputElement>this._emailTextBox).value = '';
                    (<HTMLInputElement>this._passwordTextBox).value = '';
                    this.toggleLogInDialog(event);
                }
            });
    };

    protected toggleLogInDialog = (event: Event) => {
        // keep this button from doing anything other than what we want
        event.preventDefault();

        // toggle the display of the form, using ARIA tags (CSS will target these for toggling visibility)
        if (this._formElement.getAttribute('aria-hidden') === 'true' && this._formLoggedIn.getAttribute('aria-hidden') === 'true') {
            if(document.body.dataset.customer_first_name){
                if(document.body.dataset.customer_first_name.length >0){
                    this._userFirstName2.innerHTML = "Hello "+ document.body.dataset.customer_first_name;
                }
                this.appendToDesktop2();
                this._formLoggedIn.setAttribute('aria-hidden', 'false');
                // When the dialog appears on the screen, keyboard focus should be moved to the default focusable control inside the dialog. 
                this._accountDashboard.focus();
            }else{
                const firstNameDiv = document.querySelector('.myAccount .accountName');
                const firstNameDiv2 = document.querySelector('.changeLoginDialog .accountName');
                firstNameDiv.innerHTML = '';
                if(firstNameDiv2){firstNameDiv2.innerHTML = '';}
                if(this._callBackmessage.length >0){
                    this._message.innerHTML = this._callBackmessage;
                    this._message.classList.add("messageLabelAdded");
                    var isLogout = false;
                    if(this._callBackmessage == "You have been successfully signed out of your account."){
                        isLogout = true;
                    }
                    setTimeout(() => {
                        //this._message.classList.add('fadeOut');
                        this._message.innerHTML = '';
                        this._message.classList.remove("messageLabelAdded");
                        if(window.location.href.indexOf("account") > -1 && isLogout){
                            document.location.href="/";
                        }
                    }, 4000);
                    
                }else{
                    this._message.innerHTML = '';
                    this._message.classList.remove("messageLabelAdded");
                }
                this.appendToDesktop();
                this.emailRemoveInvalidFunction();
                this.passwordRemoveInvalidFunction();
                this._formElement.setAttribute('aria-hidden', 'false');
                // When the dialog appears on the screen, keyboard focus should be moved to the default focusable control inside the dialog. 
                this._emailTextBox.focus();
            }
             // Analytic it!
            AnalyticInteractions.headerEvent(GA_eventActions.click, "Open LogIn dialog", 0, event.target as HTMLButtonElement);

        } else {
            this._callBackmessage = "";
            this._canToggle = false;
            // toggle aria-hidden to both hide from readers and trigger the css fadeout animation
            if(this._formElement.getAttribute('aria-hidden') === 'false'){
                this._formElement.setAttribute('aria-hidden', 'true');

                // clear the opacity value we animated to let the css take over
                setTimeout(() => {
                    // "refresh" our accordions
                    this.removeFromDesktop();
                }, 300); // 300 should match the transition duration in the css for this form's opacity
            }
            if(this._formLoggedIn.getAttribute('aria-hidden') === 'false'){
                this._formLoggedIn.setAttribute('aria-hidden', 'true');

                // clear the opacity value we animated to let the css take over
                setTimeout(() => {
                    // "refresh" our accordions
                    this.removeFromDesktop2();
                }, 300); // 300 should match the transition duration in the css for this form's opacity
            }

            // unbind these listeners so we don't trigger the dialog open again if enter was pushed to close
            this._toggleButton.focus();
            this.resetTimer();

            // Analytic it!
            AnalyticInteractions.headerEvent(GA_eventActions.click, "Close LogIn dialog", 0, event.target as HTMLButtonElement);
        }

        return false;
    };

    protected submitLogIn = (event: MouseEvent) => {
        // keep this button from doing anything other than what we want
        event.preventDefault();
        this.passwordRemoveInvalidFunction();
        this.emailRemoveInvalidFunction();

        var error = false;
        
        if(!this.checkEmailValiEvent(event)){
            error = true;
        }
        if(!this.checkEmailLength(event)){
            error = true;
        }
        if(!this.checkPasswordValiEvent(event)){
            error = true;
        }

        if (this._emailTextBox.value.match(/[\(\)\<\>\,\;\:\\\/\"\[\]]/)) {
            this._emailTextBox.setCustomValidity(this._emailTextBox.title);
            error = true;
        } else {
            this._emailTextBox.setCustomValidity('');
        }
        
        // validate
        if (this._emailTextBox.validity.valid == true && error == false) {
            this._emailTextBox.setAttribute('aria-invalid', false.toString());
            this._emailTextBox.classList.remove('invalid');

            const ajaxURL = `/login.php?action=process`;

            const data = {email_address :  this._emailTextBox.value, password : this._passwordTextBox.value, ajaxCall: 1, loginToken: this._loginToken.value};
            let respcode = 400;
            fetch(ajaxURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                })
                .then((resp) => {
                    // if (!resp.ok) {
                    //     // this should get caught by our fetch/fail
                    //     throw Error(resp.statusText);
                    // }
                    respcode = resp.status;
                    return resp;
                })
                .then((resp) => resp.json())
                .then((resJSON) => {  
                    if(respcode != 428){
                        var status = resJSON['login'];
                        const newtoken = resJSON['token'];
                        this._loginToken.setAttribute('value', newtoken);
                    }           
                    
                    if(status == 'success'){
                        const customer_first_name = (resJSON['customer_first_name'].length >0 ? resJSON['customer_first_name'] : ' ');
                        const customer_id = resJSON['customer_id'];
                        const firstNameDiv = document.querySelector('.myAccount .accountName');
                        const firstNameDiv2 = document.querySelector('.changeLoginDialog .accountName');

                        // copy from footer2
                        // generate logoff link on full width sites
                        // locate our containers, generate our link element & add it to the DOM (desktop)
                        if(!document.querySelector('a.logoffLink')){
                            const desktopAccountDiv2 = document.querySelector('.myAccount'),
                                mobileLinkSection2 = document.querySelector('#mobilePersonalStatic');

                            // assign this class to the container to color the account icon
                            desktopAccountDiv2.classList.add('loggedIn');
                            mobileLinkSection2?.classList.add('loggedIn');
                        }

                        if (customer_first_name.length > 0) {
                            document.body.dataset.customer_first_name = customer_first_name;
                            firstNameDiv.innerHTML = customer_first_name;
                            if(firstNameDiv2){firstNameDiv2.innerHTML = customer_first_name;}
                        }
                      
                        this.toggleLogInDialog(event);
                        AnalyticInteractions.headerEvent(GA_eventActions.click, "User Login success event", GA_EventValues.LogInSubmit, event.target as HTMLButtonElement);
                    }else if(status == 'email' && respcode == 401){
                        this.emailRemoveInvalidFunction();
                        this.passwordRemoveInvalidFunction();
                        this.emailInvalidFunction('Enter a valid email address');
                        AnalyticInteractions.headerEvent(GA_eventActions.click, "User Login failed event. Email issue.", GA_EventValues.LogInSubmit, event.target as HTMLButtonElement);
                    }else if(status == 'password' && respcode == 401){
                        this.emailRemoveInvalidFunction();
                        this.passwordRemoveInvalidFunction();
                        this.passwordInvalidFunction('Incorrect password entered. Try again.');
                        AnalyticInteractions.headerEvent(GA_eventActions.click, "User Login failed event. Password issue.", GA_EventValues.LogInSubmit, event.target as HTMLButtonElement);
                    }else if(status == 'token'&& respcode == 401){
                        this.emailRemoveInvalidFunction();
                        this.passwordRemoveInvalidFunction();
                        this.emailInvalidFunction('Your security token has expired. Please refresh your page and try again.');
                        AnalyticInteractions.headerEvent(GA_eventActions.click, "Mobile: Login Failed, security issue.", GA_EventValues.LogInSubmit, event.target as HTMLButtonElement);
                    }else if(respcode == 428){
                        //Akamai BMP ReCaptcha
                        //do nothing
                    }else{
                        this.emailRemoveInvalidFunction();
                        this.passwordRemoveInvalidFunction();
                        this.passwordInvalidFunction('Unexpected Error. Try again.');
                        AnalyticInteractions.headerEvent(GA_eventActions.click, "User Login failed event. Unexpected issue.", GA_EventValues.LogInSubmit, event.target as HTMLButtonElement);
                    }
                });
        } else {
            //this.passwordRemoveInvalidFunction();
            //this.passwordInvalidFunction('Incorrect password entered. Try again.');
            // notify the user
            //this.reportValidity();
            //this._emailTextBox.setCustomValidity('');
            AnalyticInteractions.headerEvent(GA_eventActions.click, "User Login Failed event", 0, event.target as HTMLButtonElement);
        }
    };

    private resetTimer = () => {
        clearTimeout(this._timerID);

        // throttle using our timer to keep this event from firing too frequently
        this._timerID = window.setTimeout(() => {
            // "refresh" our accordions
            this._canToggle = true;
        }, 300);

    };
    ////#endregion events
}


export class mobileLogInModule extends logInModule {
    private _header: mobileNavHeader;
    private _previousHeaderText: string;
    private readonly _tweenSpeed: number = .3;

    /**
     *Creates an instance of logInForm.
     * @param {string} [formTitle="My Account"]
     * @param {string} [formDescription="User Login Section"]
     * @memberof LoginForm
     */
    constructor(
        toggleButton: HTMLButtonElement,
        header: mobileNavHeader,
        loginTokenVal?: string,
        onSubmit?: Function,
        formTitle: string = "Welcome",
        formDescription: string = "User LogIn Section",
    ) {
        super(toggleButton, loginTokenVal, onSubmit, formTitle, formDescription);
        this._header = header;
        if (this._toggleButton) {
            this._toggleButton.onclick = this.toggleLogInDialog; // make sure we're binding to our instance    
        }        
        this.scaffoldForm(loginTokenVal);
    }

    /**
     * Create & assemble our form elements
     *
     * @private
     * @memberof loginForm
     */
    protected scaffoldForm(loginTokenVal:string) {
        super.scaffoldForm(loginTokenVal);
        this._formElement.id = formIDs.mobile;
        this._formLoggedIn.id = formIDs.mobile2;
        this._closeButton.className = "locationClose2";
        this._closeButton.setAttribute('aria-label', "Close Sign in Dialog");
        this._closeButton.innerHTML = "<span class='icon-cheveron-left'></span>Back";
        this._closeButton2.className = "locationClose2";
        this._closeButton2.setAttribute('aria-label', "Close Sign In Dialog");
        this._closeButton2.innerHTML = "<span class='icon-cheveron-left'></span>Back";
    }

    protected toggleLogInDialog = (event: Event) => {
        event.preventDefault;
        if (this._formElement.getAttribute('aria-hidden') === 'true' && this._formLoggedIn.getAttribute('aria-hidden') === 'true') {
            if(document.body.dataset.customer_first_name){
                if(document.body.dataset.customer_first_name.length >0){
                    this._userFirstName2.innerHTML = "Hello "+ document.body.dataset.customer_first_name;
                }
                document.querySelector('#mobileLogIn').innerHTML = '';
                document.querySelector('#mobileLogIn').appendChild(this._formLoggedIn);
                let container = document.querySelector('#mobileLoginForm2') as HTMLElement;
                if (this._formLoggedIn.getAttribute('aria-hidden') === 'true') {
                    //this.appendToDesktop();
                    this._formLoggedIn.setAttribute('aria-hidden', 'false');
                    container.style.display = 'block';
                    // hide the toggle link?
                    this._toggleButton.style.display = "none";
                    // update the header

                    this._previousHeaderText = this._header.text;
                    this._header.setText(this._formTitle);
                    this._header.hideLink();
                    // When the dialog appears on the screen, keyboard focus should be moved to the default focusable control inside the dialog. 
                    gsap.to(['#mainMobileSlide'], {
                        duration: this._tweenSpeed,
                        x: '-=' + 315, onComplete: () => {
                            document.querySelector('#headerNavigation').scrollTop = 0;
                            this._emailTextBox.focus();
                        }
                    });
                    // analytics
                    AnalyticInteractions.headerEvent(GA_eventActions.click, "Mobile: Open user login dialog", GA_EventValues.LogInOpen, event.target as HTMLButtonElement);
                    // When the dialog appears on the screen, keyboard focus should be moved to the default focusable control inside the dialog. 
                    this._emailTextBox.focus();
                    
                }
                this._accountDashboard.focus();
            }else{
                // to do  add first name mobile after logged if / delete
                const firstNameDiv = document.querySelector('.myAccount .accountName');
                const firstNameDiv2 = document.querySelector('.changeLoginDialog .accountName');
                firstNameDiv.innerHTML = '';
                if(firstNameDiv2){firstNameDiv2.innerHTML = '';}

                document.querySelector('#mobileLogIn').innerHTML = '';
                document.querySelector('#mobileLogIn').appendChild(this._formElement);
                
                const ajaxURL = `/login.php?action=new_token`;
                fetch(ajaxURL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    })
                    .then((resp) => {
                        if (!resp.ok) {
                            // this should get caught by our fetch/fail
                            throw Error(resp.statusText);
                        }
                        return resp;
                    })
                    .then((resp) => resp.json())
                    .then((resJSON) => {
                        const status = resJSON['new_token'];
                        const newtoken = resJSON['token'];
                        if(status == 'success'){
                            this._loginToken.setAttribute('value', newtoken);
                        }
                    });
                this.emailRemoveInvalidFunction();
                this.passwordRemoveInvalidFunction();
                let container = document.querySelector('#mobileLoginForm') as HTMLElement;
                // toggle the display of the form, using ARIA tags (CSS will target these for toggling visibility)
                if (this._formElement.getAttribute('aria-hidden') === 'true') {
                    this._formElement.setAttribute('aria-hidden', 'false');
                    if(this._callBackmessage.length >0){
                        this._message.innerHTML = this._callBackmessage;
                        this._message.classList.add("messageLabelAdded");
                        var isLogout = false;
                        if(this._callBackmessage == "You have been successfully signed out of your account."){
                            isLogout = true;
                        }
                        setTimeout(() => {
                            this._message.innerHTML = '';
                            this._message.classList.remove("messageLabelAdded");
                            if(window.location.href.indexOf("account") > -1 && isLogout){
                                document.location.href="/";
                            }
                        }, 4000);
                    }else{
                        this._message.innerHTML = '';
                        this._message.classList.remove("messageLabelAdded");
                    }
                    container.style.display = 'block';
                    // hide the toggle link?
                    this._toggleButton.style.display = "none";
                    // update the header

                    this._previousHeaderText = this._header.text;
                    this._header.setText(this._formTitle);
                    this._header.hideLink();
                    // When the dialog appears on the screen, keyboard focus should be moved to the default focusable control inside the dialog. 
                    gsap.to(['#mainMobileSlide'], {
                        duration: this._tweenSpeed,
                        x: '-=' + 315, onComplete: () => {
                            document.querySelector('#headerNavigation').scrollTop = 0;
                            //this._emailTextBox.focus();
                        }
                    });
                    // analytics
                    AnalyticInteractions.headerEvent(GA_eventActions.click, "Mobile: Open user login dialog", GA_EventValues.LogInOpen, event.target as HTMLButtonElement);
                    // When the dialog appears on the screen, keyboard focus should be moved to the default focusable control inside the dialog. 
                    //this._emailTextBox.focus();
                    
                }
            }   
        } else {
                this._canToggle = false;
                // show the toggle link?
                this._toggleButton.style.display = "";
                this._callBackmessage = "";
                // update the header
                this._header.setText(this._previousHeaderText);
                if (this._header.text != "Main Menu") {
                    this._header.showLink();
                }
                gsap.to(['#mainMobileSlide'], {
                    duration: this._tweenSpeed,
                    x: '+=' + 315, onComplete: () => {
                        this._formElement.setAttribute('aria-hidden', 'true');
                        this._formLoggedIn.setAttribute('aria-hidden', 'true');
                        this._toggleButton.focus();
                    }
                });
                document.querySelector('#mobileLogIn').innerHTML = '';
                // Analytic it!
                AnalyticInteractions.headerEvent(GA_eventActions.click, "Mobile: Close LogIn dialog", GA_EventValues.LogInOpen, event.target as HTMLButtonElement);
                // unbind these listeners so we don't trigger the dialog open again if enter was pushed to close

                //this.resetTimer();
            }

        return false;
    }

    protected signOutUser = (event: Event) => {
        const ajaxURL = `/logoff.php?action=ajax`;
        fetch(ajaxURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            })
            .then((resp) => {
                if (!resp.ok) {
                    // this should get caught by our fetch/fail
                    throw Error(resp.statusText);
                }
                return resp;
            })
            .then((resp) => resp.json())
            .then((resJSON) => {
                const status = resJSON['logoff'];
                if(status == 'success'){
                    const firstNameDiv = document.querySelector('.myAccount .accountName');
                    const firstNameDiv2 = document.querySelector('.changeLoginDialog .accountName');
                    firstNameDiv.innerHTML = '';
                    if(firstNameDiv2){firstNameDiv2.innerHTML = '';}
                    document.body.dataset.customer_first_name = '';
                    (<HTMLInputElement>this._emailTextBox).value = '';
                    (<HTMLInputElement>this._passwordTextBox).value = '';
                }
            });

            document.querySelector('#mobileLogIn').innerHTML = '';
            this._formElement.setAttribute('aria-hidden', 'true');
            this._formLoggedIn.setAttribute('aria-hidden', 'true');

            this._callBackmessage = "You have been successfully signed out of your account.";
            AnalyticInteractions.headerEvent(GA_eventActions.click, "Mobile: Sign Out User", GA_EventValues.LogInOpen, event.target as HTMLButtonElement);
            setTimeout(() => {
                // to do  add first name mobile after logged if / delete
                this._formElement.setAttribute('aria-hidden', 'false');
                const firstNameDiv = document.querySelector('.myAccount .accountName');
                const firstNameDiv2 = document.querySelector('.changeLoginDialog .accountName');
                firstNameDiv.innerHTML = '';
                if(firstNameDiv2){firstNameDiv2.innerHTML = '';}

                document.querySelector('#mobileLogIn').innerHTML = '';
                document.querySelector('#mobileLogIn').appendChild(this._formElement);
                
                const ajaxURL = `/login.php?action=new_token`;
                fetch(ajaxURL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    })
                    .then((resp) => {
                        if (!resp.ok) {
                            // this should get caught by our fetch/fail
                            throw Error(resp.statusText);
                        }
                        return resp;
                    })
                    .then((resp) => resp.json())
                    .then((resJSON) => {
                        const status = resJSON['new_token'];
                        const newtoken = resJSON['token'];
                        if(status == 'success'){
                            this._loginToken.setAttribute('value', newtoken);
                        }
                    });
                this.emailRemoveInvalidFunction();
                this.passwordRemoveInvalidFunction();
                let container = document.querySelector('#mobileLoginForm') as HTMLElement;
                // toggle the display of the form, using ARIA tags (CSS will target these for toggling visibility)
                this._formElement.setAttribute('aria-hidden', 'false');
                if(this._callBackmessage.length >0){
                    this._message.innerHTML = this._callBackmessage;
                    this._message.classList.add("messageLabelAdded");
                    var isLogout = false;
                    if(this._callBackmessage == "You have been successfully signed out of your account."){
                        isLogout = true;
                    }
                    setTimeout(() => {
                        this._message.innerHTML = '';
                        this._message.classList.remove("messageLabelAdded");
                        if(window.location.href.indexOf("account") > -1 && isLogout){
                            document.location.href="/";
                        }
                    }, 4000);
                }else{
                    this._message.innerHTML = '';
                    this._message.classList.remove("messageLabelAdded");
                }
                container.style.display = 'block';
                // hide the toggle link?
                this._toggleButton.style.display = "none";
                // update the header

                this._previousHeaderText = this._header.text;
                this._header.setText(this._formTitle);
                this._header.hideLink();

                // analytics
                AnalyticInteractions.headerEvent(GA_eventActions.click, "Mobile: Open user login dialog", GA_EventValues.LogInOpen, event.target as HTMLButtonElement);
                    
            }, 500);
        };

    protected submitLogIn = (event: MouseEvent) => {
        // keep this button from doing anything other than what we want
        event.preventDefault();

        this.passwordRemoveInvalidFunction();
        this.emailRemoveInvalidFunction();

        var error = false;

        if(!this.checkEmailValiEvent(event)){
            error = true;
        }
        if(!this.checkEmailLength(event)){
            error = true;
        }
        if(!this.checkPasswordValiEvent(event)){
            error = true;
        }

        if (this._emailTextBox.value.match(/[\(\)\<\>\,\;\:\\\/\"\[\]]/)) {
            this._emailTextBox.setCustomValidity(this._emailTextBox.title);
            error = true;
        } else {
            this._emailTextBox.setCustomValidity('');
        }
        
        // validate
        if (this._emailTextBox.validity.valid == true && error == false) {
            this._emailTextBox.setAttribute('aria-invalid', false.toString());
            this._emailTextBox.classList.remove('invalid');

            const ajaxURL = `/login.php?action=process`;

            const data = {email_address :  this._emailTextBox.value, password : this._passwordTextBox.value, ajaxCall: 1, loginToken: this._loginToken.value};
            let respcode = 400;
            fetch(ajaxURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                })
                .then((resp) => {
                    // if (!resp.ok) {
                    //     // this should get caught by our fetch/fail
                    //     throw Error(resp.statusText);
                    // }
                    respcode = resp.status;
                    return resp;
                })
                .then((resp) => resp.json())
                .then((resJSON) => {
                    const status = resJSON['login'];
                    const newtoken = resJSON['token'];
                    this._loginToken.setAttribute('value', newtoken);

                    if(status == 'success'){
                        const customer_first_name = (resJSON['customer_first_name'].length >0 ? resJSON['customer_first_name'] : ' ');
                        const customer_id = resJSON['customer_id'];
                        const firstNameDiv = document.querySelector('.myAccount .accountName');
                        const firstNameDiv2 = document.querySelector('.changeLoginDialog .accountName');

                        // copy from footer2
                        // generate logoff link on full width sites
                        // locate our containers, generate our link element & add it to the DOM (desktop)
                        if(!document.querySelector('a.logoffLink')){
                            const desktopAccountDiv2 = document.querySelector('.myAccount'),
                                mobileLinkSection2 = document.querySelector('#mobilePersonalStatic');

                            // assign this class to the container to color the account icon
                            desktopAccountDiv2.classList.add('loggedIn');
                            mobileLinkSection2?.classList.add('loggedIn');
                        }

                        if (customer_first_name.length > 0) {
                            document.body.dataset.customer_first_name = customer_first_name;
                            firstNameDiv.innerHTML = customer_first_name;
                            if(firstNameDiv2){firstNameDiv2.innerHTML = customer_first_name;}
                        }
                      
                        this.toggleLogInDialog(event);
                        document.querySelector('#mobileLogIn').innerHTML = '';
                        document.getElementById('menuBtn').click();
                        AnalyticInteractions.headerEvent(GA_eventActions.click, "Mobile: Login success", GA_EventValues.LogInSubmit, event.target as HTMLButtonElement);
                    }else if(status == 'email' && respcode == 401){
                        this.emailRemoveInvalidFunction();
                        this.passwordRemoveInvalidFunction();
                        this.emailInvalidFunction('Enter a valid email address');
                        AnalyticInteractions.headerEvent(GA_eventActions.click, "Mobile: Login Failed, email issue.", GA_EventValues.LogInSubmit, event.target as HTMLButtonElement);
                    }else if(status == 'password' && respcode == 401){
                        this.emailRemoveInvalidFunction();
                        this.passwordRemoveInvalidFunction();
                        this.passwordInvalidFunction('Incorrect password entered. Try again.');
                        AnalyticInteractions.headerEvent(GA_eventActions.click, "Mobile: Login Failed, password issue.", GA_EventValues.LogInSubmit, event.target as HTMLButtonElement);
                    }else if(status == 'token' && respcode == 401){
                        this.emailRemoveInvalidFunction();
                        this.passwordRemoveInvalidFunction();
                        this.emailInvalidFunction('Your security token has expired. Please refresh your page and try again.');
                        AnalyticInteractions.headerEvent(GA_eventActions.click, "Mobile: Login Failed, security issue.", GA_EventValues.LogInSubmit, event.target as HTMLButtonElement);
                    }else{
                        this.emailRemoveInvalidFunction();
                        this.passwordRemoveInvalidFunction();
                        this.emailInvalidFunction('Unexpected Error. Please refresh your page and try again.');
                        AnalyticInteractions.headerEvent(GA_eventActions.click, "Mobile: Login Failed, unexpected issue", GA_EventValues.LogInSubmit, event.target as HTMLButtonElement);
                    }
                });

            //AnalyticInteractions.headerEvent(GA_eventActions.click, "User Login event", GA_EventValues.LogInSubmit, event.target as HTMLButtonElement);
        } else {
            this.passwordRemoveInvalidFunction();
            this.passwordInvalidFunction('Incorrect password entered. Try again.');
            // notify the user
            this.reportValidity();
            this._emailTextBox.setCustomValidity('');
            AnalyticInteractions.headerEvent(GA_eventActions.click, "Mobile: Login Failed", GA_EventValues.LogInOpen, event.target as HTMLButtonElement);
        }
    };

    //#endregion events
}