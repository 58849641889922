import './public-path-PED';

import 'focus-visible/dist/focus-visible.min.js';
import preload from './preload';
import { delegate, sticky } from 'tippy.js';
import { headerModule } from './header/headerModule';
import { cartModule } from './header/cartModule';
import { footerModule } from './footer/footerModule';
import { cleanup, navData, topBarContent } from './helpers/dataExtractor';
import { domHelpers, eventHelpers } from './helpers/helpers';
import {
    AnalyticInteractions,
    GA_eventActions,
    GA_EventValues,
    linkOpen,
} from './helpers/AnalyticHelper';
import pageScriptImport from './loadPageScripts';
import { iterable } from './helpers/iterable';
import './helpers/sentry';
import * as bootstrap from 'bootstrap';

(async function main() {
	window.bootstrap = bootstrap;
    await preload();
    await import('../app/App'); // load react components
    new pageScriptImport();
    initTooltips();
    initPopovers();
    setPageEvents();
    initHeaderFooter();

    await import('./page/footer');
})();

function initHeaderFooter() {
    const isLegacy = Boolean(document.body.dataset?.legacy == '1'),
        headerLogoLink = document.querySelector(
            '#header .headerLogo a'
        ) as HTMLAnchorElement,
        callLink = document.querySelector(
            '#header .phoneDisplay a'
        ) as HTMLAnchorElement,
        helpLink = document.querySelector(
            '#header .helpLink a'
        ) as HTMLAnchorElement,
        accountLink = document.querySelector(
            '#header .myAccount button'
        ) as HTMLButtonElement,
        mobileAccountLink = document.querySelector(
            '#mobilePersonalStatic .changeLoginDialog'
        ) as HTMLAnchorElement;

    if (navData) {
        const headerPhoneArea = document.querySelector('.headerPhone'),
            headerPhoneDisplay = headerPhoneArea.querySelector(
                '.phoneDisplay'
            ) as HTMLElement,
            headerPhonePipe = headerPhoneArea.querySelector(
                '.pipe'
            ) as HTMLElement,
            headerPhoneLink = headerPhoneDisplay.querySelector(
                'a'
            ) as HTMLAnchorElement,
            headerPhoneSpan = headerPhoneLink.querySelector(
                '.phoneNumber'
            ) as HTMLSpanElement;

        document.body.addEventListener('headerIndexAdjust', () => {
            // this event makes sure header is behind new nav when opened
            const headerMain = document.querySelector(
                '#headerMain'
            ) as HTMLDivElement;
            if (headerMain.style.zIndex) {
                // remove the style we applied when opening nav
                // wait a moment for transitions to finish
                setTimeout(() => {
                    headerMain.style.zIndex = '';
                }, 300);
            } else {
                // add the style when opening nav
                headerMain.style.zIndex = '4';
            }

            const adminBar = document.getElementById(
                'productAdminBarContent'
            ) as HTMLDivElement;
            if (adminBar) {
                if (adminBar.style.zIndex) {
                    // remove the style we applied when opening nav
                    // wait a moment for transitions to finish
                    setTimeout(() => {
                        adminBar.style.zIndex = '';
                    }, 300);
                } else {
                    // add the style when opening nav
                    adminBar.style.zIndex = '2001';
                }
            }
        });

        const loginToken = navData.loginToken;

        headerPhoneLink.href = 'tel: ' + navData.phoneNumber;
        if (navData.phoneNumber) {
            headerPhoneSpan.textContent = 'Call ' + navData.phoneNumber;
        } else {
            // delete phone number and pipe character
            headerPhoneDisplay.style.display = 'none';
            headerPhonePipe.style.display = 'none';
        }

        new headerModule(null, topBarContent, loginToken);

        new footerModule(
            JSON.parse(navData.siteLinkJSON),
            JSON.parse(navData.storeLinkJSON),
            JSON.parse(navData.socialLinkJSON),
            JSON.parse(navData.miscLinkJSON),
            navData.expertJSON,
            navData.bbbJSON
        );

        // remove data div from dom
        cleanup();

        if (isLegacy) {
            window.headerCartModule = new cartModule('persCart' + document.body.dataset.sid);
        }
        window.helpers = {
            trapFocus: domHelpers.trapFocus,
            lazyLoad: eventHelpers.initResponsiveLazyImages,
            isSmallScreen: domHelpers.isSmallScreen,
        };
        window.CLS_Debug = {
            toggle: () => {
                const CLS = document.getElementById('CLS');
                if (CLS) {
                    if (CLS.style.display === 'none') {
                        CLS.style.display = '';
                    } else {
                        CLS.style.display == 'none';
                    }
                }
            },
        };

        // add some additional analytic interactions not covered by previous modules
        // call us link
        callLink.addEventListener('click', (event: MouseEvent) => {
            // event.preventDefault();
            let openMethod: linkOpen = linkOpen.self;
			if (callLink.target == '_blank' || event.shiftKey) openMethod = linkOpen.newWindow;
			if (event.ctrlKey) openMethod = linkOpen.newTab;

            AnalyticInteractions.headerEvent(
                GA_eventActions.click,
                'Header Call Link',
                GA_EventValues.HeaderCall,
                callLink,
                openMethod
            );
        });
        // help click
        helpLink.addEventListener('click', (event: MouseEvent) => {
            // event.preventDefault();
            let openMethod: linkOpen = linkOpen.self;
			if (helpLink.target == '_blank' || event.shiftKey) openMethod = linkOpen.newWindow;
			if (event.ctrlKey) openMethod = linkOpen.newTab;

            AnalyticInteractions.headerEvent(
                GA_eventActions.click,
                'Header Help Link',
                GA_EventValues.HeaderHelp,
                helpLink,
                openMethod
            );
        });
        // logo click
        headerLogoLink.addEventListener('click', (event: MouseEvent) => {
            // event.preventDefault();
			let openMethod: linkOpen = linkOpen.self;
			if (headerLogoLink.target == '_blank' || event.shiftKey) openMethod = linkOpen.newWindow;
			if (event.ctrlKey) openMethod = linkOpen.newTab;
            AnalyticInteractions.headerEvent(
                GA_eventActions.click,
                'Logo Home Page Link',
                GA_EventValues.HeaderLogo,
                headerLogoLink,
                openMethod
            );
        });
        // my account click
        accountLink.addEventListener('click', (event: MouseEvent) => {
            // event.preventDefault();
            AnalyticInteractions.headerEvent(
                GA_eventActions.click,
                'Header My Account Link',
                GA_EventValues.HeaderMyAccount,
                accountLink
            );
        });
        mobileAccountLink?.addEventListener('click', (event: MouseEvent) => {
            // event.preventDefault();
            AnalyticInteractions.headerEvent(
                GA_eventActions.click,
                'Header My Account Link',
                GA_EventValues.MobileMyAccount,
                mobileAccountLink
            );
        });

        if (navData.iterable) {
            iterable.initialize(navData.iterable);
        }
    }
}

function setPageEvents() {
    const enableScroll = () => {
        document.querySelector('html')?.style.removeProperty('overflow');
    };

    const disableScroll = () => {
        document.querySelector('html').style.overflow = 'hidden';
    };

    document.body.addEventListener('disableScroll', disableScroll);
    document.body.addEventListener('enableScroll', enableScroll);

    eventHelpers.initResponsiveLazyImages();
}

function initTooltips() {
    const tooltips = delegate('#mainContent', {
        target: '.tooltipnew img, .tooltipnew > span:first-of-type',
        onCreate: function (instance) {
            // let's target the close button the the popup and give it a proper event
            const closeBtn = instance.popper.querySelector(
                '.tooltipClose'
            ) as HTMLButtonElement;

            if (closeBtn) {
                closeBtn.onclick = (e: Event) => {
                    e.preventDefault();
                    instance.hide();
                };
            }
        },
        onHidden: function (instance) {
            // once we've hidden the tooltip, return our focus back to the element that invoked the tooltip
            (instance.reference as HTMLElement).focus();
        },
        onMount: function (instance) {
            const titleID: string =
                instance.reference
                    .closest('.tooltipnew')
                    .querySelector('header')
                    .textContent.replace(/ /g, '') + Math.random().toString();
            instance.popper.id = titleID;
            instance.reference.setAttribute('aria-labeledby', titleID);
        },
        onShown: function (instance) {
            const titleID: string =
                    instance.reference
                        .closest('.tooltipnew')
                        .querySelector('header')
                        .textContent.replace(/ /g, '') +
                    Math.random().toString(), // generate tooltip ID for aria linking
                parent = instance.popper.querySelector(
                    '.tippy-content'
                ) as HTMLElement; // get reference to the text element within the tooltip

            instance.popper.id = titleID; // set the tooltip's ID
            parent.tabIndex = 0;
            (parent.querySelector('header + span') as HTMLSpanElement).focus(); // focus the text so reader will read it

            domHelpers.trapFocus(parent); // trap focus in tooltip, can only exit on with button or escape

            parent.onkeyup = (event: KeyboardEvent) => {
                event.preventDefault();
                if (event.key == 'Escape') {
                    instance.hide();
                }
            };
        },
        onShow: function (instance) {
            // if we got here using any key other than enter, cancel the tooltip by returning false
            if (instance.popper.dataset.cancelOpen == 'true') {
                return false;
                instance.hide();
            }
        },
        onTrigger: function (instance, event) {
            // logic so the onShow can know if it was the Enter key that invoked the tooltip or another key
            // this is because using 'click' caused two events to fire, on both up and down
            instance.popper.dataset.cancelOpen = 'false';
            if ('key' in event) {
                const key = (event as KeyboardEvent).key;
                if (key != 'Enter') {
                    // if we pressed a key other than enter, ignore it
                    instance.popper.dataset.cancelOpen = 'true';
                }
            }

            if (instance.state.isShown) {
                instance.popper.dataset.cancelOpen = 'true';
                instance.hide();
            }
        },
        content: function (ref) {
            // DOM element references
            const parent = ref.closest('.tooltipnew');
            if (parent) {
                const textNode = parent.querySelector('.tooltiptextnew');

                // here we're pulling our tooltip inner HTML from the DOM node into the tooltip instance
                return textNode.innerHTML;
            }
        },
        arrow: false, // whether or not to show an arrow on the tooltip
        aria: 'labelledby', // what ARIA attribute to use
        maxWidth: 280, // max tooltip width
        placement: 'bottom', // default tooltip placement relative to the element that called it
        distance: 7, // distance from tooltip to element that called it
        theme: 'PED_toolTip', // CSS theme to apply to the tooltip, see shared_source\scss\components\_toolTips.scss
        interactive: true, // whether or not you can interact with elements inside the tooltip
        sticky: true, // keep it attached to the element that called it when the page moves
        flipOnUpdate: false, // whether or not it flips when it gets close to the edge
        flip: false, // whether or not it flips on open to keep whole thing visible
        appendTo: document.body, // append to document body
        // hideOnClick: false,      // uncomment to keep tooltip open for CSS debugging
        trigger: 'click keyup', // what events trigger the tooltip
        plugins: [sticky],
    });
}

function initPopovers() {
    const popovers = new bootstrap.Popover('body', {
        selector: '[data-bs-toggle="popover"]',
        trigger: 'focus',
    });
}
