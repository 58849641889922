import { AnalyticInteractions, GA_eventActions, GA_EventValues } from '../helpers/AnalyticHelper';

interface cartItem {
    productID: number | string;
    quantity: number;
}
interface cartData extends Array<cartItem> {
    [index: number]: cartItem;
}

export class cartModule {
    private _key: string;
    private _cartData: cartData = [];
    private _cartLink: HTMLAnchorElement;
    private _quantityLabel: SVGTSpanElement;

    /**
     *Creates an instance of cartModule.
     * @param {string} [localStorageKey='persCart']
     * @param {string} [quantityLabel='#SVG_cartQuantity']
     * @param {number} [quantity]
     * @memberof cartModule
     */
    constructor(localStorageKey: string = 'persCart', cartLink:string = ".headerCart a") {
        this._key = localStorageKey;

        this._cartLink = document.querySelector(cartLink);
        // set some analytic events
        this._cartLink.addEventListener('click', (e:MouseEvent)=>{
            // e.preventDefault(); // will redirect in analytic event
            AnalyticInteractions.headerEvent(
                GA_eventActions.click,
                'View shopping cart via header icon',
                GA_EventValues.ShoppingCartLinkClick,
                this._cartLink as HTMLAnchorElement
            );
        });
        // record the reference to our cart quantity label
        this._quantityLabel = this._cartLink.querySelector('#SVG_cartQuantity');
        if(this.storageAvailable('localStorage')) this.refreshCartDataFromStorage();
    }

    /**
     * Determine quantity from cart data in localStorage
     *
     * @readonly
     * @type {string}
     * @memberof cartModule
     */
    get quantityFromStorage(): string {
        return this._cartData ? this.sumQuantity(this._cartData) : '0';
    }

    private updateAlt(qty:string){
        const svgElement = this._cartLink.querySelector('svg');
        let titleElement = svgElement.querySelector('title');
        
        if (!titleElement) {
            // we don't currently have a title element
            titleElement = document.createElement('title');
            svgElement.prepend(titleElement);
        }
        titleElement.textContent = 'Shopping Cart: ' + qty + ' item'+(parseInt(qty) == 1 ? '':'s');
    }

    public reSyncCartDataFromServer(serverData: any) {
        this._cartData = [];
        for (const key in serverData) {
            if (serverData.hasOwnProperty(key)) {
                const productDataItem = serverData[key];
                this._cartData.push({
                    productID: productDataItem.id as string,
                    quantity: parseInt(productDataItem.quantity)
                });
            }
        }

        this.updateStorageValue();
    }

    /**
     * Refresh this object's data from local storage value
     *
     * @memberof cartModule
     */
    public refreshCartDataFromStorage() {
        let localCartData = JSON.parse(localStorage.getItem(this._key));

        if (localCartData) {
            this._cartData = [];
            // build our cart dictionary
            for (const key in localCartData) {
                if (localCartData.hasOwnProperty(key)) {
                    this._cartData.push({
                        productID: key,
                        quantity: parseInt(localCartData[key])
                    });
                }
            }
        }

        this.setQuantityLabelValue();
    }

    storageAvailable(type: string | number) {
        try {
            const storage = window[type],
                x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch(e) {
            return false;
        }
    }

    /**
     * Update local storage with the value of this object
     *
     * @memberof cartModule
     */
    public updateStorageValue() {
        try {
            let output: object = {};

            this._cartData.forEach(cartItem => {
                // create key/value pairs of productIDs and quantities
                output[cartItem.productID] = cartItem.quantity;
            });

            if (Object.keys(output).length > 0) {
                // if we had output to generate, set it as the cart value
                window.localStorage.setItem(this._key, JSON.stringify(output));
            } else {
                // we might have no output (such as the last cart item was removed)
                // in this case remove the storage item
                window.localStorage.removeItem(this._key);
            }

            // update the label # to reflect the quantity in the cart
            this.setQuantityLabelValue();
        } catch (error) {
            console.error("Unable to update storage");
        }
    }

    public removeProduct(productID: string | number) {
        try {
            // find the index of the product we want to remove from our cart array
            let productIndex: number = this._cartData.findIndex(item => item.productID === productID);
            // remove it from the array
            this._cartData.splice(productIndex, 1);
            // update the localStorage value to reflect this update
            this.updateStorageValue();
        } catch (error) {
            console.error("Cannot find product " + productID.toString() + " in cart");
        }
    }

    public removeAllProducts() {
        // set our cart array to nothing
        this._cartData = [];
        // update the label on the icon
        this.updateStorageValue();
    }

    /**
     * Set the cart icon's quantity label
     *
     * @param {(string|number)} [value]
     * @memberof cartModule
     */
    public setQuantityLabelValue(value?: string | number) {
        try {
            const qtyValue:string = value ? value.toString() : this.quantityFromStorage
            // we use textContent to update the tspan value
            // this works cross-browser
            this._quantityLabel.textContent = qtyValue;
            this.updateAlt(qtyValue);
        } catch (error) {
            console.error("Unable to determine value to set");
        }
    }

    /**
     * Add a new item to the cart
     *
     * @param {cartItem} cartItem
     * @memberof cartModule
     */
    public addProductToCart(productID: string | number, quantity: number): void {
        try {
            let thisItem: cartItem = { productID, quantity };
            this._cartData.push(thisItem);
            this.updateStorageValue();
        } catch (error) {
            console.error("Error adding product");
        }
    }

    /**
     * Update the product quantity of our cart
     *
     * @memberof cartModule
     */
    public updateProductQuantity(productID: string | number, newQuantity: number | string): void {
        try {
            // when coming from a .js file, it's possible the quantity could come through as a string
            // make sure to convert it so we're working with numbers
            const cleanedQuantity: number = typeof newQuantity === "string" ? parseInt(newQuantity) : newQuantity;

            if (cleanedQuantity === 0) {
                // we passed 0 quantity, so remove it from the array
                this.removeProduct(productID);
            } else {
                // attempt to find the index of the product in the cart
                let productIndex: number = this._cartData.findIndex(item => item.productID === productID);
                if (productIndex >= 0) {
                    // we found an index, update the quantity
                    this._cartData[productIndex].quantity = cleanedQuantity;
                } else {
                    // no index, add it to the cart
                    this.addProductToCart(productID, cleanedQuantity);
                }
            }

            // update local storage and label
            this.updateStorageValue();
        } catch (error) {
            console.error("Error updating product quantity");
        }
    }

    /**
     * Parse our cart and determine our total items
     *
     * @private
     * @param {*} [cartData=this._cartData] - Object of Key/value pairs, values will be summed
     * @returns {string} representation of the number quantity
     * @memberof cartModule
     */
    private sumQuantity(cartData: cartData = this._cartData): string {
        let runningSum: number = 0;
        for (let index = 0; index < cartData.length; index++) {
            const cartItem: cartItem = cartData[index];
            runningSum += cartItem.quantity;
        }

        return runningSum.toString();
    }
}
