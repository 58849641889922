import type { ModuleState } from "../app/components/cart/ItemCount/types";

declare global {
    interface Window {
        __INITIAL_STATE__?: ModuleState;
    }
}

export default async function (): Promise<ModuleState>{
    return new Promise((resolve, reject) => {
        if ('siteData' in window) {
            window.siteData.moduleData.then((data: ModuleState) => {
                window.__INITIAL_STATE__ = data;
                resolve(data);
            }).catch((err: Error) => {
                reject(err);
            });
        } else {
            document.body.addEventListener('site-data:ready', (evt: Event) => {
                window.siteData.moduleData.then((data: ModuleState) => {
                    window.__INITIAL_STATE__ = data;
                    resolve(data);
                }).catch((err: Error) => {
                    reject(err);
                });
            }, { once: true });
        }
    });
};
