import { changeLocationModule, mobileChangeLocationModule } from './changeLocationModule';
import { logInModule, mobileLogInModule } from './logInModule';
import { searchModule, mobileSearchModule } from './searchModule';
import { mobileNavHeader } from '../navigation/mobileNavModules';
import { domHelpers } from '../helpers/helpers';

export class headerModule {
    private _desktopChangeLocationModule: changeLocationModule;
    private _mobileChangeLocationModule: mobileChangeLocationModule;
    private _mobileLogInModule: mobileLogInModule;
    private _zipCode: string;

    constructor(mobileHeader: mobileNavHeader, topContent?: string, loginToken?: string) {
        const headerSearch = document.querySelector('.headerSearch') as HTMLElement,
            topBar = document.querySelector('#headerTop');

        if (topContent) {
            topBar.querySelector('.constrainer').innerHTML = topContent;
        }

        this._desktopChangeLocationModule = new changeLocationModule(
            document.querySelector('.location .changeLocationDialog') as HTMLButtonElement,
            null,
            this.zipChanged
        );

        if(mobileHeader){
            this._mobileChangeLocationModule = new mobileChangeLocationModule(
                document.querySelector('#mobilePersonalStatic .changeLocationDialog') as HTMLButtonElement,
                null,
                mobileHeader,
                this.zipChanged
            );
        }

        new logInModule(
            document.querySelector('.myAccount #btnChangeLoginDialog') as HTMLButtonElement,
            loginToken
        );
        
        if (mobileHeader) {
            new mobileLogInModule(
                document.querySelector('#mobilePersonalStatic .changeLoginDialog') as HTMLButtonElement,
                mobileHeader,
                loginToken
            ); 
        }

        this._zipCode = this._desktopChangeLocationModule.currentZipCode;


        new searchModule(
            headerSearch.querySelector('#headerSearchForm'),
            headerSearch.querySelector('#txtSearch'),
            headerSearch.querySelector('#btnSearch'),
            headerSearch.querySelector('.searchResults'),
            headerSearch.querySelector('.searchResultsContainer')
        );

        new mobileSearchModule(
            document.querySelector('.headerSearch .mobileSearchButton'),
            document.querySelector('#headerSearchMobile'),
            (event?:MouseEvent | CustomEvent | KeyboardEvent)=>{
                // console.info('triggering close method');
                document.body.dispatchEvent(new CustomEvent('closeMenu', { detail: {} }));
                // possibly remove the existing dimmer
                document.querySelectorAll('.dimmer').forEach((dimmer:Element, key: number, parent: NodeListOf<Element>)=>{
                    dimmer.remove();
                });
            }
        );

        document.querySelector('#mobileChangeLocation')?.appendChild(this._mobileChangeLocationModule.formElement);

        document.querySelector('#mobileLogIn')?.appendChild(this._mobileLogInModule.formElement);

        // set our zips
        this.setZipLabels();

        // init the sticky mobile header
        headerModule.initSticky();
    }    

    get currentZip(): string {
        return this._zipCode;
    }

    public static initSticky() {
        const headerElement: HTMLElement = document.querySelector('#header') as HTMLElement,
            headerMain: HTMLElement = headerElement.querySelector('#headerMain'),
            headerNavigation: HTMLElement = headerElement.querySelector('#headerNavigation'),
            mobileSearchBar: HTMLElement = headerElement.querySelector('#headerSearchMobile'),
            headerTop: HTMLElement = headerElement.querySelector('#headerTop'),
            headerBottom: HTMLElement = headerElement.querySelector('#headerBottom'),
            headerHeight: number = headerElement.offsetHeight,
            topBarHeight: number = headerTop.offsetHeight,
            bottomBarHeight: number = headerBottom.offsetHeight;

            const detectZoom = () => { 
                const pageWidth = document.body.clientWidth;
                const windowWidth = window.outerWidth;

                return ((pageWidth < windowWidth) && pageWidth < 1200);
              };

        let positioningLogic: (preventZoom:boolean) => void;

        if (domHelpers.isSmallScreen()) {
            const calcMenuHeight = (adjust: number = 0) => {
                const navBox: HTMLElement = document.querySelector('#headerNavigation > nav'),
                    screenHeight = window.innerHeight;

                let calculatedHeight = screenHeight - adjust;

                if (navBox) {
                    navBox.style.minHeight = calculatedHeight + 'px';
                    navBox.style.maxHeight = calculatedHeight + 'px';
                }
            };

            positioningLogic = (preventZoom:boolean) => {
                if (window.scrollY >= topBarHeight && !preventZoom) {
                    // we've scrolled down past the top bar to the meat of the header
                    // therefore stick it
                    if (!headerMain.classList.contains('sticky')) {
                        headerMain.classList.add('sticky');
                        headerNavigation.classList.add('sticky');
                        mobileSearchBar.classList.add('sticky');
                        document.body.classList.add('sticky');
                        headerNavigation.style.top = headerMain.offsetHeight.toString() + 'px';
                        mobileSearchBar.style.top = "0";

                        calcMenuHeight(headerMain.offsetHeight);
                    }
                } else {
                    // we've scrolled up past the header, put it back where we got it
                    headerMain.classList.remove('sticky');
                    headerNavigation.classList.remove('sticky');
                    mobileSearchBar.classList.remove('sticky');
                    document.body.classList.remove('sticky');
                    // make sure our navigation menu is in line with the rest of the header
                    headerNavigation.style.top = headerHeight + 'px';
                    mobileSearchBar.style.top = topBarHeight + 'px';

                    calcMenuHeight(headerHeight);
                }
            };

            // old method: set marginTop with a fixed position header
            //document.body.style.marginTop = headerHeight;

            // new method: first calculate and set the header's final height
            // this will keep content below in place when we rip pieces out of it to fix to top
            headerElement.style.height = headerHeight + 'px';
        } else {
            // slightly different implementation on desktop
            positioningLogic = (preventZoom:boolean) => {
                if (window.scrollY != 0 && window.scrollY >= topBarHeight && !preventZoom) {
                    // we've scrolled down past the top bar to the meat of the header
                    // therefore stick it
                    if (!headerMain.classList.contains('sticky')) {
                        // stick the elements to the top of the page
                        headerMain.classList.add('sticky');
                        headerNavigation.classList.add('sticky');
                        document.body.classList.add('sticky');
                        // position the sticky nav under the sticky header (otherwise it's behind it)
                        headerNavigation.style.top = (headerMain.offsetHeight-1) + 'px';
                        // create the space where the header was to keep the page from jumping
                        headerBottom.style.marginTop = (headerMain.offsetHeight + headerNavigation.offsetHeight) + 'px';
                    }

                } else {
                    // we've scrolled up past the sticky point, put everything back in place
                    headerMain.classList.remove('sticky');
                    headerNavigation.classList.remove('sticky');
                    document.body.classList.remove('sticky');
                    headerNavigation.style.top = '0px';
                    headerBottom.style.marginTop = '0px';
                }
            }
        }

        // initial heights
        positioningLogic(detectZoom());

        // give us a scroll spy event for sticking the header 
        window.onscroll = (event: Event) => {
            positioningLogic(detectZoom());
        };
    }

    /**
     * Find all zip code display labels and update them to the latest value
     *
     * @private
     * @memberof headerModule
     */
    private setZipLabels() {
        let zipDisplaySpans = document.querySelectorAll('.headerZipCode') as NodeListOf<HTMLSpanElement>;
        for (const key in zipDisplaySpans) {
            if (zipDisplaySpans.hasOwnProperty(key)) {
                const zipSpan = zipDisplaySpans[key] as HTMLElement;
                zipSpan.innerHTML = this._zipCode;
            }
        }
    }

    //#region events
    private zipChanged = (newZip: string) => {
        this._zipCode = newZip;
        this.setZipLabels();
    }
    //#endregion
}
