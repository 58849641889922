import { GA_EventFieldObjects, AnalyticInteractions } from "./AnalyticHelper";

enum readyStates {      // helper for ready state codes
    'UNSENT' = 0,        // Client has been created. open() not called yet.
    'OPENED',           // open() has been called.
    'HEADERS_RECEIVED', // send() has been called, and headers and status are available.
    'LOADING',          // Downloading; responseText holds partial data.
    'DONE'              // The operation is complete.
}

export enum PEDButtonType {
    'standard',
    'special',
    'footer',
    'none'
}

declare interface Window extends EventTarget, AnimationFrameProvider, WindowLocalStorage, WindowOrWorkerGlobalScope, WindowSessionStorage {
	addEventListener<K extends keyof WindowEventMap>(type: K, listener: (this: Window, ev: WindowEventMap[K]) => any, options?: boolean | AddEventListenerOptions): void;
    addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void;
}
type EventListenerOrEventListenerObject = EventListener | EventListenerObject;
interface EventListener {
    (evt: Event): void;
}

interface EventListenerObject {
    handleEvent(object: Event): void;
}

/**
 * Cross browser AJAX helper for GET/POST operations
 *
 * @export
 * @class ajaxHelpers
 */
export class ajaxHelpers {
    private static readonly _methods = { GET: 'GET', POST: 'POST' };

    constructor(){}
    /**
     * Create the cross-browser XMLHttpRequest object
     *
     * @private
     * @returns {(XMLHttpRequest | ActiveXObject)}
     * @memberof ajaxHelpers
     */
    private static createAjaxRequestObject(): XMLHttpRequest | ActiveXObject {
        let xmlHttp: XMLHttpRequest | ActiveXObject;

        if (window.XMLHttpRequest) {
            // code for IE7+, Firefox, Chrome, Opera, Safari
            xmlHttp = new XMLHttpRequest();
        } else {
            // code for IE6, IE5
            xmlHttp = new ActiveXObject('Microsoft.XMLHTTP');
        }

        // Create the object
        return xmlHttp;
    }
    /**
     * Submit the ajax request
     *
     * @private
     * @param {string} type
     * @param {string} location
     * @param {(Document|BodyInit)} parameters
     * @param {Function} onComplete
     * @memberof ajaxHelpers
     */
    private static submitAjax(type: string, location: string, parameters: any, onComplete: Function, onLoading?: Function): void {
        var http3: any = this.createAjaxRequestObject(), // create our request object
            parameterString = JSON.stringify(parameters);

        // create a handler to keep an eye on the request's readyState
        http3.onreadystatechange = function () {
            if (http3.readyState == readyStates.DONE) {
                // status 200 OK            
                if (http3.status == 200) {
                    // execute any passed callback, send in the response
                    if (onComplete) {
                        onComplete(http3.responseText);
                    }
                }
            }
            if (http3.readyState == readyStates.LOADING) {
                // status 200 OK
                if (http3.status == 200) {
                    // execute any passed callback, send in the response
                    if (onLoading) {
                        onLoading(http3.responseText);
                    }
                }
            }
        };

        // Make request
        http3.open(type, location, true);
        http3.setRequestHeader('Content-type', 'application/json;charset=UTF-8');
        http3.send(parameterString);
    }

    /**
     *Perform an ajax POST
     *
     * @param {string} location - URL to POST to
     * @param {(Document|BodyInit)} parameters - body to be sent in the request
     * @param {Function} onComplete - callback function
     * @memberof ajaxHelpers
     */
    static post(location: string, parameters: any, onComplete: Function, onLoading?: Function) {
        this.submitAjax(this._methods.POST, location, parameters, onComplete, onLoading);
    }
    /**
        *Perform an ajax GET
        *
        * @param {string} location - URL to GET
        * @param {(Document|BodyInit)} parameters - body to be sent in the request
        * @param {Function} onComplete - callback function
        * @memberof ajaxHelpers
        */
    static get(location: string, onComplete: Function, onLoading?: Function) {
        this.submitAjax(this._methods.GET, location, {}, onComplete, onLoading);
    }
}

/**
 * helper for referencing chevron classes
 *
 * @export
 * @enum {number}
 */
export enum chevronDirection {
    left = "icon-cheveron-left",
    right = "icon-cheveron-right",
    up = "icon-cheveron-up",
    down = "icon-cheveron-down"
}
/**
 * Class to assist with common DOM generation actions
 *
 *
 * @export
 * @class domHelpers
 */
export class domHelpers {


    /**
     * Get our current screen width
     *
     * @static
     * @returns {number}
     * @memberof domHelpers
     */
    static getScreenWidth(): number {
        // simple cross browser helper for returning our current browser width
        let w = window,
            d = document,
            e = d?.documentElement,
            g = d?.body;

        return e?.clientWidth || g?.clientWidth || w?.innerWidth; // get our width
    }
    /**
     * Trap tab focus within a HTML element
     *
     * @static
     * @param {HTMLElement} element
     * @memberof domHelpers
     */
    static trapFocus(element: HTMLElement): void {
        // while the change location "popup" is visible, trap focus loop within
        const focusableEls = element.querySelectorAll('[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])');
        if(focusableEls){
            const  firstFocusableEl = focusableEls[0], // get the first element
                lastFocusableEl = focusableEls[focusableEls.length - 1], // get the last element
                KEYCODE_TAB = 9; // tab keycode reference

            eventHelpers.bindEvent(element, ['keydown'], function (e: KeyboardEvent) {
                // determine if our key pressed was tab
                const isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

                if (!isTabPressed) {
                    // if not tab, just return
                    return;
                }

                if (e.shiftKey) /* shift + tab */ {
                    // going backwards, make sure to cycle to the last DOM node
                    if (document.activeElement === firstFocusableEl) {
                        (<HTMLElement>lastFocusableEl).focus();
                        e.preventDefault();
                    }
                } else /* tab */ {
                    // going normal, loop back to the first DOM node
                    if (document.activeElement === lastFocusableEl) {
                        (<HTMLElement>firstFocusableEl).focus();
                        e.preventDefault();
                    }
                }

            });
        }
    }
    /**
     * Returns an iterable collection of elements for usage in loops
     *
     * @static
     * @param {(string|HTMLElement|NodeList|Document|Window)} v
     * @returns {(Array<HTMLElement>|NodeList)}
     * @memberof domHelpers
     */
    static mockNodeList(v: string | HTMLElement | NodeList | Document | Window): Array<HTMLElement> | NodeList | Document | Window {
        // take an object, such as result of querySelector or querySelectorAll or even a string,
        // and process so the result can be looped
        var elements;
        if (v instanceof HTMLElement) {
            // we passed a single element or the document or window object
            elements = [v];
        } else if (v instanceof NodeList) {
            // we passed a node list
            elements = v;
        } else if (typeof v === 'string') {
            // we passed a selector
            elements = document.querySelectorAll(v);
        } else {
            // we passed something else, perhaps document or window object?
            elements = v;
        }

        return elements;
    }
    /**
     * Detect if the element is either Window, Document, or Form
     *
     * @static
     * @param {Element} v
     * @returns {boolean}
     * @memberof domHelpers
     */
    static isDocumentOrWindowOrForm(v: any): boolean {
        // detect these special cases
        if (v instanceof window.constructor) return true;
        if (v instanceof Document) return true;
        if (v instanceof HTMLFormElement) return true;
        return false;
    }
    /**
     * Remove spaces from a string
     *
     * @param {string} str
     * @returns {string}
     * @memberof domHelpers
     */
    static stripSpaces(str: string): string {
        // strips ALL spaces from a string, used for ID generation
        str = str || '';
        return str.replace(/\s+/g, '');
    }
    /**
     * Return True if our current viewport is a touch screen device
     *
     * @returns {boolean}
     * @memberof domHelpers
     */
    static isMobile = ():boolean => {
        if ('userAgentData' in navigator) return navigator.userAgentData.mobile;
        // utilize media queries to determine if it applies to the window
        const coarseCheck = window.matchMedia('(pointer: coarse)').matches,
            fineCheck = window.matchMedia('(pointer: fine)').matches;
        let mobileCheck:boolean;
        if (coarseCheck == fineCheck) {
            // if both resolve to the same value, pointer media query is unsupported, assume desktop
            mobileCheck = false;
        } else {
            // otherwise accept the value of the coarse check
            mobileCheck = coarseCheck;
        }

        // IE might produce a false positive with pointer media support
        return mobileCheck;
    }

    static isSmallScreen = ():boolean => {
            const mdCutoff = 812;
            const mobileCheck = domHelpers.isMobile();
            return mobileCheck || (!mobileCheck && document.body.clientWidth < mdCutoff);
    }

    static isHidden=(el:HTMLElement):boolean=> {
        return (el?.offsetParent === null)
    };

    static isElement = (obj:any):boolean => {
        try {
          //Using W3 DOM2 (works for FF, Opera and Chrome)
          return obj instanceof HTMLElement;
        }
        catch(e){
          //Browsers not supporting W3 DOM2 don't have HTMLElement and
          //an exception is thrown and we end up here. Testing some
          //properties that all elements have (works on IE7)
          return (typeof obj==="object") &&
            (obj.nodeType===1) && (typeof obj.style === "object") &&
            (typeof obj.ownerDocument ==="object");
        }
      }

    static createElement(node: string, content?: any): HTMLElement {
        try {
            let genericElement = document.createElement(node);
            if (content) {
                if (this.isElement(content)) {
                    genericElement.appendChild(content);
                }else{
                    genericElement.innerHTML = content;
                }
            }
            return genericElement;
        } catch (error) {
            // console.warn(error);
            // console.info(node);
        }
    }

    static createTextInput():HTMLInputElement{
        let input = this.createElement('input') as HTMLInputElement;
        input.className="PED_input";
        input.type="text";
        return input;
    }

    static createButton(type: PEDButtonType): HTMLButtonElement {
        let pedButton = this.createElement('button') as HTMLButtonElement;
        switch (type) {
            case PEDButtonType.standard:
                pedButton.className = "PED_button";
                break;
            case PEDButtonType.special:
                pedButton.className = "PED_button special";
                break;
            case PEDButtonType.footer:
                pedButton.className = "PED_button reverse bordered"
                break;
            default:
                break;
        }
        return pedButton;
    }
    /**
     * Create an <a> element
     *
     * @param {((string | HTMLElement))} link - string link text or HTML Element
     * @param {{class:string, href:string, title:string, id:string, data:string}} linkAttrs - key/value pairs of attributes to apply
     * @returns {HTMLAnchorElement}
     * @memberof domHelpers
     */
    static createHyperlink(link: (string | HTMLElement), linkAttrs: any, analyticEvent?:GA_EventFieldObjects): HTMLAnchorElement {
        // link has href, title properties
        const returnLink: HTMLAnchorElement = document.createElement('a');   // create the anchor element

        for (const key in linkAttrs) {
            if (linkAttrs.hasOwnProperty(key)) {
                const atr: string = linkAttrs[key];
                // set our attributes
                if (!(key === 'title' && atr === link && atr != null && atr != "null")) {
                    // but not title if identical to the text
                    returnLink.setAttribute(key, atr);
                }
            }
        }

        if (link instanceof HTMLElement) {
            // we passed a DOM element instead of text to be inserted into the hyperlink
            returnLink.appendChild(link);
        } else {
            // we passed a string text to be inserted into the hyperlink
            returnLink.innerHTML = link;
        }
        
        // do one last check of the href, make sure we're not setting a null link
        if (linkAttrs.href === '' || linkAttrs.href === null || linkAttrs.href.toLowerCase() === 'null' || linkAttrs.href === '/null') {            
            returnLink.removeAttribute('href');
        } else {            
            returnLink.href = linkAttrs.href;
        }

        if (analyticEvent) {
            returnLink.addEventListener('click',(e:MouseEvent)=>{
                AnalyticInteractions.tealium_navigation(
                    analyticEvent?.eventAction,
                    analyticEvent?.eventCategory,
                    analyticEvent?.eventLabel,
                    analyticEvent?.eventValue?.toString(),
                    () => {
                        if (returnLink?.href) {
                            window.location.href = returnLink?.href;
                        }
                    }
                )
            });
        }

        return returnLink;
    }
    /** 
     * Creates an <img> element
     * @param {{class, src, alt, id, data}} attributes - any valid image attributes
     * @returns {HTMLImageElement}
    */
    static createImage(attributes: any): HTMLImageElement {
        // simple helper for creating images to reduce duplicate code
        let thisImage = document.createElement('img'),
            key, value;
        for (key in attributes) {
            if (attributes.hasOwnProperty(key)) {
                value = attributes[key];
                thisImage.setAttribute(key, value);
            }
        }

        return thisImage;
    }
    /** 
     * Creates an <img> element
     * @param {string} baseURL - url for our largest size image
     * @param {number[]} sizes - array of sizes to generate
     * @returns {string} data for use on the image
    */
    static generateSrcSet(baseURL: string, sizes: number[]): string {
        let urlParts = baseURL.match(/nav-image\/(\d+)\/(.*)/),          
            output = '';
        if (urlParts) {
            for (let index = 0; index < sizes.length; index++) {
                const size = sizes[index];
                const lastSize = index === sizes.length - 1;
                const newSrc = `nav-image/${size}/${urlParts[2]} ${size}w${!lastSize ? ', ' : ''}`;
                output += newSrc;
            }
        }

        return output;
    }
    /**
     * Create a <section> element
     * @param {string} className - Name of class to apply to section. Pass empty string '' to omit
     * @returns {HTMLElement}
     */
    static createSection(className: string): HTMLElement {
        var sect = document.createElement('section');
        if (className != '') {
            sect.className = className;
        }
        return sect;
    }
    /**
     * Creates a span with the provided text
     *
     * @param {string} spanText
     * @returns {HTMLSpanElement}
     * @memberof domHelpers
     */
    static createSpan(spanText: string): HTMLSpanElement {
        var span = document.createElement('span');
        span.innerHTML = spanText;
        return span;
    }
    

    /**
     * Creates an icon (span element)
     *
     * @param {string} className - Icon's class
     * @param {boolean} [hideFromReader=false] - optionally hide the icon from the screen readers
     * @returns {HTMLSpanElement}
     * @memberof domHelpers
     */
    static createIcon(className: string, hideFromReader: boolean = false): HTMLSpanElement {
        var span = this.createSpan(''); // create our span with empty text (icon is generated with css pseudo classes)
        // set the icon class
        span.className = className;
        // optionally hide the icon from the screen reader
        // we should only really use this when the icon is purely aesthetic
        span.setAttribute('aria-hidden', hideFromReader.toString());
        return span;
    }

    static addChevronIcon(parentEle: HTMLElement, direction: chevronDirection) {
        var validDirection: boolean, defaultDirection: chevronDirection.right;
        // make sure our element is something we can append children into
        if ('appendChild' in parentEle) {
            // make sure we passed a valid direction
            switch (direction) {
                case chevronDirection.left:
                case chevronDirection.right:
                case chevronDirection.up:
                case chevronDirection.down:
                    validDirection = true;
                    break;
                default:
                    validDirection = false;
                    break;
            }

            // if parent was something we can append to, do so
            if (!validDirection) {
                // set a default if something invalid was passed
                direction = defaultDirection;
            }
            parentEle.appendChild(this.createIcon(direction, true));
        }
    }
    /**
     * Create a figure with an image and a caption
     *
     * @param {string} className - css class to be applied to the <figure>
     * @param {{class:string, src:string, alt:string, id:string, data:string}} imgAttrs - key/value attributes for the image
     * @param {(string|HTMLElement|NodeList)} caption - Can be string text, DOM node, or nodeList
     * @returns {HTMLElement}
     * @memberof domHelpers
     */
    static createFigure(className: string, imgAttrs: {}, caption: string | HTMLElement | NodeList | Element): HTMLElement {
        let fig = document.createElement('figure'),
            figImg = fig.appendChild(this.createImage(imgAttrs)),
            figCaption = fig.appendChild(document.createElement('figcaption')),
            index: number, element: Node;

        if (className != '') {
            fig.className = className;
        }

        switch (typeof caption) {
            case 'string':
                figCaption.innerHTML = caption;
                break;
            case 'object':
                if (caption instanceof NodeList) {
                    for (index = 0; index < caption.length; index++) {
                        element = caption[index];
                        figCaption.appendChild(element);
                    }
                } else {
                    figCaption.appendChild(caption);
                }
                break;
            default:
                // neither option?
                break;
        }

        return fig;
    }
}

export class eventHelpers {
    /**
     * Vanilla replacement for jQuery on() and off()
     *
     * @private
     * @param {string} listenerAction
     * @param {string} eventType
     * @param {EventListenerOrEventListenerObject} eventFunction
     * @param {EventTarget} element
     * @param {boolean} capture
     * @memberof eventHelpers
     */
    private static toggleListener(listenerAction: string, eventType: string, eventFunction: Function, element: EventTarget, capture: boolean): void {
        switch (listenerAction) {
            case 'on':
                element.addEventListener(eventType, eventFunction as EventListener, capture); // attach the event
                break;
            case 'off':
                element.removeEventListener(eventType, eventFunction as EventListener, capture); // detach the event 
                break;
        }
    }

    /**
     * Assign our events
     *
     * @private
     * @param {(string | HTMLElement | NodeList | Document | Window)} variant
     * @param {[string]} eventTypes
     * @param {Function} eventFunction
     * @param {string} listenerAction
     * @param {boolean} [capture=false]
     * @memberof eventHelpers
     */
    private static processEvent(variant: string | HTMLElement | NodeList | Document | Window, eventTypes: string[], eventFunction: Function, listenerAction: string, capture: boolean = false): void {
        let elements: any, index: number, element: HTMLElement, eventIndex: number, eventType: string;

        // test if eventTypes is an array
        eventTypes = Array.isArray(eventTypes) ? eventTypes : [eventTypes];

        // using this loop we can bind multiple events to multiple objects with one function call
        // first loop is for the events
        for (eventIndex = 0; eventIndex < eventTypes.length; eventIndex++) {
            eventType = eventTypes[eventIndex];

            if (domHelpers.isDocumentOrWindowOrForm(variant)) {
                // when document, window, or form, we want to apply the event directly and NOT iterate the children
                this.toggleListener(listenerAction, eventType, eventFunction, variant as EventTarget, capture);
            } else {
                elements = domHelpers.mockNodeList(variant);
                // check our node list length...
                if (elements.length === 1) {
                    // only have one element, no need to loop
                    this.toggleListener(listenerAction, eventType, eventFunction, elements[0], capture);
                } else {
                    // we have multiple...
                    // check if 'variant' was a selector string
                    // if so great, we can delegate and save performance
                    // ideally we want to explicitly call this with a specific parent, better than binding to document
                    if (typeof variant === 'string') {
                        eventHelpers.bindDelegateEvent(document, variant, eventTypes, eventFunction);
                    } else {
                        // worst case performance, we passed in a node list
                        // take extreme caution when doing this, avoid if all possible
                        // need to loop through each element, attaching the event to it
                        for (index = 0; index < elements.length; index++) {
                            element = elements[index];
                            this.toggleListener(listenerAction, eventType, eventFunction, element, capture);
                        }
                    }
                }
            }
        }
    }

    /**
     * Bind one or more elements to one or more events
     * @param {(string | HTMLElement | NodeList | Document | Window)}  variant - object(s) to bind the event to
     * @param {[string]} eventTypes - string array of events to bind
     * @param {Function} eventFunction - function to execute on event trigger
     * @example
     * // single event binding
     * bindEvent('button', ['click'], myFunction);
     * @example
     * // multiple event binding
     * bindEvent('input', ['keyUp', 'keyDown'], myFunction);
     */
    static bindEvent = function (variant: string | HTMLElement | Document | Window | NodeList, eventTypes: string[], eventFunction: Function): void {
        this.processEvent(variant, eventTypes, eventFunction, 'on');
    }

    /**
     * Create a delegate event.  This is ideal when binding one event to multiple elements.
     * @param {(string | HTMLElement | NodeList | Document | Window)} parent - parent object or selector to attach the delegated event to
     * @param {string} selector - element or selector to apply the event function to
     * @param {[string]} eventTypes - string array of events to bind
     * @param {Function} eventFunction - function to execute on event trigger
     */
    static bindDelegateEvent(parent: string | HTMLElement | Document | Window | NodeList, selector: string, eventTypes: string[], eventFunction: Function): void {
        var delegateFunction = (event: Event) => {
            if ((<Element>event.target).matches(selector)) {
                eventFunction(event);
            }
        }
        this.processEvent(parent, eventTypes, delegateFunction, 'on', true);
    }
    /**
     * Removes event bindings set by bindEvent.
     * Provide parameters exactly the same as when you called bindEvent.
     * @param {(string | HTMLElement | NodeList | Document | Window)} variant - can be a node, nodeList, selector, document or window object
     * @param {[string]} eventTypes - string array of events to bind
     * @param {Function} eventFunction - function to execute on event trigger
     * @example
     * // single event binding
     * unbindEvent('button', ['click'], myFunction);
     * @example
     * // multiple event binding
     * unbindEvent('input', ['keyUp', 'keyDown'], myFunction);
     */
    static unbindEvent(variant: (string | HTMLElement | NodeList | Document | Window), eventTypes: string[], eventFunction: Function): void {
        this.processEvent(variant, eventTypes, eventFunction, 'off');
    }

    static initResponsiveLazyImages = function (container:HTMLElement|Document = document) {
        const processLazyImages = () => {
            var lazyImages: Array<HTMLImageElement> = [].slice.call((container ?? document).querySelectorAll("img.lazy")),
                active = false;
            if ("IntersectionObserver" in window) {
                // modern browser technique
                let lazyImageObserver = new IntersectionObserver(function (entries, observer) {
                    entries.forEach(function (entry) {
                        if (entry.isIntersecting) {
                            // one of our images came into view
                            let lazyImage: HTMLImageElement = <HTMLImageElement>entry.target;

                            lazyImage.onload = ()=>{
                                // if we assigned a placeholder, remove it when the image finishes loading
                                lazyImage.classList.remove('PED_placeholder');
                            };
                            // set the src and srcset
                            if (lazyImage.dataset.src) {
                                lazyImage.src = lazyImage.dataset.src;
                            }
                            if (lazyImage.dataset.srcset) {
                                lazyImage.srcset = lazyImage.dataset.srcset;
                            }
                            // cleanup
                            lazyImage.classList.remove("lazy");
                            lazyImage.removeAttribute('data-src');
                            lazyImage.removeAttribute('data-srcset');
                            lazyImageObserver.unobserve(lazyImage);
                        }
                    });
                });

                lazyImages.forEach(function (lazyImage) {
                    // observe each of our images
                    lazyImageObserver.observe(lazyImage);
                });
            } else {
                // fallback for browsers that do not support IntersectionObserver
                var lazyLoad = function () {
                    if (active === false) {
                        active = true;

                        setTimeout(function () {
                            lazyImages.forEach(function (lazyImage) {
                                if ((lazyImage.getBoundingClientRect().top <= window.innerHeight && lazyImage.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazyImage).display !== "none") {
                                    // one of our images came into view
                                    // set the src and srcset
                                    lazyImage.src = lazyImage.dataset.src;
                                    lazyImage.srcset = lazyImage.dataset.srcset;

                                    // cleanup
                                    lazyImage.classList.remove('PED_placeholder');
                                    lazyImage.classList.remove("lazy");
                                    lazyImage.removeAttribute('data-src');
                                    lazyImage.removeAttribute('data-srcset');

                                    lazyImages = lazyImages.filter(function (image) {
                                        return image !== lazyImage;
                                    });

                                    if (lazyImages.length === 0) {
                                        document.removeEventListener("scroll", lazyLoad);
                                        window.removeEventListener("resize", lazyLoad);
                                        window.removeEventListener("orientationchange", lazyLoad);
                                    }
                                }
                            });

                            active = false;
                        }, 200);
                    }
                };
                // (fallback ver.) watch for our images to come into view 
                document.addEventListener("scroll", lazyLoad);
                // @ts-ignore: compiler throws error here thinking window is 'never'. Bug?
                window.addEventListener("resize", lazyLoad);
                 // @ts-ignore: compiler throws error here thinking window is 'never'. Bug?
                 window.addEventListener("orientationchange", lazyLoad);
            }
        };

        if (document.readyState === 'loading') {  
            // Loading hasn't finished yet, binding as listener
            document.addEventListener('DOMContentLoaded', processLazyImages);
          } else { 
            // `DOMContentLoaded` has already fired, executing instead
            processLazyImages();
          }
    }
}
