class pageScriptImport {
    // page flags
    private pdp: boolean             = false;
    private vanity: boolean          = false;
    private home: boolean            = false;
    private stories: boolean         = false;
    private newsRelease: boolean     = false;
    private howTo: boolean           = false;
    private login: boolean           = false;
    private cart: boolean            = false;
    private quickSearch: boolean     = false;
    private checkoutSuccess: boolean = false;
    private compare: boolean         = false;
    // site flag
    private isACW: boolean = false;

    constructor() {
        this.setFlags();
        this.conditionalScriptImports();
    }

    private async setFlags() {
        this.parseDataset();
        this.parsePath();
    }

    private async conditionalScriptImports() {
        // site-specific
        if (this.isACW) {
            if (this.home) import('./page/ACW_home');
            if (this.vanity) import('./page/ACW_hybrid');
            if (this.pdp) import('./page/ACW_product');
            if (this.stories) import('./page/ACW_stories');
            if (this.newsRelease) import('./page/ACW_news_release');
        } else {
            if (this.home) import('./page/home');
            if (this.vanity) import('./page/hybrid');
            if (this.pdp) import('./page/product');
            if (this.stories) import('./page/stories');
            if (this.newsRelease) import('./page/news_release');
        }

        // shared between ACW/Niche
        if (this.howTo) import('./page/how_to_library');
        if (this.login) import('./page/login');
        if (this.quickSearch) import('./page/quick_search');
        if (this.cart) import('./page/shopping_cart');
        if (this.checkoutSuccess) import('./page/checkoutSuccess');
        if (this.compare) import('./page/compare');
    }

    private parseDataset() {
        const category = document.body.dataset?.category;
        const theme = document.body.dataset?.bsTheme;
        this.cart            = category       === 'cart';
        this.checkoutSuccess = category       === 'checkoutSuccess';
        this.home            = category       === 'home';
        this.newsRelease     = category       === 'newsRelease';
        this.pdp             = category       === 'product';
        this.stories         = category       === 'stories';
        this.vanity          = category       === 'vanity';
        this.isACW           = theme && theme === 'ACW';
    }

    private parsePath() {
        const path = window.location.pathname;
        this.howTo       = path === '/how-to-library.php';
        this.login       = path === '/login.php' || path === '/login-new.php';
        this.quickSearch = path === '/quick_search.php';
        this.compare     = path === '/compare.php';
    }
}

export default pageScriptImport;
