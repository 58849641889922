import { miniCartItem } from './AnalyticHelper';

export namespace iterable {
    enum methods {
        'GET' = 'GET',
        'POST' = 'POST',
        'PUT' = 'PUT',
        'DELETE' = 'DELETE',
    }

    enum code {
        "Success" = "Success",
        "BadApiKey" = "BadApiKey",
        "BadParams" = "BadParams",
        "BadJsonBody" = "BadJsonBody",
        "QueueEmailError" = "QueueEmailError",
        "GenericError" = "GenericError",
        "InvalidEmailAddressError" = "InvalidEmailAddressError",
        "DatabaseError" = "DatabaseError",
        "EmailAlreadyExists" = "EmailAlreadyExists",
        "Forbidden" = "Forbidden"
    }

    interface cartDataFields {
        stores_id: string,
        products_id: string,
        checkoutStatus?: string,
        modelNumber?: string,
        manufacturer?: string
    }
    interface cartItem {
        id: string,
        sku?: string,
        name: string,
        description?: string,
        categories?: Array<string>,
        price: number,
        quantity: number,
        imageUrl?: string,
        url?: string,
        dataFields?: cartDataFields
    }
    interface userObject {
        email?: string,
        dataFields?: object,
        userId: string,
        preferUserId?: boolean,
        mergeNestedObjects?: boolean
    }
    interface pageDataFields {
        products_id?: string,
        stores_id: string,
        category?: string,
        url?: string,
    }
    interface pageEvent {
        email?: string,
        eventName: string,
        id?: string,
        createdAt?: number,
        dataFields?: pageDataFields,
        userId: string,
        campaignId?: number,
        templateId?: number
    }
    interface iterableResult {
        msg: string,
        code: code,
        params: object
    }

    function extractFromCookie(key: string): string {
        const cookies = decodeURIComponent(document.cookie.split('; ').find((a) => {
            return a.indexOf(key) >= 0
        }));

        return cookies.split(`${key}=`)[1];
    }

    function stripOption(id: string) {
        if (id) {
            let optionIndex = id.indexOf("{");

            if (optionIndex >= 0) {
                return id.substring(0, optionIndex);
            } else {
                return id;
            }
        } else {
            return id;
        }
    }

    function isNumeric(str: any) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(Number(str)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    function URLParamToCookies() {
        const params = new URLSearchParams(location.search);
        const { hasIterableCampaign, hasIterableTemplate, hasUser } = {
            hasIterableCampaign: params.has('iterableEmailCampaignId'),
            hasIterableTemplate: params.has('iterableTemplateId'),
            hasUser: params.has('userId')
        };

        if (hasIterableCampaign || hasIterableTemplate) {
            const expDateIterable = new Date();
            expDateIterable.setTime(expDateIterable.getTime() + (1 * 24 * 60 * 60 * 1000)); // 1 = 1 day
            const iterableCookieEnd = `expires=${expDateIterable.toUTCString()};path=/;secure;`;
            if (params.has('iterableEmailCampaignId')) {
                const campaignId: string = params.get('iterableEmailCampaignId');
                if (campaignId.length == 7 && isNumeric(campaignId)) {
                    document.cookie = `iterableEmailCampaignId=${campaignId};${iterableCookieEnd}`;
                }
            }
            if (params.has('iterableTemplateId')) {
                const templateId: string = params.get('iterableTemplateId');
                if (templateId.length == 7 && isNumeric(templateId)) {
                    document.cookie = `iterableTemplateId=${templateId};${iterableCookieEnd}`;
                }
            }
        }
        if (hasUser) {
            const expDateUser = new Date();
            expDateUser.setTime(expDateUser.getTime() + (364 * 24 * 60 * 60 * 1000)); // 364 days
            const userCookieEnd = `expires=${expDateUser.toUTCString()};path=/;secure;`;
            const userId: string = params.get('userId');
            const validIdRegex = /^([a-f0-9]{64})$/gm; // sha256 validation
            if (validIdRegex.test(userId)) {
                document.cookie = `userId=${userId};${userCookieEnd}`;
            }
        }
    };

    export function initialize(key: string, store?: string) {
        URLParamToCookies();

        const user = new iterable.user(key);

        if (user.userObject) {
            const pageEvent = new iterable.page(user),
                cartEvent = new iterable.cart(user);

            document.body.addEventListener('iterableCartUpdate', function iterableCartUpdate(e: CustomEvent) {
                const cartAction = document.body.dataset.cartEvent;

                if (e.detail.currentCart) {

                    for (let index = 0; index < e.detail.currentCart.length; index++) {
                        const cartItem: miniCartItem = e.detail.currentCart[index],
                            prodURL = '',
                            imageURL = cartItem.image ? `${window.location.origin}/products-image/500/${cartItem.image}` : '';

                        cartEvent.updateCartItem(
                            cartItem.id,
                            cartItem.name || "",
                            +cartItem.price,
                            +cartItem.quantity,
                            cartItem.model,
                            '',
                            [cartItem.category],
                            imageURL,
                            prodURL,
                            e.detail.storeId || store,
                            'pre-checkout',
                            cartItem.model,
                            cartItem.brand
                        );
                    }

                    // update iterable only when we issue the command to
                    if (cartAction == "update") {
                        cartEvent.syncCart();
                    }
                    document.body.dataset.cartEvent = "";
                }
            });

            document.body.addEventListener('iterableCartRemoveItem', (e: CustomEvent) => {
                cartEvent.removeFromCart(e.detail.id);
                cartEvent.syncCart();
            });
            document.body.addEventListener('iterableCartUpdateQuantity', (e: CustomEvent) => {
                cartEvent.updateCartItemQuantity(e.detail.id, e.detail.quantity);
                cartEvent.syncCart();
            });

            document.body.addEventListener('iterablePageViewEvent', (e: CustomEvent) => {
                pageEvent.trackEvent(e.detail.category, e.detail.storeId || store, e.detail.productData);
            });

            return true;
        } else {
            // no logged in user detected
            return false;
        }
    }

    class dataCourier {
        // we'll use this class for our ajax operations
        private _apiKey = '';
        private readonly _baseURL = 'https://api.iterable.com';
        private _headers = {};

        constructor(key: string) {
            this._apiKey = key;
            this._headers = {
                'Content-Type': 'application/json',
                'Api-Key': this._apiKey
            };
        }

        deliver(endpoint: string, payload: object, onSuccess: (value: any) => any, onError?: (reason: any) => void) {
            return fetch(
                this._baseURL + endpoint,
                {
                    method: methods.POST,
                    headers: this._headers,
                    body: JSON.stringify(payload)
                }
            )
                .then(this.validateResponse)
                .then(this.readResponseAsJSON)
                .then(this.validateBody)
                .then(onSuccess || this.onSuccess)
                .catch(onError || this.onFail);
        }

        retrieve(endpoint: string, onSuccess: (value: any) => any, onError?: (reason: any) => void) {
            return fetch(
                this._baseURL + endpoint + `?apiKey=${this._apiKey}`,
                {
                    method: methods.GET,
                    headers: this._headers,
                }
            )
                .then(this.validateResponse)
                .then(this.readResponseAsJSON)
                .then(this.validateBody)
                .then(onSuccess)
                .catch(onError || this.onFail);
        }
        // on failed ajax
        private onFail(reason: any) {

        }

        private onSuccess(result: any) {

        }

        private validateBody(response: any) {
            if ('code' in response && response.code != code.Success) {
                throw Error(`${response.code}: ${response.msg}`)
            }
            return response;
        }

        private validateResponse(response: Response) {
            if (!response.ok && response.statusText != '') {
                // this should get caught by our fetch/fail
                throw Error(response.statusText);
            }
            return response;
        }
        private readResponseAsJSON(response: Response) {
            return response.json();
        }
    }

    export class user {
        private readonly _cookieName: string = 'ems';
        private _user: userObject;
        private _courier: dataCourier;

        private _sampleUsers: Array<userObject> = [
            {
                email: 'enrico.capitan+pedhashed1@iterable.com',
                userId: 'ebf0c617d095b11b8ecc6141a58360cb526afa1d46730511ff85ec3b295c9537',
                preferUserId: true
            },
            {
                email: 'enrico.capitan+pedhashed2@iterable.com',
                userId: '58d8327b93282257e6b8b468c07861e7f4663d3d36c79858895cce52f329784d',
                preferUserId: true
            },
            {
                email: 'enrico.capitan+pedhashed3@iterable.com',
                userId: 'c346bcf0ffc06956ed54367d9ece234a0224e03b63742dea0925feff8031ec5e',
                preferUserId: true
            }

        ];

        constructor(key: string, debug: boolean = false) {
            this._courier = new dataCourier(key);

            if (debug) {
                this._user = this._sampleUsers[Math.floor(Math.random() * this._sampleUsers.length)];
            } else {
                const cookieVal = extractFromCookie('userId') || extractFromCookie(this._cookieName);

                if (cookieVal) {
                    this._user = {
                        dataFields: {},
                        userId: cookieVal,
                        preferUserId: true
                    };
                }
            }
        }

        public get dataCourier(): dataCourier {
            return this._courier;
        }

        public get userObject(): userObject {
            return this._user;
        }
    }

    export class cart {
        private _cartObject: Array<cartItem>;
        private _user: user;

        constructor(user: user) {
            this._user = user;
            this._cartObject = [];
        }


        public get cartObject(): Array<cartItem> {
            return this._cartObject;
        }

        updateCartItemQuantity(id: string, quantity: number) {
            const cleanedQuantity: number = typeof quantity === "string" ? parseInt(quantity) : quantity;

            id = stripOption(id.toString());

            if (cleanedQuantity <= 0) {
                // we passed 0 quantity, so remove it from the array
                this.removeFromCart(id);
            } else {
                // attempt to find the index of the product in the cart
                let productIndex: number = this._cartObject.findIndex(item => item.id === id);
                if (productIndex >= 0) {
                    // we found an index, update the quantity
                    this._cartObject[productIndex].quantity = cleanedQuantity;
                } else {
                    // no index, this shouldn't happen?
                }
            }
        }

        updateCartItem(id: string, name: string, price: number, quantity: number, model: string = '', description: string = '', categories: Array<string> = [], imageUrl: string = '',
            url: string = '', storeId: string = '', checkoutStatus: string = '', modelNumber: string = '', manufacturer: string = '') {

            id = stripOption(id.toString());

            if (quantity > 0) {
                const newItem: cartItem = {
                    id: id,
                    sku: model,
                    name: name || "",
                    description: description,
                    categories: categories,
                    price: price,
                    quantity: quantity,
                    imageUrl: imageUrl,
                    url: url,
                    dataFields: {
                        stores_id: storeId.toString(),
                        products_id: id,
                        checkoutStatus: checkoutStatus,
                        modelNumber: modelNumber,
                        manufacturer: manufacturer
                    }
                },
                    existingItemIndex = this._cartObject.findIndex(item => item.id === id);

                if (existingItemIndex >= 0) {
                    // item already exists in cart, update the values
                    this._cartObject[existingItemIndex] = newItem;
                } else {
                    // does not exist (-1)
                    this._cartObject.push(newItem)
                }

                // we did things. Notify iterable
                //this.syncCart();
            } else {
                this.removeFromCart(id);
            }

        }
        removeFromCart(id: string) {
            const existingItemIndex = this._cartObject.findIndex(item => item.id === id);

            if (existingItemIndex >= 0) {
                this._cartObject.splice(existingItemIndex, 1);
            }
        }

        syncCart() {
            const endpoint = `/api/commerce/updateCart`,
                payload = { "user": this._user.userObject, "items": this._cartObject };

            this._user.dataCourier.deliver(
                endpoint,
                payload,
                (result) => {
                    // handle success
                },
                (error) => {
                    // handle error
                }
            );
        }
    }

    export class page {
        private _user: user;
        private _pageEvent: pageEvent;

        constructor(user: user) {
            let campaignCookieVal: any = extractFromCookie('iterableEmailCampaignId'),
                templateCookieVal: any = extractFromCookie('iterableTemplateId'),
                url = window.location.href.substr(0, window.location.href.indexOf(window.location.search)); // strip out querystring from URL

            if (url.search) {

            }

            if (!url) {
                url = window.location.href;
            }

            campaignCookieVal = isNaN(campaignCookieVal) ? null : parseInt(campaignCookieVal);
            templateCookieVal = isNaN(templateCookieVal) ? null : parseInt(templateCookieVal);

            this._user = user;
            this._pageEvent = {
                eventName: "pageView",
                dataFields: {
                    stores_id: '',
                    products_id: '',
                    category: '',
                    url: url,
                },
                userId: this._user.userObject.userId,
                campaignId: campaignCookieVal,
                templateId: templateCookieVal
            };
        }

        trackEvent(category: string, storeId: string, productData: { id: string, name: string, image: string, stars: number, recommended: boolean, hot: boolean, new: boolean }) {
            const endpoint = `/api/events/track`;
            this._pageEvent.dataFields.category = category;
            this._pageEvent.dataFields.stores_id = storeId.toString();
            if (productData) {
                this._pageEvent.dataFields.products_id = stripOption(productData.id);
                // this._pageEvent.dataFields.PRODUCT_IMAGE = productData.image;
                // this._pageEvent.dataFields.PRODUCT_NAME = productData.name;
                // this._pageEvent.dataFields.REVIEW_STARS = productData.stars;
                // this._pageEvent.dataFields.PRODUCT_RECOMMENDED = productData.recommended;
                // this._pageEvent.dataFields.PRODUCT_HOT = productData.hot;
                // this._pageEvent.dataFields.PRODUCT_NEW = productData.new;
            }

            this._user.dataCourier.deliver(
                endpoint,
                this._pageEvent,
                (result) => {
                    // console.info('Iterable: successful page track post: ', result);
                },
                (error) => {
                    // console.info('Iterable: failed page track post: ', error);
                }
            );
        }
    }
}